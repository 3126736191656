import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  WordsNGramSettings,
  WordsNGramSettingsUpdateType,
  WordsNGramStaticRequestType,
  WordsNGramStaticResponseType,
} from '@app/interfaces/words-n-gramm.type';

import { baseQuery } from '../baseQuery';
export const wordsNGramApi = createApi({
  reducerPath: 'wordsNGramApi',
  tagTypes: ['wordsNGram', 'wordsNGramSettings'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getWordsNGramStaticCloud: build.query<
      WordsNGramStaticResponseType,
      WordsNGramStaticRequestType
    >({
      query: (params) => ({
        url: serverRoutes.gramCloudData,
        params: params,
      }),
      providesTags: ['wordsNGram'],
    }),
    getWordsNGramSettings: build.query<WordsNGramSettings, { live_report_id: string }>({
      query: (params) => ({
        url: serverRoutes.gramCloudData,
        params,
      }),
      providesTags: ['wordsNGramSettings'],
    }),
    updateWordsNGramStaticCloudSettings: build.mutation({
      query: ({ params, body }: WordsNGramSettingsUpdateType) => ({
        url: serverRoutes.gramCloud,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['wordsNGram', 'wordsNGramSettings'],
    }),
    startCalculateWordNGramCloud: build.mutation({
      query: (params: { live_report_id: string }) => ({
        url: serverRoutes.gramCloudUpdate,
        method: 'PUT',
        params,
      }),
    }),
  }),
});

export const {
  useGetWordsNGramStaticCloudQuery,
  useUpdateWordsNGramStaticCloudSettingsMutation,
  useStartCalculateWordNGramCloudMutation,
  useGetWordsNGramSettingsQuery,
} = wordsNGramApi;

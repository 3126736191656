module.exports = {
  loading: 'Загрузка...',
  from: 'из',
  show: 'Показать',
  go_to_page: 'Перейти к странице:',
  input: {
    offset_placeholder: 'Выбрать страницу',
  },
  records_queue: 'Записей в очереди на расчет'
};

import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router';

import { PageHeader, Select, Skeleton } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { OptionItem } from '@app/components/ui/select/select.type';
import { useGetChannelsQuery } from '@app/store/api/get-channels-info.api';

import { useGetAllLiveReportsListQuery } from '@app/store/api/live-reports.api';

import { WordsNGramStaticRequestType } from '@app/interfaces/words-n-gramm.type';

import { WordsCloudFilter } from './blocks/words-cloud-filter';
import { WordCloudContext } from './types/types';
import WordsCloudSkeleton from './blocks/words-cloud.skeleton';

const WordsNGramm = () => {
  const { t } = useTranslation('components.menuBar');
  const { t: ti } = useTranslation('pages.wordsNGramm');
  const [projectId, changeProjectId] = useState<string | undefined>(
    localStorage.getItem('cloud_n_gram') || undefined,
  );
  const [dataForCloud, setDataForCloud] = useState<WordsNGramStaticRequestType>();
  const { data, isLoading } = useGetChannelsQuery(undefined);
  const { data: projectsList, isLoading: loading } = useGetAllLiveReportsListQuery({});

  useEffect(() => {
    if (projectId) return;
    changeProjectId(projectsList?.live_reports?.[0].live_report_id);
  }, [projectId, projectsList]);

  function handleSelectOnChange(option: string | number) {
    localStorage.setItem('cloud_n_gram', option as string);
    changeProjectId(option as string);
  }

  const renderSelectProjectOptions: Array<OptionItem> = projectsList?.live_reports.length
    ? projectsList?.live_reports.map(({ name, live_report_id }) => ({
        title: name,
        value: live_report_id,
      }))
    : [];
  function onFormSubmit(data: Omit<WordsNGramStaticRequestType, 'live_report_id'>) {
    if (!projectId) return;
    setDataForCloud({ ...data, live_report_id: projectId });
  }

  const renderSelectProject = loading ? (
    <Skeleton width={234} height={32} />
  ) : (
    <Select
      size={240}
      onChange={handleSelectOnChange}
      placeholder={ti('select_all_projects')}
      options={renderSelectProjectOptions}
      defaultValue={projectId}
      isEmpty
      dropWidth={238}
    />
  );
  if (loading || isLoading) return <WordsCloudSkeleton />;
  return (
    <>
      <PageHeader label={t('words_n_gramm')} icon="CloudIcon">
        <div className="inline-flex font-[500] text-[15px] text-1color items-center">
          {ti('choose_project')}:<div className="ml-[11px]">{renderSelectProject}</div>
        </div>
      </PageHeader>
      <div className="flex items-start justify-between gap-[10px] mt-[10px]">
        <Outlet context={{ data: dataForCloud } as WordCloudContext} />
        <div>
          <WordsCloudFilter
            projectId={projectId}
            disableSaveButton={!!projectId}
            onFormSubmit={onFormSubmit}
            channels={data}
          />
        </div>
      </div>
    </>
  );
};

export default WordsNGramm;

import React, { useEffect, useState } from 'react';
import { Button, EasyFilterView } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { AnalyticPageOutlet } from '@app/pages/analytics/single-analytic.page';
import { PresetPageTableBlock } from '@app/pages/analytics/preset/blocks/preset-page-table.block';
import { Filter, GetFilterParamsNew, Range } from '@app/components/ui/easy-filter/types';
import { AllStatusProject, GroupSharedStatus } from '@app/interfaces/analytics';
import { useGetAliasesListQuery } from '@app/store/api/alias-settings-page.api';
import {
  useEditProjectPresetMutation,
  useGetAnalyticsProjectPresetQuery,
} from '@app/store/api/analytics-project-preset.api';
import { PresetFillChooseBlock } from '@app/pages/analytics/preset/blocks/preset-fill-choose.block';
import {
  useCreateSinglePresetMutation,
  useGetPresetTemplateGroupsListQuery,
} from '@app/store/api/preset-template.api';
import { PresetsCreateDataType } from '@app/interfaces/pages-types/presets-page-types/presets-table-props.type';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { useAppSelector } from '@app/store/store';

const DEFAULT_RECORDS_LIMIT = 10;
const emptyFilter: Filter & Range = {
  range: {
    type: 'n',
    parameters: [],
  },
  filter: [],
};
const PresetPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('pages.presetAp');
  const { project } = useOutletContext<AnalyticPageOutlet>();
  const { userSettings } = useAppSelector((state) => state.userSettings);
  //api
  const { data: aliasesData } = useGetAliasesListQuery();
  const { data: analyticsProjectPreset, isLoading } = useGetAnalyticsProjectPresetQuery(
    {
      project_id: project?.project_id || '',
    },
    { skip: !project?.project_id },
  );
  const { data: presetTemplates } = useGetPresetTemplateGroupsListQuery();
  const [editPresetSettings] = useEditProjectPresetMutation();
  const [createPresetTemplate] = useCreateSinglePresetMutation();
  //useState
  const [recordsParams, getRecordsParams] = useState<GetFilterParamsNew>({
    offset: 0,
    limit: userSettings?.tablesLimit?.analyticProject?.presets || DEFAULT_RECORDS_LIMIT,
    preset_mode: false,
    filter: analyticsProjectPreset?.preset || emptyFilter,
  });
  const defaultFilter: Filter = {
    filter: [
      {
        id: getFilteringKeys().find((key) => key.title === 'CallID')?.id || '',
        value: '0',
        condition: '!=',
      },
    ],
  };
  useEffect(() => {
    getRecordsParams((prev) => ({
      ...prev,
      limit: userSettings?.tablesLimit?.analyticProject?.presets || 10,
    }));
  }, [userSettings?.tablesLimit?.analyticProject?.presets]);

  useEffect(() => {
    if (!analyticsProjectPreset?.preset) return;
    getRecordsParams((prev) => ({
      ...prev,
      filter: analyticsProjectPreset?.preset as Filter & Range,
    }));
  }, [analyticsProjectPreset?.preset]);
  // handlers
  function createNewPresetSettings(filter?: Filter & Range) {
    filter
      ? editPresetSettings({ params: { project_id: project?.project_id || '' }, body: filter })
      : editPresetSettings({
          params: { project_id: project?.project_id || '' },
          body: getFilteringKeys().find((key) => key.title === 'CallID')?.id
            ? { ...emptyFilter, filter: defaultFilter.filter }
            : { ...emptyFilter, range: { type: 'l', parameters: [{ num: 7 }] } },
        });
  }
  function onChangeFilterHandler(data: Filter & Range) {
    getRecordsParams((prev) => ({ ...prev, filter: data }));
  }
  function getFilteringKeys() {
    return (
      aliasesData?.aliases
        .filter((item) => !item.forbidden_rules)
        .map((alias) => ({
          id: alias.alias_id,
          title: alias.name,
          type_meta: alias.type_meta,
          type_value: alias.type_value,
        })) || []
    );
  }
  const getSaveBtnDisabled = () => {
    if (recordsParams.filter === analyticsProjectPreset?.preset) return true;
    if (project?.status !== AllStatusProject.CREATED && project?.status !== AllStatusProject.PAUSE)
      return true;
    return !project?.owner && project?.shared === GroupSharedStatus.VIEW;
  };

  const getSaveAsTemplateDisabled = () => {
    return !project?.owner && project?.shared === GroupSharedStatus.VIEW;
  };
  function cancelFilter() {
    if (analyticsProjectPreset?.preset === null) return;
    getRecordsParams((prev) => ({
      ...prev,
      filter: analyticsProjectPreset?.preset as Filter & Range,
    }));
  }
  function clearFilter() {
    getRecordsParams((prev) => ({
      ...prev,
      filter: emptyFilter,
    }));
  }
  function saveAsTemplate() {
    if (!presetTemplates) return;
    const presetTemplateData: PresetsCreateDataType = {
      name: t('system.create_as_template_name'),
      shared: false,
      description: '',
      preset_group_id:
        presetTemplates?.find((group) => group.is_default)?.preset_group_id ||
        presetTemplates?.[0]?.preset_group_id,
      rules: recordsParams.filter,
    };
    createPresetTemplate(presetTemplateData)
      .unwrap()
      .then((response) => {
        navigate(`/${navigationRoutes.presets}/${response.preset_id}`);
      });
  }
  //jsx markup
  const getTitleFilter = <h2 className="text-1color font-bold">{t('filter.title')}</h2>;
  const getFooterFilter = (
    <div className="flex">
      <div className="grow">
        <Button
          label={t('filter.buttons.save')}
          className="mr-[10px]"
          onClick={() => createNewPresetSettings(recordsParams.filter)}
          disabled={getSaveBtnDisabled()}
        />
        <Button
          fill="linked"
          label={t('filter.buttons.save_as_template')}
          className="mr-[-22px]"
          onClick={() => saveAsTemplate()}
          disabled={getSaveAsTemplateDisabled()}
        />
        <Button
          fill="linked"
          label={t('filter.buttons.cancel')}
          onClick={() => cancelFilter()}
          disabled={getSaveBtnDisabled()}
        />
      </div>
      <div>
        <Button
          fill="linked"
          label={t('filter.buttons.delete')}
          variant="danger"
          icon="TrashIcon"
          disabled={
            project?.status === 'ACTIVE' ||
            (!project?.owner && project?.shared === GroupSharedStatus.VIEW)
          }
          onClick={() => clearFilter()}
        />
      </div>
    </div>
  );
  if (isLoading) return <></>;
  return (
    <div>
      {/*|| !analyticsProjectPreset?.preset?.filter.length*/}
      {analyticsProjectPreset?.preset === null ||
      (!analyticsProjectPreset?.preset.filter.length &&
        analyticsProjectPreset?.preset.range.type === 'n') ? (
        <PresetFillChooseBlock
          createNewPresetSettings={createNewPresetSettings}
          project={project}
          presetTemplates={presetTemplates}
        />
      ) : (
        <div>
          <EasyFilterView
            dataIdStartDate="analytic-project-preset-date-from"
            dataIdEndDate="analytic-project-preset-date-to"
            titleMarkup={getTitleFilter}
            footerMarkup={getFooterFilter}
            data={recordsParams.filter}
            onChangeHandler={onChangeFilterHandler}
            keysForFiltering={getFilteringKeys() || []}
            fillFromTemplate
          />
          <PresetPageTableBlock recordsParams={recordsParams} getRecordsParams={getRecordsParams} />
        </div>
      )}
    </div>
  );
};

export default PresetPage;

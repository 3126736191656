import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  // ChartsListOnDashboard,
  ChartsListOnDashboardResponse,
  CreateChartOnDashboardType,
  DashboardChartSettings,
  dashboardChartsSettings,
  EditGraphLayoutType,
} from '@app/interfaces/dashboards.type';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { ReportChart } from '@app/interfaces/report.type';

import { baseQuery } from '../baseQuery';

export const dashboardChartsApi = createApi({
  reducerPath: 'dashboardChartsApi',
  tagTypes: [''],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getDashboardGraphList: build.query<(dashboardChartsSettings & ReportChart)[], string>({
      async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
        const graphsArray: (dashboardChartsSettings & ReportChart)[] = [];
        const graphsOnDashboard = (await fetchWithBQ({
          url: serverRoutes.dashboardGraphs,
          params: { dashboard_id: arg },
        })) as unknown as { data: ChartsListOnDashboardResponse };
        const data = graphsOnDashboard?.data?.dashboard_graph_list;
        for (const graph of data) {
          const graphData = (await fetchWithBQ({
            url: serverRoutes.chartData,
            method: 'POST',
            body: { id: graph.live_report_graph_id, ...graph.settings_override },
          })) as unknown as {
            data: { data: dashboardChartsSettings & ReportChart };
            error?: {
              status: number;
              data: {
                data: ReportChart;
              };
            };
          };
          if (graphData.error?.status) {
            switch (graphData.error?.status) {
              case 500:
                graphsArray.push({
                  ...graphData?.data?.data,
                  errorStatus: graphData.error?.status,
                  dashboard_graph_id: graph.dashboard_graph_id,
                  live_report_id: graph.live_report_id,
                  layout: { ...graph.layout, i: graph.dashboard_graph_id },
                });
                break;
              case 403:
                graphsArray.push({
                  ...graphData?.data?.data,
                  type: 'barchart',
                  errorStatus: graphData.error?.status,
                  graph_id: graph.live_report_graph_id,
                  name: 'error',
                  dataLabels: false,
                  categories: [],
                  series: [],
                  range: { type: 'l', parameters: [{ num: 7 }] },
                  // order_by: OrderByChartSettings;
                  line_settings: { active: false, color: '', value: [] },
                  styles: {
                    header_size: 24,
                    text_size: 12,
                    value_position: 'inside',
                    grid: false,
                    text_color: '#000',
                    legend_position: 'bottom',
                  },
                  dashboard_graph_id: graph.dashboard_graph_id,
                  live_report_id: graph.live_report_id,
                  layout: { ...graph.layout, i: graph.dashboard_graph_id },
                });
                break;
              default:
                break;
            }
          } else {
            graphData.data &&
              graphsArray.push({
                ...graphData.data.data,
                dashboard_graph_id: graph.dashboard_graph_id,
                live_report_id: graph.live_report_id,
                layout: { ...graph.layout, i: graph.dashboard_graph_id },
              });
          }
        }
        return graphsArray
          ? { data: graphsArray }
          : { error: { error: '123', data: [] } as FetchBaseQueryError };
      },
    }),
    addNewGraphOnDashboard: build.mutation<DashboardChartSettings, CreateChartOnDashboardType>({
      async queryFn(arg, { dispatch }, extraOptions, fetchWithBQ) {
        const createGraphData = (await fetchWithBQ({
          url: serverRoutes.dashboardGraph,
          method: 'POST',
          body: arg,
        })) as unknown as { data: DashboardChartSettings };

        const graphData = (await fetchWithBQ({
          url: serverRoutes.chartData,
          method: 'POST',
          body: { id: createGraphData.data.live_report_graph_id },
        })) as unknown as { data: { data: ReportChart } };

        const formattedChartObject: dashboardChartsSettings & ReportChart = {
          ...graphData.data.data,
          layout: { ...createGraphData.data.layout, i: createGraphData.data.dashboard_graph_id },
          dashboard_graph_id: createGraphData.data.dashboard_graph_id,
          live_report_id: createGraphData.data.live_report_id,
        };
        dispatch(
          dashboardChartsApi.util?.updateQueryData(
            'getDashboardGraphList',
            arg.dashboard_id || '',
            (draft) => {
              draft.push(formattedChartObject);
            },
          ),
        );

        return createGraphData
          ? { data: createGraphData.data }
          : { error: { error: '123', data: [] } as FetchBaseQueryError };
      },
    }),
    editGraphOnDashboard: build.mutation<
      DashboardChartSettings,
      { body: CreateChartOnDashboardType; id: string }
    >({
      async queryFn(arg, { dispatch }, extraOptions, fetchWithBQ) {
        const editedGraphData = (await fetchWithBQ({
          url: serverRoutes.dashboardGraph,
          method: 'PUT',
          params: { dashboard_graph_id: arg.id },
          body: arg.body,
        })) as unknown as { data: DashboardChartSettings };

        const graphData = (await fetchWithBQ({
          url: serverRoutes.chartData,
          method: 'POST',
          body: {
            id: editedGraphData.data.live_report_graph_id,
            range: arg.body.settings_override.range,
          },
        })) as unknown as { data: { data: ReportChart } };

        const formattedChartObject: dashboardChartsSettings & ReportChart = {
          ...graphData.data.data,
          layout: { ...editedGraphData.data.layout, i: editedGraphData.data.dashboard_graph_id },
          dashboard_graph_id: editedGraphData.data.dashboard_graph_id,
          live_report_id: editedGraphData.data.live_report_id,
        };
        dispatch(
          dashboardChartsApi.util?.updateQueryData(
            'getDashboardGraphList',
            arg.body.dashboard_id || '',
            (draft) => {
              return draft.map((draftChart) => {
                if (draftChart.dashboard_graph_id === editedGraphData.data.dashboard_graph_id) {
                  return formattedChartObject;
                }
                return draftChart;
              });
            },
          ),
        );

        return editedGraphData
          ? { data: editedGraphData.data }
          : { error: { error: '123', data: [] } as FetchBaseQueryError };
      },
      // query: ({ body, id }) => ({
      //   url: serverRoutes.dashboardGraph,
      //   method: 'PUT',
      //   params: { dashboard_graph_id: id },
      //   body,
      // }),
    }),
    deleteGraphOnDashboard: build.mutation({
      query: ({ dashboard_graph_id }: { dashboard_graph_id: string; dashboard_id?: string }) => ({
        url: serverRoutes.dashboardGraph,
        method: 'DELETE',
        params: { dashboard_graph_id },
      }),
      onCacheEntryAdded: async (arg, { dispatch, cacheDataLoaded }) => {
        await cacheDataLoaded;
        dispatch(
          dashboardChartsApi.util?.updateQueryData(
            'getDashboardGraphList',
            arg.dashboard_id || '',
            (draft) => {
              return draft.filter(
                (draftChart) => draftChart.dashboard_graph_id !== arg.dashboard_graph_id,
              );
            },
          ),
        );
      },
    }),
    // dashboard layout change
    editDashboardGraphLayout: build.mutation<
      DashboardChartSettings,
      { body: EditGraphLayoutType[]; params: { dashboard_id: string } }
    >({
      query: ({ body, params }) => ({
        url: serverRoutes.dashboardLayout,
        method: 'PUT',
        params,
        body: { dashboard_graph_order: body },
      }),
      onCacheEntryAdded: async ({ params, body }, { dispatch, cacheDataLoaded }) => {
        await cacheDataLoaded.then(() => {
          dispatch(
            dashboardChartsApi.util?.updateQueryData(
              'getDashboardGraphList',
              params.dashboard_id,
              (draft) => {
                return draft.map((draftChart) => ({
                  ...draftChart,
                  layout: {
                    ...draftChart.layout,
                    ...(body.find(
                      (layoutItem) =>
                        layoutItem.dashboard_graph_id === draftChart.dashboard_graph_id,
                    )?.layout || draftChart.layout),
                  },
                }));
              },
            ),
          );
        });
      },
    }),
    // getDashboard: build.query<DashboardsSettingsType, string>({
    //   query: (body) => ({
    //     url: serverRoutes.dashboardGraph,
    //     params: { id: body },
    //   }),
    // }),
  }),
});

export const {
  useGetDashboardGraphListQuery,
  useAddNewGraphOnDashboardMutation,
  useEditGraphOnDashboardMutation,
  useDeleteGraphOnDashboardMutation,
  useEditDashboardGraphLayoutMutation,
} = dashboardChartsApi;

import React, { FC } from 'react';
import AudioBuild from '@app/components/audio-build';
import { NotFoundedPage } from '@app/pages';
import { useParams } from 'react-router';

const ReportRecordPage: FC = () => {
  const { record_id, analytics_id } = useParams();

  if (!record_id) return <NotFoundedPage />;
  return <AudioBuild recordId={record_id} projectId={analytics_id} withMetric />;
};

export default ReportRecordPage;

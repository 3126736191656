module.exports = {
  title: 'Настройки',
  warning: 'Предупреждение',
  navigation: {
    common_settings: 'Общие настройки',
    alias: 'Алиасы',
    recognition_rules: 'Правила распознавания',
    integration_settings: 'Интеграция'
  },
  recog_rules: {
    table: {
      name: 'Название',
      rules: 'Условие отбора записей',
      asr_model_id: 'Модель распознавания',
      actions: 'Действия'
    },
    modal_label: 'Создать правило распознавания',
    modal_label_edit: 'Редактировать правило распознавания',
    modal_title_recognize: 'Распознать за период',
    modal_label_recognize: 'Название правила',
    recognition_rules_queue: 'Размер очереди распознавания ',
    from: 'от',
    to: 'до',
    add_to_recognition_queue: 'Добавить в очередь распознавания',
    recognition_rules: 'Правила распознавания',
    interval_error: 'Неверный интервал',
    recognition_model_default: 'Модель распознавания по умолчанию',
    recognition_rules_comment:
      'Комментарий. Все записи, которые не попадают в прочие условия отбора',
    add_recognition_rule: 'Добавить правило',
    form: {
      name: 'Название',
      placeholder_name: 'Название',
      filter_rules: 'Правила фильтра',
      recog_model: 'Модель распознавания',
      choose_placeholder: 'Выбрать...',
      button_label_delete: 'Удалить правило',
    },
  },
  integrations:{
    integrations_title: 'Интеграции',
    add_integration: 'Добавить интеграцию',
    delete_integration_text: 'Вы действительно хотите удалить данную интеграцию?',
    table: {
      name: 'Название  Интеграции',
      description: 'Описание интеграции',
      type: 'Тип интеграции',
      actions: 'Действия',
    },
    modal_title: {
      create: 'Создание интеграции REST API',
      edit: 'Настройка интеграции REST API',
    },
    form: {
      name: 'Название',
      saveButton: 'Сохранить',
      newKeysButton: 'Создать новую пару ключей',
      deleteButton: 'Удалить интеграцию',
    },
    createdIntModalLabel: 'Генерация связки ключей',
    createdIntModalKey: 'Cкопируйте данный ключ доступа.',
    createdIntModalDescription: 'После закрытия данного окна ключ нельзя будет увидеть повторно.',
  },
  common_setting: {
    table: {
      name: 'Название',
      channel: 'Номер дорожки',
      actions: 'Действия'
    },
    delete_channel_text: 'Вы действительно хотите удалить данный канал?',
    form: {
      icon: 'Иконка',
      operator: 'Оператор',
      client: 'Клиент',
      other: 'Другой',
      channel_name: 'Название канала',
      channel_name_placeholder: 'Название',
      button_label: 'Сохранить',
      channel_number: 'Номер дорожки',
    },
    title: 'Аудио-каналы',
    modal_title: {
      edit: 'Настроить канал',
      create: 'Добавить канал',
    },
    button: {
      add_channel: 'Добавить канал',
    },
  },
  alias: {
    add_alias_button: 'Добавить алиас',
    table: {
      name: 'Название',
      meta_name: 'Название метаданного',
      type_value: 'Тип значения',
      type_meta: 'Тип метаданного',
      utils: 'Действия'
    },
    popup_hints: {
      templates_list_count: 'Количество шаблонов в группе',
    },
    alias_title: 'Алиасы',
    delete_alias_text: 'Данное действие повлияет на работу системы',
    delete_alias_text_alias_is_using:
      'Данный алиас испольуется в других настройках системы и поэтому его нельзя удалить',
    modal_label: 'Создать алиас',
    modal_label_edit: 'Редактировать алиас',
    values_translate: {
      str: 'Текст',
      num: 'Число',
      system: 'Системный',
      base: 'Базовый',
      record: 'Запись',
      channel: 'Канал',
    },
    form: {
      alias: 'Алиас',
      meta: 'Метаданные',
      type_meta: 'Тип метаданного',
      type_value: 'Тип значения',
      text: 'Текст',
      number: 'Число',
      record_level: 'Уровни записей',
      channel_level: 'Уровни каналов',
      button_label: 'Сохранить',
      button_label_delete: 'Удалить алиас',
      placeholder_name: 'Название',
      placeholder_meta_name: 'Значение',
    },
  },
};

import React from 'react';
import { Route } from 'react-router-dom';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { BreadcrumbsItem } from '@app/layouts/layout';
import RequiredAuth from '@app/routes/required-auth.route';
import { LastProjectOutlet, LastProjectRecordsPage, SingleLastRecordPage } from '@app/pages';

export const lastRecordRoute = (
  <Route
    path={navigationRoutes.projectRecords}
    element={
      <RequiredAuth>
        <LastProjectOutlet />
      </RequiredAuth>
    }
    handle={{
      crumb: () => <BreadcrumbsItem path={navigationRoutes.analytics} crumb="project_records" />,
    }}
  >
    <Route path=":recordProjectId">
      <Route index element={<LastProjectRecordsPage />} />
      <Route path=":recordId" element={<SingleLastRecordPage />} />
    </Route>
  </Route>
);

module.exports = {
  popup_hints: {
    dashboard_list: 'Открыть список рабочих столов',
    dashboard_intervals: 'Задать временной период для всех диаграмм',
    grid_layout_settings: 'Изменить размер и расположение графиков'
  },
  system : {
    not_owner_only: 'Виден всем пользователям',
    owner_only: 'Виден только владельцу',
    add_new_dashboard: 'Добавить рабочий стол',
    new_dashboard_system_name: 'Рабочий стол %{name}',
    dashboard_empty: 'На рабочем столе нет графиков',
  },
  buttons: {
    add_new_graph : 'Добавить график',
    add_dashboard: 'Добавить рабочий стол',
  },
  modals: {
    editModal : {
      choose_interval: 'Выбор периода по умолчанию',
      warning_text: 'Выбранный период будет сохранен и действовать на график вне зависимости от настроенного периода графика в отчете, а также от выбранного периода на рабочем столе.',
      save_chart_settings: 'Сохранить',
      add_chart: 'Добавить',
    },
    create_chart_modal: {
      title: 'Добавить график из отчета'
    },
    delete_modal: {
      main_text: 'Вы действительно хотите удалить данный рабочий стол?',
    }
  }
}

import {
  AllChanelsArrType,
  MetricObjType,
  MetricTypesArrType,
} from '@app/interfaces/pages-types/anatylics-metric.type';
import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Button, CircleButton, Input, Select, Toggle } from '@ui';

import { AutoFocusHook } from '@app/utils/helpers';

import { AllStatusProject } from '@app/interfaces/analytics';

import useTranslation from '@app/hooks/use-translation';

import { Controller, useForm } from 'react-hook-form';

import { WordsListComponent } from '@app/components/ui/add-words-and-dictionaries-container/wordsListComponent';

import { MetricTemplatesType } from '@app/interfaces/slices-types/metric-templates.type';

import {
  useCreateMetricTemplateMutation,
  useEditMetricTemplateMutation,
  useGetMetricTemplateGroupsListQuery,
} from '@app/store/api/metric-template.api';

import {
  useCreateProjectMetricGroupMutation,
  useEditProjectMetricGroupMutation,
} from '@app/store/api/metrics.api';

import { useGetAllDictionariesListQuery } from '@app/store/api/dictionaries.api';

import { VadModalsChildrenType } from '../constantsForModals';
import { ColorInput } from '../color-input/color-input';
import { AddToTemplateModal } from '../add-to-template-modal/add-to-template-modal';
type FormType = {
  activeColor: string;
  switchValue: boolean;
  name: string;
  channel: string;
  exclude_begin_sec: number;
  exclude_end_sec: number;
  select: string;
  wordsListArray: string[];
  dictionariesListArray: string[];
};
export const SpeechModals: React.FC<{
  modalsSetState: (type: string) => void;
  copyModal: boolean;
  dataFields: VadModalsChildrenType[];
  metric: MetricTypesArrType;
  allChannels?: AllChanelsArrType[];
  editModal?: boolean;
  metricTemplate?: boolean;
  createFromTemplate?: boolean;
  status?: AllStatusProject | undefined;
  page?: string;
  viewOnly?: boolean;
  metricSettings?: MetricObjType | undefined;
  metricTemplateSettings?: MetricTemplatesType | undefined;
}> = ({
  dataFields,
  metric,
  allChannels,
  editModal,
  modalsSetState,
  metricTemplate,
  createFromTemplate,
  status,
  page,
  copyModal,
  viewOnly,
  metricTemplateSettings,
  metricSettings,
}) => {
  const { id } = useParams();
  const { t } = useTranslation('pages.metricTemplates');
  const { t: tf } = useTranslation('common.forms');
  const [createTemplateModal, setCreateTemplateModal] = React.useState(false);
  //api
  const { data: metricTemplates } = useGetMetricTemplateGroupsListQuery();
  const { data: dictionaries } = useGetAllDictionariesListQuery();
  const [createMetric] = useCreateProjectMetricGroupMutation();
  const [editMetric] = useEditProjectMetricGroupMutation();
  const [editMetricTemplate] = useEditMetricTemplateMutation();
  const [createMetricTemplate] = useCreateMetricTemplateMutation();
  const defaultValue = (): FormType => {
    if (editModal) {
      if (metricTemplate) {
        return {
          wordsListArray: metricTemplateSettings?.settings.wordlist || [],
          dictionariesListArray: metricTemplateSettings?.settings.dictionaries || [],
          activeColor: `#${metricTemplateSettings?.settings.color?.toLowerCase() || ''}`,
          switchValue: metricTemplateSettings?.settings.all_word_forms || false,
          name: metricTemplateSettings?.name || '',
          channel: metricTemplateSettings?.settings.channel || '',
          exclude_begin_sec: metricTemplateSettings?.settings.exclude_begin_sec || 0,
          exclude_end_sec: metricTemplateSettings?.settings.exclude_end_sec || 0,
          select: metricTemplateSettings?.metric_templates_group_id || 'default',
        };
      } else {
        return {
          wordsListArray: metricSettings?.settings.wordlist || [],
          dictionariesListArray: metricSettings?.settings.dictionaries || [],
          activeColor: `#${metricSettings?.settings.color?.toLowerCase() || ''}`,
          switchValue: metricSettings?.settings.all_word_forms || false,
          name: metricSettings?.name || '',
          channel: metricSettings?.settings.channel || '',
          exclude_begin_sec: metricSettings?.settings.exclude_begin_sec || 0,
          exclude_end_sec: metricSettings?.settings.exclude_end_sec || 0,
          select: '',
        };
      }
    } else {
      if (createFromTemplate) {
        return {
          wordsListArray: metricTemplateSettings?.settings.wordlist || [],
          dictionariesListArray: metricTemplateSettings?.settings.dictionaries || [],
          activeColor: `#${metricTemplateSettings?.settings.color?.toLowerCase() || ''}`,
          switchValue: metricTemplateSettings?.settings.all_word_forms || false,
          name: metricTemplateSettings?.name || '',
          channel: metricTemplateSettings?.settings.channel || '',
          exclude_begin_sec: metricTemplateSettings?.settings.exclude_begin_sec || 0,
          exclude_end_sec: metricTemplateSettings?.settings.exclude_end_sec || 0,
          select: metricTemplateSettings?.metric_templates_group_id || 'default',
        };
      } else
        return {
          activeColor: '',
          switchValue: false,
          name: '',
          channel: '',
          exclude_begin_sec: 0,
          exclude_end_sec: 0,
          select: 'default',
          wordsListArray: [],
          dictionariesListArray: [],
        };
    }
  };
  const {
    register,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    clearErrors,
    control,
  } = useForm<FormType>({ defaultValues: defaultValue() });
  const copyToClipboard = useCallback(() => {
    event?.preventDefault();
    let textToCopy: string | undefined = '';
    if (metricSettings && !metricTemplate) {
      textToCopy = metricSettings.metric_id;
    } else if (metricTemplateSettings && metricTemplate) {
      textToCopy = metricTemplateSettings.metric_template_id;
    }
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy);
    }
  }, [metricSettings, metricTemplateSettings, metricTemplate]);
  const disableFunc = () => {
    if (status === AllStatusProject.PAUSE) {
      return false;
    }
    return status !== AllStatusProject.CREATED;
  };
  const resetAsyncForm = () => {
    modalsSetState(metric.type);
  };

  const onSubmit = (data) => {
    const obj: MetricObjType = {
      name: data.name,
      description: ' ',
      type: metric.type,
      result_value_type: metric.result_value_type,
      settings: {
        channel: data.channel,
        all_word_forms: data.switchValue,
        color: data.activeColor?.slice(1).toUpperCase(),
        wordlist: data.wordsListArray,
        dictionaries: data.dictionariesListArray,
        exclude_begin_sec: data.exclude_begin_sec,
        exclude_end_sec: data.exclude_end_sec,
      },
    };
    if (!editModal) {
      if (metricTemplate) {
        createMetricTemplate({ params: { metric_templates_group_id: watch('select') }, body: obj });
      } else {
        id && createMetric({ params: { project_id: id }, body: obj });
      }
    } else {
      if (metricSettings?.metric_id) {
        id &&
          editMetric({
            params: { project_id: id, metric_id: metricSettings.metric_id },
            body: obj,
          });
      } else if (metricTemplate) {
        if (copyModal) {
          createMetricTemplate({
            params: { metric_templates_group_id: watch('select') },
            body: obj,
          });
        } else {
          metricTemplateSettings?.metric_template_id &&
            editMetricTemplate({
              params: {
                metric_templates_group_id: watch('select'),
                id: metricTemplateSettings?.metric_template_id,
              },
              body: obj,
            });
        }
      }
    }
    resetAsyncForm();
  };
  const getDefaultTemplate = () => {
    return metricTemplates?.find((e) => e.is_default)?.metric_templates_group_id;
  };
  const createMetricTemplateApplyBtn = () => {
    const obj: MetricObjType = {
      name: watch('name') as string,
      description: ' ',
      type: metric.type,
      result_value_type: metric.result_value_type,
      settings: {
        channel: watch('channel'),
        all_word_forms: watch('switchValue'),
        color: watch('activeColor').slice(1).toUpperCase(),
        wordlist: watch('wordsListArray'),
        dictionaries: watch('dictionariesListArray'),
        exclude_begin_sec: watch('exclude_begin_sec'),
        exclude_end_sec: watch('exclude_end_sec'),
      },
    };
    createMetricTemplate({
      params: { metric_templates_group_id: getDefaultTemplate() as string },
      body: obj,
    }).then(() => {
      resetAsyncForm();
      setCreateTemplateModal(false);
    });
    // .catch(() => {
    //   toast.error(t('fill_all'));
    // });
  };

  const clickColor = (event) => {
    setValue('activeColor', event.currentTarget.value);
  };
  const inputFocusRef = AutoFocusHook();

  const getSelectItems = () => {
    return metricTemplates?.map((e) => {
      return { title: e.name, value: e.metric_templates_group_id };
    });
  };
  function changeDictionariesArray(word: string[]) {
    setValue('dictionariesListArray', word);
  }
  function changeWordsArray(word: string[]) {
    setValue('wordsListArray', word);
  }
  function handleClearWordsComponent() {
    setValue('wordsListArray', []);
    setValue('dictionariesListArray', []);
  }
  return (
    <div className={`${metric.type === 'find-words' && 'w-full justify-between flex gap-[30px]'}`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full relative">
          <div
            className={`${
              metric.type === 'find-words' ? 'w-[500px]' : 'w-[550px]'
            } flex flex-col gap-[20px]`}
          >
            <div className="flex items-center justify-between">
              <span className="max-w-[218px] text-0color text-[15px]">{t('metric_group')}</span>
              <span className="mr-[150px]">
                <b>
                  {metric.group.toUpperCase()} {t('one_metric')}
                </b>
              </span>
            </div>
            <div>
              {dataFields.map((e) => {
                return e.inputText.map((e) => {
                  return (
                    <div className="flex items-center justify-between" key={e.id}>
                      <span className="max-w-[218px] text-0color text-[15px]">{e.labelTitle}</span>
                      <div className="w-[280px]">
                        <Input
                          {...inputFocusRef}
                          {...register('name', {
                            required: { value: true, message: tf('required') },
                          })}
                          name="name"
                          placeholder={e.placeholder}
                          error={errors.name?.message}
                        />
                      </div>
                    </div>
                  );
                });
              })}
            </div>
            {metricTemplate && (
              <div className="flex items-center justify-between">
                <span className="text-0color text-[15px]">{t('metric_templates_group')}</span>
                <div className="w-[280px]">
                  <Controller
                    render={() => (
                      <Select
                        error={errors.select?.message}
                        defaultValue={watch('select')}
                        options={[
                          ...(getSelectItems() || []),
                          {
                            title: t('choose') + '...',
                            value: 'default',
                            disabled: true,
                            nullable: true,
                          },
                        ]}
                        onChange={(value) => {
                          setValue('select', value as string);
                          clearErrors('select');
                        }}
                      />
                    )}
                    name={'select'}
                    rules={{ validate: (value) => value !== 'default' || tf('required') }}
                    control={control}
                  />
                </div>
              </div>
            )}
            <div className="flex items-center justify-between mr-[150px] relative">
              <span className="max-w-[218px] text-0color text-[15px]">{t('channel_search')}</span>
              <Controller
                render={() => (
                  <div className="flex flex-col min-w-[130px]">
                    {metric.type !== 'speech-rate-duration' && (
                      <div className="flex items-center gap-[10px]">
                        <input
                          name="channel"
                          onChange={(e) => {
                            setValue('channel', e.target.value);
                            clearErrors('channel');
                          }}
                          type="checkbox"
                          value={'all'}
                          checked={watch('channel') === 'all'}
                          className="focus:ring-offset-0 focus:ring-0 rounded-full"
                        />
                        <label className="text-[14px] text-0color">{t('all')}</label>
                      </div>
                    )}
                    {allChannels?.map((chanel) => {
                      return (
                        <div
                          className="flex items-center gap-[10px]"
                          key={chanel.settings_channels_id}
                        >
                          <input
                            name="channel"
                            onChange={(e) => {
                              setValue('channel', e.target.value);
                              clearErrors('channel');
                            }}
                            type="checkbox"
                            checked={watch('channel') === chanel.settings_channels_id}
                            value={chanel.settings_channels_id}
                            className="focus:ring-offset-0 focus:ring-0 rounded-full"
                          />
                          <label className="text-[14px] text-0color">{chanel.name}</label>
                        </div>
                      );
                    })}
                  </div>
                )}
                name={'channel'}
                control={control}
                rules={{ validate: (value) => !!value || tf('required') }}
              />
              {errors.channel?.message && (
                <div
                  className={
                    'absolute bottom-[-17px] right-[10px] text-sm text-red-600 dark:text-red-500'
                  }
                >
                  {errors.channel?.message}
                </div>
              )}
            </div>
            {metric.type === 'find-words' && (
              <>
                <div className="flex items-center justify-between mr-[247px]">
                  <span className="max-w-[218px] text-0color text-[15px]">{t('all_words')}</span>
                  <Toggle
                    onChecked={(e) => setValue('switchValue', e)}
                    size={'large'}
                    checked={watch('switchValue')}
                  />
                </div>
                <div className="flex items-center justify-between relative">
                  <span className="max-w-[218px] text-0color text-[15px]">{t('color')}</span>
                  <Controller
                    render={() => (
                      <div id="colors" className="flex items-center gap-[6px] mr-[23px]">
                        <ColorInput
                          onClick={(event) => {
                            clickColor(event);
                            clearErrors('activeColor');
                          }}
                          active={watch('activeColor')}
                        />
                      </div>
                    )}
                    name={'activeColor'}
                    control={control}
                    rules={{ validate: (value) => !!value || tf('required') }}
                  />
                  {errors.activeColor?.message && (
                    <div
                      className={
                        'absolute bottom-[-17px] right-[160px] text-sm text-red-600 dark:text-red-500'
                      }
                    >
                      {errors.activeColor?.message}
                    </div>
                  )}
                </div>
              </>
            )}
            {dataFields.map((e) => {
              return e.inputNumber.map((e, index) => {
                return (
                  <div key={e.id} className="flex items-center justify-between">
                    <span className="text-0color max-w-[200px] text-[15px]">{e.labelTitle}</span>
                    <div className="bg-transparent w-[180px] mr-[101px]">
                      <Input
                        {...register(e.id as 'exclude_begin_sec' | 'exclude_end_sec')}
                        max={1000000}
                        min={0}
                        name={e.id}
                        key={index}
                        type={'number'}
                        icon="ClockIcon"
                      />
                    </div>
                  </div>
                );
              });
            })}
          </div>
          <div className="sticky z-50 bottom-0 w-full">
            <div className="bg-[#fff] ">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center justify-between gap-[15px] py-4">
                  <div className="gap-[15px] flex">
                    <Button
                      disabled={(page !== 'metricTemplate' && disableFunc()) || viewOnly}
                      type="submit"
                      label={t('save')}
                    />
                    <Button onClick={() => resetAsyncForm()} fill="linked" label={t('cancel')} />
                  </div>
                  <div>
                    {page !== 'metricTemplate' && !createFromTemplate && (
                      <Button
                        onClick={() => setCreateTemplateModal(true)}
                        fill="linked"
                        icon="FolderConfigIcon"
                        label={t('save_as_template')}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <Button
                    onClick={() => handleClearWordsComponent()}
                    fill="linked"
                    variant="danger"
                    label={t('clear_all')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="flex flex-col">
        {metric.type === 'find-words' && (
          <WordsListComponent
            dictionaries={dictionaries}
            className="!w-[503px]"
            wordsListArray={watch('wordsListArray')}
            setWordsListArray={changeWordsArray}
            dictionariesListArray={watch('dictionariesListArray')}
            setDictionariesListArray={changeDictionariesArray}
          />
        )}
        <AddToTemplateModal
          show={createTemplateModal}
          name={watch('name')}
          applyBtn={createMetricTemplateApplyBtn}
          onClose={setCreateTemplateModal}
        />
        <div className="text-0color text-[15px] w-full h-full flex items-end justify-start gap-3 mb-[20px]">
          <div className="mb-[3px]">{t('metric_id')}</div>
          <div className="flex gap-1">
            <p>
              {metricTemplate
                ? metricTemplateSettings?.metric_template_id
                : metricSettings?.metric_id}
            </p>
            <CircleButton
              onClick={copyToClipboard}
              icon="CopyIcon"
              className="text-action mb-[5px]"
              size={16}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { FC, useState } from 'react';
import AccordionUI from '@app/components/ui/Accordion/Aссordion';
import AccordionItemUI from '@app/components/ui/Accordion/AccordionItem';
import cn from 'classnames';
import { ChevronDownIcon } from '@icons';
import { EasyModal } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { AccordionItemUIPropsClickChildParams } from '@app/interfaces/userInterface';
import { Filter, Range } from '@app/components/ui/easy-filter/types';
import { PresetsGroupsType } from '@app/interfaces/slices-types/presets-slice.type';
type PresetTemplatesModalBlockPropsType = {
  fillFromTemplateModal: boolean;
  changeFillFromTemplateModalState: React.Dispatch<React.SetStateAction<boolean>>;
  createNewPresetSettings(filter?: Filter & Range): void;
  presetTemplates: PresetsGroupsType[] | undefined;
};
export const PresetTemplatesModalBlock: FC<PresetTemplatesModalBlockPropsType> = (props) => {
  const {
    fillFromTemplateModal,
    changeFillFromTemplateModalState,
    presetTemplates,
    createNewPresetSettings,
  } = props;
  const { t } = useTranslation('pages.presetAp.templatesModal');
  const [activePresetTemplateItem, setActivePresetTemplateItem] = useState('');
  //api

  function clickAccordionPresetItem(event: AccordionItemUIPropsClickChildParams) {
    setActivePresetTemplateItem(event.id === activePresetTemplateItem ? '' : event.id);
  }
  function clickPresetTemplate(data: { filter: Filter & Range }) {
    createNewPresetSettings(data.filter);
  }
  return (
    <EasyModal
      withoutFooter={true}
      show={fillFromTemplateModal}
      onClose={changeFillFromTemplateModalState.bind(null, false)}
      label={t('title')}
      variant={'mediumW650'}
    >
      <AccordionUI>
        {presetTemplates?.map((presetTemplate) => {
          return (
            <AccordionItemUI
              dataId={`ap-preset-group-${presetTemplate.name}`}
              title={presetTemplate.name}
              id={presetTemplate.preset_group_id}
              key={presetTemplate.preset_group_id}
              clickBtnChild={clickAccordionPresetItem}
              active={activePresetTemplateItem === presetTemplate.preset_group_id}
            >
              {presetTemplate.presets.map((preset, index, presets) => (
                <div
                  data-id={`ap-preset-template-${presetTemplate.name}-${preset.name}`}
                  key={preset.preset_id}
                  className={cn(
                    'flex justify-between pt-[20px] pl-[26px] pr-[17px] pb-[19px] shadow-medium cursor-pointer',
                    (index === 0 || index === presets.length - 1) && 'rounded-t-defaultR',
                  )}
                  onClick={() =>
                    clickPresetTemplate({
                      filter: preset.rules,
                    })
                  }
                >
                  <div className=" text-[17px] font-medium leading-[19px] tracking-tight	text-1color">
                    {preset.name}
                  </div>
                  <div className="rotate-[-90deg]">
                    <ChevronDownIcon size={14} /> {/* width 12, height 17 */}
                  </div>
                </div>
              ))}
              {!presetTemplate.presets.length && <p>{t('empty_templates_list')}</p>}
            </AccordionItemUI>
          );
        })}
      </AccordionUI>
    </EasyModal>
  );
};

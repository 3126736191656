import React, { FC, useEffect, useState } from 'react';
import { Box, Button, CustomDatePicker, EasyModal, Input, Select, Skeleton } from '@ui';

import { FormProvider, useForm } from 'react-hook-form';

import cn from 'classnames';

import useTranslation from '@app/hooks/use-translation';

import { OptionItem } from '@app/components/ui/select/select.type';

import { onFilterInput, onFilterSend } from '@app/components/ui/easy-filter/helpers';

import moment from 'moment/moment';

import { AddFromTemplateModal } from '@app/components/ui/easy-filter/blocks/addFromTemplateModal';

import { useGetPresetTemplateGroupsListQuery } from '@app/store/api/preset-template.api';

import { UniversalFilterConstants } from '@app/hooks/use-filter-data';

import {
  Filter,
  FilterRangeParametersItemForRType,
  FilterRangeType,
  KeysForFilteringType,
  Range,
} from './types';

import { Field } from './blocks';
type EasyFilterProps = {
  data: Filter & Range;
  // changeFilterStateHandle: React.Dispatch<React.SetStateAction<Filter & Range>>;
  onChange?: (data: Filter & Range) => void;
  changeFilterOpenState: React.Dispatch<React.SetStateAction<boolean>>;
  calendarBindValue: React.Dispatch<React.SetStateAction<string>>;
  keysForFiltering: KeysForFilteringType[];
  fillFromTemplate?: boolean;
  fillFromTemplateHandler?: (data: Filter & Range) => void;
  isLoading?: boolean;
  modalMode?: boolean;
  dataIdStartDate?: string;
  dataIdEndDate?: string;
};
const DEFAULT_TIME_START = '00:00';
const DEFAULT_TIME_END = '23:59';
const EasyFilter: FC<EasyFilterProps> = (props) => {
  const {
    data: filterData,
    modalMode,
    // changeFilterStateHandle,
    keysForFiltering,
    calendarBindValue,
    changeFilterOpenState,
    onChange,
    fillFromTemplate,
    fillFromTemplateHandler,
    isLoading,
    dataIdStartDate,
    dataIdEndDate,
  } = props;
  const textStyle = cn('font-[500]', 'text-[14px]', 'leading-[16px]', 'text-3color');
  const { t } = useTranslation('components.filter');
  const { conditionsWithoutValue, metaDateTime, metaDateTimeCalendar, metaDateTimeCalendarId } =
    UniversalFilterConstants();
  //api
  const { data: presetTemplates } = useGetPresetTemplateGroupsListQuery();
  const [addFromTemplateModalState, setAddFromTemplateModalState] = useState(false);
  const [metaDateTimeCalendarValue, changeMetaDateTimeCalendarValue] = useState(
    getMetaDateTimeCalendar()[0].value,
  );
  //useState
  const [data, changeFilterData] = useState(filterData);
  const [rangeDatesValue, changeRangeDatesValue] = useState({
    startDate:
      (data.range.parameters[0] as FilterRangeParametersItemForRType)?.value ||
      moment().format('yyyy.MM.DD'),
    startTime:
      moment((data.range.parameters[0] as FilterRangeParametersItemForRType)?.value).format(
        'HH:mm',
      ) || DEFAULT_TIME_START,
    endDate:
      (data.range.parameters[1] as FilterRangeParametersItemForRType)?.value ||
      moment().format('yyyy.MM.DD'),
    endTime:
      moment((data.range.parameters[1] as FilterRangeParametersItemForRType)?.value).format(
        'HH:mm',
      ) || DEFAULT_TIME_END,
  });
  const methods = useForm<Filter & Range>({ defaultValues: data });
  const { handleSubmit, setValue, watch, register, getValues } = methods;
  function getRangeSelectOptions(): OptionItem[] {
    return metaDateTime.map((item) => ({ value: item.id, title: item.name }));
  }
  function getMetaDateTimeCalendar(): OptionItem[] {
    return metaDateTimeCalendar.map((item) => ({ value: item.id, title: item.name }));
  }

  // useEffect
  useEffect(() => {
    changeFilterData(filterData);
  }, [filterData]);

  useEffect(() => {
    const value =
      watch('range.type') === 'cd' || watch('range.type') === 'cw' || watch('range.type') === 'cm'
        ? metaDateTimeCalendarId
        : watch('range.type');
    watch('range.type') === 'cd' ||
      watch('range.type') === 'cw' ||
      (watch('range.type') === 'cm' && changeMetaDateTimeCalendarValue(watch('range.type')));
    setValue('range.type', value as FilterRangeType);
  }, [metaDateTimeCalendarId, setValue, watch]);

  useEffect(() => {
    if (watch(`range.parameters.${0}.num`)) return;
    setValue(`range.parameters.${0}.num`, 1);
  }, [setValue, watch]);

  const renderRangeCondition = {
    n: <div></div>,
    r: (
      <div className={'flex flex-col items-start gap-[35px]'}>
        <div>{t('from')}</div>
        <div>{t('before')}</div>
      </div>
    ),
    l: <div></div>,
    [metaDateTimeCalendarId]: <div>=</div>,
  };
  const renderRangeValue = {
    n: <div></div>,
    r: (
      <div className={'flex flex-col gap-[15px]'}>
        <div className={'flex items-center gap-[11px]'}>
          <CustomDatePicker
            dataId={dataIdStartDate || ''}
            selected={moment(rangeDatesValue.startDate, 'YYYY.MM.DD').toDate()}
            onChange={(date) => changeRangeDatesValue((prev) => ({ ...prev, startDate: date }))}
          />
          <Input
            mask="99:99"
            defaultValue={rangeDatesValue.startTime}
            onChange={(time) =>
              changeRangeDatesValue((prev) => ({ ...prev, startTime: time.target.value }))
            }
          />
        </div>
        <div className={'flex items-center gap-[11px]'}>
          <CustomDatePicker
            dataId={dataIdEndDate || ''}
            selected={moment(rangeDatesValue.endDate, 'YYYY.MM.DD').toDate()}
            onChange={(date) => changeRangeDatesValue((prev) => ({ ...prev, endDate: date }))}
          />
          <Input
            defaultValue={rangeDatesValue.endTime}
            mask="99:99"
            onChange={(time) =>
              changeRangeDatesValue((prev) => ({ ...prev, endTime: time.target.value }))
            }
            error={rangeDatesValue.endTime.replace(/\D/gm, '').length !== 4 ? 'err' : undefined}
          />
        </div>
      </div>
    ),
    l: (
      <div className={'w-full flex items-center gap-[15px]'}>
        <div className={textStyle}>{t('last_from_capital')}</div>
        <Input
          {...register(`range.parameters.${0}.num`, {
            valueAsNumber: true,
            max: 90,
            min: 1,
          })}
          type="number"
          error={
            Number(watch(`range.parameters.${0}.num`)) > 90
              ? t('range_by_days_input_error_label')
              : ''
          }
          min={1}
        />
        <div className={textStyle}>{t('days')}</div>
      </div>
    ),
    [metaDateTimeCalendarId]: (
      <div>
        {/*//todo*/}
        <Select
          defaultValue={metaDateTimeCalendarValue}
          onChange={(value) => {
            changeMetaDateTimeCalendarValue(value);
            calendarBindValue(value as string);
          }}
          options={getMetaDateTimeCalendar()}
        />
      </div>
    ),
  };
  const onSubmit = (value: Filter & Range) => {
    let values = value;
    if (values.range.type === 'n') {
      values = { ...values, range: { ...values.range, parameters: [] } };
    } else if (values.range.type === 'r') {
      const startTime = {
        value: `${moment(rangeDatesValue.startDate, 'YYYY.MM.DD').format('YYYY-MM-DD')} ${
          rangeDatesValue.startTime
        }`,
        condition: '>=',
      };
      const endTime = {
        value: `${moment(rangeDatesValue.endDate, 'YYYY.MM.DD').format('YYYY-MM-DD')} ${
          rangeDatesValue.endTime
        }`,
        condition: '<=',
      };
      values = { ...values, range: { ...values.range, parameters: [startTime, endTime] } };
    }
    const data = onFilterSend(values);
    onChangeFilter({
      ...data,
      filter: data.filter.map((filterData) =>
        conditionsWithoutValue.includes(filterData.condition)
          ? { id: filterData.id, condition: filterData.condition }
          : filterData,
      ),
    } as Filter & Range);
  };
  function onChangeFilter(data: Filter & Range) {
    let newData = {
      ...data,
      filter: data.filter.filter((_, index) => index != data.filter.length - 1),
    };
    if (newData.range.type === metaDateTimeCalendarId) {
      newData = { ...newData, range: { ...newData.range, parameters: [] } };
      newData = {
        ...newData,
        range: { ...newData.range, type: metaDateTimeCalendarValue as FilterRangeType },
      };
    }
    onChange?.(newData);
    changeFilterOpenState(false);
  }
  function addFilterFromTemplateHandler(data: Filter & Range) {
    const convertedData = onFilterInput(data);
    changeFilterData(data);
    methods.setValue('filter', convertedData.filter);
    methods.setValue('range', convertedData.range);
    if (convertedData.range.type === 'r') {
      const startDate = moment(
        (convertedData.range.parameters as unknown as FilterRangeParametersItemForRType)[0].value,
      ).format('YYYY.MM.DD');
      const startTime = moment(
        (convertedData.range.parameters as unknown as FilterRangeParametersItemForRType)[0].value,
      ).format('HH:mm');
      const endDate = moment(
        (convertedData.range.parameters as unknown as FilterRangeParametersItemForRType)[1].value,
      ).format('YYYY.MM.DD');
      const endTime = moment(
        (convertedData.range.parameters as unknown as FilterRangeParametersItemForRType)[1].value,
      ).format('HH:mm');
      changeRangeDatesValue({
        startDate: startDate,
        startTime: startTime,
        endTime: endTime,
        endDate: endDate,
      });
    }
    fillFromTemplateHandler?.(data);
    setAddFromTemplateModalState(false);
  }
  const SkeletonLoading = (
    <div className={'pb-6'}>
      <div className={'flex w-full justify-around items-center pb-6'}>
        <Skeleton width={160} height={15} />
        <Skeleton width={160} height={15} />
        <Skeleton width={160} height={15} />
      </div>
      <div className={'flex flex-col justify-center w-full items-center gap-[15px]'}>
        <Skeleton width={987} height={66} />
        <Skeleton width={987} height={66} />
      </div>
    </div>
  );
  if (isLoading) return SkeletonLoading;
  return (
    <Box className="min-h relative">
      <div className={'w-full flex justify-center items-center '}>
        <FormProvider {...methods}>
          <form className={'relative w-full px-[70px]'} onSubmit={handleSubmit(onSubmit)}>
            <div
              className={
                'text-[12px] leading-[13px] flex pb-[13px] font-semibold uppercase items-center justify-around'
              }
            >
              <div>{t('alias')}</div>
              <div>{t('condition')}</div>
              <div>{t('values')}</div>
            </div>
            <div className="min-h-[300px]">
              {!modalMode && (
                <div className={'w-full mb-[15px]'}>
                  <div
                    className={
                      'flex items-center py-4 relative bg-speech_analitics_filter rounded-[12px] border-solid border-l-[2px] border-speech_analitics px-[24px]'
                    }
                  >
                    <div className={'flex items-start gap-[16px]'}>
                      <div className={'w-[360px]'}>
                        <Select
                          defaultValue={watch('range.type')}
                          onChange={(value) => setValue('range.type', value as FilterRangeType)}
                          options={getRangeSelectOptions()}
                        />
                      </div>
                      <div className={`w-[100px] flex self-center justify-center ${textStyle}`}>
                        {renderRangeCondition[watch('range.type')]}
                      </div>
                      <div className={'w-[330px] flex self-center'}>
                        {renderRangeValue[watch('range.type')]}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <Field modalMode={modalMode} keysForFiltering={keysForFiltering} />
            </div>
            <div className={'py-12 flex items-center justify-between'}>
              <div className={'flex items-center gap-[10px]'}>
                {modalMode ? (
                  <Button
                    fill={'filled'}
                    label={t('confirm')}
                    onClick={() => onSubmit(getValues())}
                  />
                ) : (
                  <Button fill={'filled'} label={t('confirm')} type={'submit'} />
                )}
                <Button
                  fill={'linked'}
                  label={t('cancel')}
                  onClick={changeFilterOpenState.bind(null, false)}
                />
              </div>
              {fillFromTemplate && (
                <div>
                  <Button
                    onClick={() => setAddFromTemplateModalState(true)}
                    fill={'linked'}
                    icon={'FolderConfigIcon'}
                    label={t('fillFromTemplate')}
                  />
                </div>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
      {/*modals*/}
      <EasyModal
        variant="mediumW650"
        withoutFooter
        show={addFromTemplateModalState}
        onClose={setAddFromTemplateModalState.bind(null, false)}
        label={
          <div className="text-0color text-[24px] font-bold mb-[25px]">
            {t('add_preset_from_template_title')}
          </div>
        }
      >
        {addFromTemplateModalState && (
          <AddFromTemplateModal
            addFilterFromTemplateHandler={addFilterFromTemplateHandler}
            groups={presetTemplates || []}
          />
        )}
      </EasyModal>
      {/*modals*/}
    </Box>
  );
};

export default EasyFilter;

import React, { FC, useState } from 'react';
import {
  useAddRecognitionMutation,
  useChangeRecognitionDefaultModelMutation,
  useDeleteRecognitionMutation,
  useEditRecognitionRuleMutation,
  useGetRecognitionDefaultModelQuery,
  useGetRecognitionsListQuery,
  useLazyGetRecognitionRuleQuery,
  useRecognitionRuleQueueQuery,
  useSubmitRecordForRecognitionMutation,
} from '@app/store/api/recognition-rules-settings-page.api';
import { Button, EasyModal, SkeletonPageLoading, Select } from '@ui';
import { TableColumn } from '@app/components/rubber-table/table.types';
import { useGetAliasesListQuery } from '@app/store/api/alias-settings-page.api';
import {
  RecognitionRulesModalWithForm,
  RecognitionRulesTable,
  RecognizeRuleByRangeModal,
} from '@app/pages/settings/blocks/recognition-rules-settings';
import { useGetAsrModelsListQuery } from '@app/store/api/asr_model.api';
import useTranslation from '@app/hooks/use-translation';
import { RecognitionRulesSettingsObj } from '@app/interfaces/settings.type';
import { FilterRangeItem } from '@app/interfaces/filter';
import { CheckIcon, EditIcon, HelpCircleIcon, XIcon } from '@icons';

const RecognitionRulesSettings: FC = () => {
  const { t } = useTranslation('pages.settings');
  const { data, isLoading } = useGetRecognitionsListQuery();
  const { data: aliases } = useGetAliasesListQuery();
  const { data: asr_model } = useGetAsrModelsListQuery();
  const { data: default_model, refetch } = useGetRecognitionDefaultModelQuery();
  const [addRecognition] = useAddRecognitionMutation();
  const [editRecognitionRule] = useEditRecognitionRuleMutation();
  const [deleteRecognitionRule] = useDeleteRecognitionMutation();
  const [changeRecognitionDefaultModel] = useChangeRecognitionDefaultModelMutation();
  const [getRecognitionRule] = useLazyGetRecognitionRuleQuery();
  const [submitRecordForRecognition] = useSubmitRecordForRecognitionMutation();
  const [recognitionRuleName, changeRecognitionRuleName] = useState<string>();
  const [recognitionRuleId, changeRecognitionRuleId] = useState<string>();
  const [isOpenRecognitionModal, changeIsOpenRecognitionModal] = useState(false);
  const [editDefaultModel, changeEditDefaultModel] = useState(false);
  const [editMode, changeEditMode] = useState(false);
  const [isOpenDeleteModal, changeOpenDeleteModal] = useState(false);
  const [isOpenRecognizeModal, changeOpenRecognizeModal] = useState(false);
  const [recognitionRuleSettings, changeRecognitionRuleSettings] = useState<
    RecognitionRulesSettingsObj | undefined
  >();
  const { data: recognitionRuleQueue } = useRecognitionRuleQueueQuery(undefined, {
    pollingInterval: 30000,
  });
  const [defaultRecognitionModel, changeDefaultRecognitionModel] = useState<string | undefined>(
    default_model?.model_id,
  );
  const columns: TableColumn[] = [
    { index: 'name', title: t('recog_rules.table.name'), maxWidth: 220 },
    { index: 'rules', title: t('recog_rules.table.rules') },
    { index: 'asr_model_id', title: t('recog_rules.table.asr_model_id'), maxWidth: 250 },
    { index: 'actions', title: t('recog_rules.table.actions'), maxWidth: 150, divider: 'left' },
  ];
  function deleteHandleClick(id: string) {
    changeRecognitionRuleId(id);
    changeOpenDeleteModal(true);
  }
  function editHandleClick(id: string) {
    changeRecognitionRuleId(id);
    getRecognitionRule(id).then((data) => {
      changeRecognitionRuleSettings(data?.data);
      changeEditMode(true);
      changeIsOpenRecognitionModal(true);
    });
  }
  function recognitionHandleClick(id: string, name: string) {
    changeRecognitionRuleId(id);
    changeRecognitionRuleName(name);
    changeOpenRecognizeModal(true);
  }
  function onFormSubmitHandler(data) {
    editMode && recognitionRuleId
      ? editRecognitionRule({ body: data, id: recognitionRuleId })
      : addRecognition(data);
    changeIsOpenRecognitionModal(false);
  }
  function deleteRecognitionRuleHandler() {
    recognitionRuleId && deleteRecognitionRule(recognitionRuleId);
  }
  function onSuccessRecognizeRule(range: FilterRangeItem) {
    if (!recognitionRuleId) return;
    submitRecordForRecognition({ range: range, id: recognitionRuleId });
  }
  function getOptionFromAsrModels() {
    return asr_model?.map((item) => ({ title: item.name, value: item.model_id }));
  }
  function handleChangeDefaultModel() {
    defaultRecognitionModel &&
      changeRecognitionDefaultModel(defaultRecognitionModel).then(() => {
        refetch();
        changeEditDefaultModel(false);
      });
  }
  const renderModal = () => {
    if (!isOpenRecognitionModal) return;
    return (
      <RecognitionRulesModalWithForm
        asr_model={asr_model}
        editMode={editMode}
        changeEditMode={changeEditMode}
        isOpenRecognitionModal={isOpenRecognitionModal}
        changeIsOpenRecognitionModal={changeIsOpenRecognitionModal}
        onFormSubmitHandler={onFormSubmitHandler}
        recognitionRuleData={recognitionRuleSettings}
      />
    );
  };
  const renderRecognizeByRangeModal = () => {
    if (!isOpenRecognizeModal) return;
    return (
      <RecognizeRuleByRangeModal
        changeOpenRecognizeModal={changeOpenRecognizeModal}
        isOpenRecognizeModal={isOpenRecognizeModal}
        recognitionRuleName={recognitionRuleName || 'N/A'}
        onSuccess={onSuccessRecognizeRule}
      />
    );
  };
  if (isLoading) return <SkeletonPageLoading />;
  return (
    <div className="flex flex-col">
      {renderModal()}
      {renderRecognizeByRangeModal()}
      <EasyModal
        withoutFooter
        onClose={changeOpenDeleteModal.bind(null, false)}
        label={t('warning')}
        show={isOpenDeleteModal}
        variant="removal"
        onRemove={() => deleteRecognitionRuleHandler()}
      >
        <div className="flex items-center justify-center">{t('alias.delete_alias_text')}</div>
      </EasyModal>
      <div className="inline-flex items-center w-full justify-between py-[30px]">
        <h1 className="text-[18px] font-[700] ml-[20px]">{t('recog_rules.recognition_rules')}</h1>
        <div className="flex items-center gap-[10px]">
          <div className="text-[14px] font-[500] text-1color flex items-center gap-[5px]">
            <span>{t('recog_rules.recognition_rules_queue')}:</span>
            <div className="font-[700]">{recognitionRuleQueue?.recognize_queue_count}</div>
          </div>
          <Button
            data-id="new-recognition-rule"
            onClick={() => {
              changeIsOpenRecognitionModal(true);
            }}
            icon="CirclePlusIcon"
            label={t('recog_rules.add_recognition_rule')}
          />
        </div>
      </div>
      <RecognitionRulesTable
        recognitionHandleClick={recognitionHandleClick}
        editHandleClick={editHandleClick}
        deleteHandleClick={deleteHandleClick}
        asr_model={asr_model}
        aliases={aliases?.aliases}
        columns={columns}
        data={data || []}
      />
      <div className="py-[32px] flex flex-col gap-[17px]">
        <div className="flex items-center">
          <HelpCircleIcon className="text-3color mr-[10px]" size={19} />
          <div className="text-[14px] text-1color leading-[110%] font-[500] tracking-tight">
            {t('recog_rules.recognition_model_default')}
          </div>
          <div>
            {editDefaultModel ? (
              <div className="flex items-center gap-[6px] ml-[6px]">
                <Select
                  defaultValue={defaultRecognitionModel || default_model?.model_id}
                  size={200}
                  dropWidth={194}
                  options={getOptionFromAsrModels() || []}
                  onChange={(value) => changeDefaultRecognitionModel(value as string)}
                />
                <div
                  onClick={changeEditDefaultModel.bind(null, false)}
                  className="p-[8.5px_11.5px] bg-white rounded-[10px] cursor-pointer"
                >
                  <XIcon className="text-basic_red" size={11} />
                </div>
                <div
                  onClick={handleChangeDefaultModel}
                  className="p-[8.5px_11.5px] bg-white rounded-[10px] cursor-pointer"
                >
                  <CheckIcon className="text-basic_green" size={11} />
                </div>
              </div>
            ) : (
              <div className="flex items-center gap-[6px]">
                <b>&nbsp;- {default_model?.name || 'N/A'}</b>
                <div className="cursor-pointer" onClick={changeEditDefaultModel.bind(null, true)}>
                  <EditIcon size={18} className="text-action" />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center gap-[10px]">
          <HelpCircleIcon className="text-3color" size={19} />
          <div className="text-[14px] text-1color leading-[110%] font-[500] tracking-tight">
            {t('recog_rules.recognition_rules_comment')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecognitionRulesSettings;

import React from 'react';
import { NavigationTabs } from '@ui';
import { NavigationTabsItem } from '@app/components/ui/navigation-tabs/navigation-tabs';
import useTranslation from '@app/hooks/use-translation';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { useOutletContext, useParams } from 'react-router-dom';
import { useGetLiveReportSettingsQuery } from '@app/store/api/live-reports.api';
import { ReportOutletContext } from '@app/pages/reports/report.outlet';

const ReportNavigatorBlock = () => {
  const { changeIsOpenChartModal } = useOutletContext<ReportOutletContext>();
  const { t } = useTranslation('pages.reports.navigation');
  const { id } = useParams();
  const { currentData: liveReport } = useGetLiveReportSettingsQuery(
    { id: id || '' },
    { skip: !id },
  );
  const navigationIndexes = {
    chart: 'chart',
    settings: 'settings',
    records: 'records',
  };
  const navigatorData: Array<NavigationTabsItem> = [
    {
      label: t('charts'),
      icon: 'ChartIcon',
      index: navigationIndexes.chart,
      link: `/${navigationRoutes.reports}/${liveReport?.live_report_id}`,
      dataId: 'reports-section-charts',
    },
    {
      icon: 'SettingIcon',
      label: t('settings'),
      index: navigationIndexes.settings,
      link: `/${navigationRoutes.reports}/${liveReport?.live_report_id}/${navigationRoutes.settings}`,
      dataId: 'reports-section-settings',
    },
    {
      icon: 'TableIcon',
      label: t('records'),
      index: navigationIndexes.records,
      link: `/${navigationRoutes.reports}/${liveReport?.live_report_id}/${navigationRoutes.reportRecords}`,
      dataId: 'reports-section-records',
    },
  ];

  function handlerOnChange(clickedTab: NavigationTabsItem) {
    clickedTab.index === navigationIndexes.settings && changeIsOpenChartModal(true);
  }

  return <NavigationTabs onChange={handlerOnChange} items={navigatorData} />;
};

export default ReportNavigatorBlock;

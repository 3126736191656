import React, { useState } from 'react';
import { Box, Button, PageHeader } from '@ui';
import ChartNative from '@app/components/chart/chart-native';
import { ApexOptions } from 'apexcharts';
import { ChartTypes } from '@app/interfaces/report.type';
// a5e0f9d6-d478-418f-adc7-2eb997a98c7f

const currentChartBuild = {
  options: {
    height: 350,
    noData: {
      text: 'Нет данных',
    },
    tooltip: {
      enabled: true,
      y: {},
      style: {
        dropShadow: {
          enabled: false,
        },
        opacity: 0.4,
        background: {
          enabled: false,
          dropShadow: {
            enabled: false,
          },
        },
      },
    },
    labels: ['Оценка обслуживания'],
    grid: {
      show: false,
      strokeDashArray: 3.3,
      position: 'back',
      borderColor: '#E5E8EB',
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    responsive: [
      {
        breakpoint: 400,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
    stroke: {
      curve: 'smooth',
    },
    yaxis: {
      forceNiceScale: true,
      min: 0,
    },
    chart: {
      redrawOnParentResize: false,
      id: 'chart_21d64051-d27c-4969-8d59-813d6a71e3da',
      type: 'line',
      width: '95%',
      height: 350,
      offsetX: 10,
      animations: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetX: -185,
        tools: {
          download:
            '<div title="Скачать" class="mt-[2px] ml-[3px]"><div class="text-3color active:scale-[90%] z-[0] hover:opacity-80"><svg xmlns="http://www.w3.org/2000/svg" width="22" fill="none" viewBox="0 0 19 17"><path fill="currentColor" d="M11.428 10.956v1.524h3.377c2.167 0 3.52-1.278 3.52-3.055 0-1.49-.834-2.687-2.201-3.227 0-3.117-2.249-5.366-5.154-5.366-1.805 0-3.193.91-4.074 2.174-1.675-.397-3.59.854-3.671 2.789-1.58.294-2.55 1.613-2.55 3.288 0 1.88 1.442 3.397 3.74 3.397h3.082v-1.524H4.414c-1.408 0-2.194-.861-2.194-1.914 0-1.271.854-2.12 2.221-2.12.096 0 .144-.054.137-.156C4.51 4.77 5.932 4.072 7.442 4.51c.09.02.15.007.192-.075.65-1.197 1.66-2.079 3.322-2.079 2.099 0 3.603 1.655 3.705 3.623.02.39-.007.807-.04 1.149-.015.096.027.15.122.164 1.258.219 2.03.916 2.03 2.03 0 .964-.656 1.634-2.016 1.634h-3.33zm-1.962 5.776c.184 0 .355-.061.54-.239l2.283-2.146a.566.566 0 00.191-.438c0-.349-.287-.595-.615-.595a.648.648 0 00-.465.206l-.902.909-.417.512.082-1.011V8.078a.698.698 0 00-.697-.683.699.699 0 00-.704.683v5.852l.089 1.011-.424-.512-.903-.91a.648.648 0 00-.464-.204c-.335 0-.616.245-.616.594 0 .178.062.315.192.438l2.283 2.146c.191.178.355.24.547.24z"></path></svg></div></div>',
        },
      },
      defaultLocale: 'ru',
      locales: [
        {
          name: 'ru',
          options: {
            toolbar: {
              download: 'Скачать',
              selection: 'Выбрать',
              selectionZoom: 'Увеличить выбранное',
              zoomIn: 'Увеличить',
              zoomOut: 'Уменьшить',
              pan: 'Переместить',
              reset: 'Сбросить настройки',
            },
          },
        },
      ],
    },
    dropShadow: {
      enabled: false,
    },
    dataLabels: {
      enabled: true,
      offsetY: 0,
      offsetX: 0,
      style: {
        fontSize: '12px',
        colors: ['#c592f7'],
        zIndex: 0,
      },
      dropShadow: {
        enabled: false,
      },
    },
    colors: ['#3453ef'],
    plotOptions: {
      bar: {
        borderRadiusApplication: 'end',
        borderRadius: 5,
        columnWidth: '100%',
        dataLabels: {
          position: 'top',
        },
        horizontal: false,
      },
    },
    xaxis: {
      categories: [
        '27.11.2022',
        '29.11.2022',
        '30.11.2022',
        '01.12.2022',
        '02.12.2022',
        '03.12.2022',
        '04.12.2022',
        '05.12.2022',
        '06.12.2022',
        '07.12.2022',
        '08.12.2022',
        '10.12.2022',
        '11.12.2022',
        '14.12.2022',
        '16.12.2022',
        '17.12.2022',
        '19.12.2022',
        '21.12.2022',
        '22.12.2022',
        '23.12.2022',
        '25.12.2022',
      ],
      tickPlacement: 'on',
      labels: {
        rotate: -45,
        trim: true,
        hideOverlappingLabels: false,
      },
      convertedCatToNumeric: true,
    },
    legend: {
      markers: {
        fillColors: [['#3453ef']],
      },
      position: 'bottom',
      horizontalAlign: 'center',
      show: false,
      showForSingleSeries: false,
      showForNullSeries: false,
      showForZeroSeries: false,
    },
    annotations: {
      yaxis: [],
      xaxis: [],
      points: [],
    },
  },
  series: [
    {
      name: 'Оценка обслуживания',
      data: [3, 2, 4, 4, 3, 3, 4, 3, 4, 3, 2, 3, 3, 4, 2, 3, 3, 4, 2, 4, 4],
    },
  ],
};

const EmailPage = () => {
  const [hideComponentsToggle, toggleHideComponents] = useState<boolean>(true);
  const mockChart = (
    <Box className="p-[10px]">
      <ChartNative
        options={currentChartBuild?.options as unknown as ApexOptions}
        series={currentChartBuild?.series}
        height={350}
        isShowToolbar={true}
        width="100%"
        handlers={{
          updateOptions: {
            noData: { text: 'Не хватает данных. Проверьте настройки' },
            title: {
              text: '250',
              offsetY: -19,
              margin: 30,
              style: {
                fontSize: `${10}px`,
              },
            },
            chart: {
              toolbar: {
                show: true,
                offsetX: -35,
                tools: {
                  pan: true,
                  zoom: true,
                  selection: true,
                  zoomin: true,
                  zoomout: true,
                  reset: true,
                },
              },
            },
            legend: {
              position: 'bottom',
            },
          },
        }}
        type={(currentChartBuild?.options?.chart?.type as ChartTypes) || 'bar'}
      />
    </Box>
  );
  function printReportHandler() {
    // const printBody = document.getElementById('printBody')?.innerHTML;
    // const windowPrint = window.open('', 'PRINT', 'height=400px, width=400px');
    // windowPrint?.document.write(`<html lang="RU"><head><title>${'test'}</title></head><body>`);
    // if (printBody) windowPrint?.document.write(printBody);
    // windowPrint?.document.write('</body></html>');
    // windowPrint?.close();
    // windowPrint?.print();
    toggleHideComponents(false);
    setTimeout(() => {
      window.print();
      toggleHideComponents(true);
    });
  }
  return (
    <div>
      <PageHeader label="ПРИМЕР ОТЧЕТА">
        {hideComponentsToggle && <Button onClick={printReportHandler} label="Распечатать отчет" />}
      </PageHeader>
      <div id="printBody">
        <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-[15px] ">
          {Array(6)
            .fill(mockChart)
            .map((chart, index) => (
              <div key={index} className="w-[100%]">
                {chart}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default EmailPage;

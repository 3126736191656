import { Box, Button, Input, Select } from '@app/components/ui';
import React, { FC } from 'react';

import { useForm } from 'react-hook-form';

import { AllChanelsArrType } from '@app/interfaces/pages-types/anatylics-metric.type';

import { NavLink } from 'react-router-dom';
import { navigationRoutes } from '@app/utils/navigation-routes';

import { CloudIcon, MenuSmallIcon } from '@app/components/ui/icons/icons-list';
import {
  useGetWordsNGramSettingsQuery,
  useStartCalculateWordNGramCloudMutation,
  useUpdateWordsNGramStaticCloudSettingsMutation,
} from '@app/store/api/get-words-n-gramm.api';
import { WordsNGramStaticRequestType } from '@app/interfaces/words-n-gramm.type';
import useTranslation from '@app/hooks/use-translation';

type WordsCloudFilterPropsType = {
  channels: AllChanelsArrType[] | undefined;
  onFormSubmit?: (data: Omit<WordsNGramStaticRequestType, 'live_report_id'>) => void;
  disableSaveButton?: boolean;
  projectId: string | undefined;
};
export const WordsCloudFilter: FC<WordsCloudFilterPropsType> = (props) => {
  const { t } = useTranslation('pages.wordsNGramm');
  const { channels, projectId, onFormSubmit, disableSaveButton } = props;
  //api
  const { data: cloudSettings } = useGetWordsNGramSettingsQuery({
    live_report_id: projectId || '',
  });
  const [updateSettings] = useUpdateWordsNGramStaticCloudSettingsMutation();
  const [startCalculate] = useStartCalculateWordNGramCloudMutation();
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<{ filter: string; limit: number; channel: string[]; num_first_phrases: number }>({
    defaultValues: {
      limit: 100,
      // frequency: Number(getOccurrenceOptions()[0].value),
      channel: cloudSettings?.channel?.map(String) || ['all'],
      num_first_phrases: cloudSettings?.num_first_phrases,
    },
  });
  function onSubmit(data) {
    const filterValue: string = data.filter;
    // const decoder = new TextDecoder('utf-8');
    const decodedMessage = encodeURI(filterValue).split(' ');

    onFormSubmit?.({ filter: decodedMessage as unknown as string, limit: data.limit });
  }
  function getChannelsOptions() {
    return (channels && channels?.map((item) => ({ title: item.name, value: item.channel }))) || [];
  }

  return (
    <div>
      <Box className="px-[30px] py-[20px] w-[300px] h-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-start w-full gap-[25px]">
            <div className="flex flex-col items-center gap-[25px] w-full">
              <div className="flex items-center">
                <div className="flex relative items-center gap-[10px]">
                  <span className="flex items-center text-2color text-[15px] leading-[18px] font-[400]">
                    {t('cloud_deep')}
                  </span>
                  <div>
                    <Input
                      {...register('limit', {
                        max: { message: 'max is 100', value: 100 },
                      })}
                      name={'limit'}
                    />
                    <span className="mt-[2px] text-basic_red text-[12px] absolute right-0 top-[28px]">
                      {errors.limit?.message}
                    </span>
                  </div>
                </div>
                <NavLink
                  end
                  to={`/${navigationRoutes.wordsNGramm}/table`}
                  className={(active) =>
                    `${
                      active.isActive && 'bg-action/[0.1]'
                    } mx-[13px] hover:bg-action/[0.1] p-[3.5px]`
                  }
                >
                  {({ isActive }) => (
                    <MenuSmallIcon
                      className={`${isActive && '!text-action'} text-3color hover:text-action`}
                      size={21}
                    />
                  )}
                </NavLink>
                <NavLink
                  end
                  to={`/${navigationRoutes.wordsNGramm}`}
                  className={(active) =>
                    `${active.isActive && 'bg-action/[0.1]'} hover:bg-action/[0.1] p-[3.5px]`
                  }
                >
                  {({ isActive }) => (
                    <CloudIcon
                      className={`${isActive && '!text-action'} text-3color hover:text-action`}
                      size={21}
                    />
                  )}
                </NavLink>
              </div>
              <div className="w-[240px]">
                <hr className="py-[10px]" />
                <Select
                  placeholder={t('channel_select_placeholder')}
                  dropWidth={292}
                  options={[
                    { title: t('all_channels_option'), value: 'all' },
                    ...getChannelsOptions(),
                  ]}
                  onChangeMultiple={(value) =>
                    value.includes('all')
                      ? watch('channel')?.includes('all')
                        ? setValue(
                            'channel',
                            (value as string[]).filter((item) => item !== 'all'),
                          )
                        : setValue('channel', ['all'])
                      : setValue('channel', value as string[])
                  }
                  isMulti
                  defaultValue={watch().channel}
                />
              </div>
              <div className="w-[240px]">
                <Input
                  {...register('num_first_phrases', {
                    validate: (value) => value <= 1000 || '',
                  })}
                  label={t('first_phrases_count')}
                  error={watch('num_first_phrases') > 1000 ? 'max is 1000' : ''}
                />
              </div>
              <div className="flex flex-col gap-[20px]">
                <Button
                  onClick={() => {
                    projectId &&
                      updateSettings({
                        params: { live_report_id: projectId },
                        body: {
                          settings: {
                            channel: watch('channel')?.length
                              ? watch('channel').includes('all')
                                ? getChannelsOptions().map((item) => Number(item.value))
                                : watch('channel').map(Number)
                              : undefined,
                            num_first_phrases: watch('num_first_phrases')
                              ? watch('num_first_phrases')
                              : 0,
                          },
                        },
                      });
                  }}
                  fill="outlined"
                  label={t('change_cloud_settings')}
                />
                <hr />
                <Button
                  onClick={() => projectId && startCalculate({ live_report_id: projectId })}
                  variant="danger"
                  fill="outlined"
                  label={t('start_cloud_calculate')}
                />
                <hr />
              </div>
              <div className={'w-full'}>
                <Input
                  label={'filter'}
                  {...register('filter', {
                    pattern: {
                      value: /^[A-Za-zА-Яа-яёЁ]+$|^[A-Za-zА-Яа-яёЁ]+\s[A-Za-zА-Яа-яёЁ]+$/,
                      message: t('filter_error_input_message'),
                    },
                  })}
                  error={errors.filter?.message}
                />
              </div>
              <div className="">
                <Button
                  disabled={!disableSaveButton}
                  type="submit"
                  className="!font-[400] !leading-[18px] !px-[13.5px] w-[240px]"
                  label={t('apply_button')}
                  isFull
                />
              </div>
            </div>
          </div>
        </form>
      </Box>
    </div>
  );
};

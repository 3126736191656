import React, { FC, useEffect, useRef, useState } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';

export type ChartConfig = { dataPointIndex: string | number };
export type ChartContext = {
  height?: number;
  type?: ApexChart['type'];
  shadow?: {
    enabled?: boolean;
    color?: string;
    top?: number;
    left?: number;
    blur?: number;
    opacity?: number;
  };
  zoom?: {
    enabled?: boolean;
  };
};
export type ChartNativeProps = {
  options: ApexOptions;
  type: ApexChart['type'];
  isShowToolbar: boolean;
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
  width?: string | number;
  height?: string | number;
  /* handlers data */
  handlers?: {
    updateOptions?: ApexOptions;
    updateSeries?: Array<ApexAxisChartSeries | ApexNonAxisChartSeries>;
    appendSeries?: Array<ApexAxisChartSeries | ApexNonAxisChartSeries>;
    toggleSeries?: Array<string>;
    showSeries?: Array<string>;
    hideSeries?: Array<string>;
    appendData?: Array<{ data: Array<number> }>;
  };
  /* events */
  events?: {
    animationEnd?: (chartContext: ApexOptions, options: ApexOptions) => void;
    beforeMount?: (chartContext: ChartContext, config: ChartConfig) => void;
    mounted?: (chartContext: ChartContext, config: ChartConfig) => void;
    updated?: (chartContext: ChartContext, config: ChartConfig) => void;
    mouseMove?: (event: MouseEvent, chartContext: ChartContext, config: ChartConfig) => void;
    mouseLeave?: (event: MouseEvent, chartContext: ChartContext, config: ChartConfig) => void;
    click?: (event: MouseEvent, chartContext: ChartContext, config: ChartConfig) => void;
    legendClick?: (chartContext: ChartContext, seriesIndex: number, config: ChartConfig) => void;
    markerClick?: (
      event: MouseEvent,
      chartContext: ChartContext,
      {
        seriesIndex,
        dataPointIndex,
        config,
      }: { seriesIndex: number; dataPointIndex: number; config: ChartConfig },
    ) => void;
    xAxisLabelClick?: (event: MouseEvent, chartContext: ChartContext, config: ChartConfig) => void;
    selection?: (
      chartContext: ChartContext,
      { xaxis, yaxis }: { xaxis: number | string; yaxis: number | string },
    ) => void;
    dataPointSelection?: (
      event: MouseEvent,
      chartContext: ChartContext,
      config: ChartConfig,
    ) => void;
    dataPointMouseEnter: (
      event: MouseEvent,
      chartContext: ChartContext,
      config: ChartConfig,
    ) => void;
    dataPointMouseLeave?: (
      event: MouseEvent,
      chartContext: ChartContext,
      config: ChartConfig,
    ) => void;
    beforeZoom?: (
      chartContext: ChartContext,
      { xaxis }: { xaxis: number | string },
    ) => {
      xaxis: {
        min: string;
        max: string;
      };
    };
    beforeResetZoom?: (
      chartContext: ChartContext,
      opts: never,
    ) => {
      xaxis: {
        min: string;
        max: string;
      };
    };
    zoomed?: (chartContext: ChartContext, { xaxis }: { xaxis: number | string }) => void;
    scrolled?: (chartContext: ChartContext, { xaxis }: { xaxis: number | string }) => void;
  };
};
// const DEFAULT_CHART_SIZE = '100%';
const DEFAULT_CHART_TYPE = 'bar';

const ChartNative: FC<ChartNativeProps> = (props) => {
  const {
    options,
    events = {},
    handlers,
    type = DEFAULT_CHART_TYPE,
    series,
    isShowToolbar,
  } = props;
  const [chartData, setChartData] = useState<{
    options?: ApexOptions;
    series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
  } | null>(null);
  const [updateOptionState, setUpdateOptionsState] = useState<ApexOptions>();
  const chartRef = useRef<ApexCharts | null>(null);
  const chartElement = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!chartRef?.current || chartData?.options !== options || chartData?.series !== series) {
      chartRef?.current && chartRef.current.destroy();
      try {
        setChartData({ options, series });
        const currentOptions = {
          ...options,
          series,
          chart: {
            ...options.chart,
            type,
            events,
            toolbar: {
              ...options.chart?.toolbar,
            },
          },
        };
        chartRef.current = new ApexCharts(chartElement.current, currentOptions);
        if (chartRef?.current) {
          chartRef.current.render();
        }
      } catch (error) {
        console.error('Chart build error', error);
      }
    }
  }, [chartData?.options, chartData?.series, events, isShowToolbar, options, series, type]);

  useEffect(() => {
    if (
      handlers?.updateOptions &&
      updateOptionState !== handlers?.updateOptions &&
      chartRef.current
    ) {
      setTimeout(() => {
        chartRef.current?.updateOptions(handlers?.updateOptions);
        setUpdateOptionsState(handlers?.updateOptions);
      }, 200);
    }
  }, [handlers?.updateOptions, updateOptionState]);

  useEffect(() => {
    if (chartRef?.current && handlers?.updateOptions) {
      setTimeout(() => {
        chartRef.current?.updateOptions(handlers.updateOptions);
      }, 200);
    }
  }, [handlers?.updateOptions]);

  useEffect(() => {
    if (chartRef.current && handlers) {
      const stopHandlers = ['updateOptions'];
      Object.entries(handlers).forEach(([handler, body]) => {
        if (handler && !stopHandlers.includes(handler)) {
          try {
            for (const series of body as ApexAxisChartSeries | ApexNonAxisChartSeries) {
              setTimeout(() => chartRef.current?.[handler](series, true), 100);
            }
          } catch (error) {
            console.error(handler, error);
          }
        }
      });
    }
  }, [handlers]);
  return <div ref={chartElement} />;
};

export default ChartNative;

export const metricIconsData = {
  meta: 'meta',
  summ_speech_duration: 'summspeechduration',
  summ_silent_duration: 'summsilentduration',
  interruptions: 'interruptions',
  max_pause_duration: 'maxpauseduration',
  avg_pause_duration: 'avgpauseduration',
  find_words: 'findwords',
  max_monolog_duration: 'maxmonologduration',
  speech_rate_duration: 'speechrateduration',
  calculated: 'calculated',
  pause_exceed: 'pauseexceed',
  max_conflict: 'maxconflict',
  avg_conflict: 'avgconflict',
};

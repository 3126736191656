import React from 'react';

import { FC } from 'react';
import classNames from 'classnames';
import useTranslation from '@app/hooks/use-translation';

import { IconNameType } from '../icons/icon-name.type';
import { LabelColor } from '../label/label.type';
import Icon from '../icons';
// import { Label } from '@ui';

export type StatusItem = {
  title: string;
  variant?: LabelColor;
  icon?: IconNameType;
};

export type StatusVariant =
  | 'PAUSE'
  | 'FILLING'
  | 'ACTIVE'
  | 'STOPPING'
  | 'STARTING'
  | 'CREATED'
  | 'REMOVING'
  | 'ARCHIVE'
  | 'ACTUALIZATION';

type StatusLabelProps = {
  status: StatusVariant;
  onChangeStatus?: (status: string) => void;
  isDropLeft?: boolean;
  isDropRight?: boolean;
  statusProperties?: Partial<Record<StatusVariant, Array<StatusVariant>>>;
  dataId?: string;
};

export const AnalyticsStatus: FC<StatusLabelProps> = (props) => {
  const { status, dataId } = props;
  const { t } = useTranslation('components.reportsStatusLabel');

  // const reportsList: Record<StatusVariant, StatusItem> = {
  //   LIFE: { title: 'LIVE', variant: 'success', icon: 'LiveIcon' },
  //   CREATED: { title: t('created'), variant: 'success_light' },
  //   UPDATING: { title: t('updating'), variant: 'primary' },
  //   DONE: { title: t('done'), variant: 'primary' },
  //   ARCHIVE: { title: t('archive'), variant: 'danger' },
  //   PAUSE: { title: t('pause'), variant: 'danger' },
  //   DELETING: { title: t('deleting'), variant: 'danger' },
  //   REMOVING: { title: t('removing'), variant: 'danger' },
  //   ERROR: { title: t('error'), variant: 'danger' },
  //   FILLING: { title: t('filling'), variant: 'primary' },
  //   ACTIVE: { title: t('active'), variant: 'success' },
  //   STOPPING: { title: t('stopping'), variant: 'danger' },
  //   STARTING: { title: t('starting'), variant: 'success_light' },
  //   ACTUALIZATION: { title: t('actualization'), variant: 'success_light' },
  // };
  const statusList: Record<StatusVariant, StatusItem> = {
    PAUSE: { title: t('pause'), icon: 'PauseIcon' },
    STOPPING: { title: t('stopping') },
    STARTING: { title: t('starting') },
    CREATED: { title: t('created') },
    ACTIVE: { title: t('active') },
    FILLING: { title: t('filling') },
    REMOVING: { title: t('removing') },
    ARCHIVE: { title: t('archive'), icon: 'ArchiveBox' },
    ACTUALIZATION: { title: t('actualization') },
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  // const filteredDropMenu =
  //   statusProperties?.[status]?.map((statusFromPropertyList) => ({
  //     ...reportsList[statusFromPropertyList],
  //     value: statusFromPropertyList,
  //   })) || [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const statusVariant = (variant?: string) => {
    return classNames(
      'font-[500]',
      'text-[12px]',
      'text-white',
      'py-[1px]',
      'px-[5px]',
      'flex',
      'items-center',
      'gap-[5px]',
      'rounded-[2px]',
      variant === 'ACTIVE' && 'bg-[#39A76D]',
      variant === 'STARTING' && 'bg-[#39A76D]',
      variant === 'PAUSE' && 'bg-[#66CBEA]',
      variant === 'STOPPING' && 'bg-[#E92E5D]',
      variant === 'REMOVING' && 'bg-[#E92E5D]',
      variant === 'CREATED' && 'bg-[#13BE62]',
      variant === 'FILLING' && 'bg-[#FFA12E]',
      variant === 'ARCHIVE' && 'bg-[#A6ABBA]',
      variant === 'ACTUALIZATION' && 'bg-[#2987CC]',
    );
  };

  return (
    <div data-id={dataId} className={statusVariant(status)}>
      {statusList[status]?.icon && (
        <Icon size={12} className="text-white" name={statusList[status]?.icon as IconNameType} />
      )}
      {statusList[status]?.title.toLowerCase()}
    </div>
  );
};

export default AnalyticsStatus;

import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ReportChart, ReportChartResponse, ReportTimeRange } from '@app/interfaces/report.type';
import ChartComponent from '@app/components/chart/chart-component';
import GridLayout, { Layout } from 'react-grid-layout';
import useResizeObserver from '@app/hooks/use-resize-observer';
import ChartDatePickerModalBlock from '@app/pages/reports/blocks/chart-date-picker-modal.block';
import { ChartEditModal } from '@app/pages/dashboards/blocks/chart-edit-modal';
import ModalSettingsChartBlock from '@app/pages/reports/blocks/chart-modal/modal-settings-chart.block';
import { EasyModal } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { useLazyGetGraphDataQuery } from '@app/store/api/graph-data.api';
import useChartDateIntervals from '@app/hooks/use-chart-date-intervals';
import {
  useDeleteGraphOnDashboardMutation,
  useEditDashboardGraphLayoutMutation,
} from '@app/store/api/dashboard-charts.api';
import { FilterItem } from '@app/interfaces/filter';
import { ChartsLayoutType, EditGraphLayoutType } from '@app/interfaces/dashboards.type';
import {
  useDeleteNewLiveReportGraphMutation,
  useEditLiveReportGraphLayoutMutation,
} from '@app/store/api/live-reports-charts.api';
type GridLayoutChartsContainerPropsType = {
  dashboard?: boolean;
  commonRange?: ReportTimeRange | null;
  layoutSettings: ChartsLayoutType[];
  changeLayoutSettings: React.Dispatch<React.SetStateAction<ChartsLayoutType[]>>;
  onEditIntervalWithoutSave?: (data: {
    data: ReportChart;
    id: string;
    layout: ChartsLayoutType | undefined;
  }) => void;
  //todo
  charts?: any[];
  viewOnly?: boolean;
  filterValue?: FilterItem[];
  currentDashboardOrReportId?: string;
  isDraggable?: boolean;
  flagForSendChartLayoutRequestChange?: boolean;
};
const LAYOUT_ROW_HEIGHT = 70;

const GridLayoutChartsContainer: FC<GridLayoutChartsContainerPropsType> = (props) => {
  const { t } = useTranslation('pages.chartPage');
  const {
    dashboard = false,
    viewOnly = false,
    onEditIntervalWithoutSave,
    charts: chartsData,
    commonRange,
    filterValue,
    currentDashboardOrReportId,
    isDraggable = true,
    layoutSettings,
    changeLayoutSettings,
    flagForSendChartLayoutRequestChange,
  } = props;
  //api
  const [getGraphData] = useLazyGetGraphDataQuery();
  const [deleteChartFromDashboard] = useDeleteGraphOnDashboardMutation();
  const [editDashboardLayoutSettings] = useEditDashboardGraphLayoutMutation();
  const [deleteLiveReportChart] = useDeleteNewLiveReportGraphMutation();
  const [editLiveReportChartLayout] = useEditLiveReportGraphLayoutMutation();
  //api
  // state
  const charts = useMemo(() => chartsData, [chartsData]);
  const { dateToString, intervalTimeBuilder } = useChartDateIntervals();
  const [openCustomDateModal, setOpenedCustomDateModal] = useState<boolean>(false);
  const [openModal, setMobile] = useState<boolean>(false);
  const [isOpenRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const [data, setData] = useState<ReportChartResponse['data']>();
  const { reversedDateIntervals } = useChartDateIntervals();
  // state
  // functions
  function changeCustomDateInterval({ start, end }: { start: string | null; end: string | null }) {
    const range = intervalTimeBuilder(start, end) as unknown as ReportTimeRange;
    if (!data) return;
    if (dashboard) {
      getGraphData({
        id: data.graph_id,
        settings: {
          width: data.width,
          range,
        },
      }).then((Cdata) => {
        const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
        const layout = layoutSettings.find((layout) => layout.i === data.dashboard_graph_id);
        onEditIntervalWithoutSave &&
          data.dashboard_graph_id &&
          onEditIntervalWithoutSave({
            data: chartData,
            id: data.dashboard_graph_id,
            layout: layout,
          });
      });
    } else {
      getGraphData({
        id: data.graph_id,
        settings: {
          width: data.width,
          range,
          filter: filterValue,
        },
      }).then((Cdata) => {
        const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
        const layout = layoutSettings.find((layout) => layout.i === chartData.graph_id);
        onEditIntervalWithoutSave &&
          data.graph_id &&
          onEditIntervalWithoutSave({ data: chartData, id: data.graph_id, layout: layout });
      });
    }
    setOpenedCustomDateModal(false);
  }
  function handlerRemoveModal() {
    if (!data) return;
    if (data.dashboard_graph_id) {
      deleteChartFromDashboard({
        dashboard_graph_id: data.dashboard_graph_id,
        dashboard_id: currentDashboardOrReportId,
      });
    } else
      deleteLiveReportChart({ id: data?.graph_id, live_report_id: currentDashboardOrReportId });
  }
  // functions
  // grid layout
  const [gridWidth, changeGridWidth] = useState<number>(1055);
  const [layoutWidthRef, changeLayoutWidthRef] = useState<HTMLDivElement>();
  const [layoutForServerRequest, layoutChangeForServerRequest] = useState<EditGraphLayoutType[]>();
  useResizeObserver(layoutWidthRef, changeGridWidth);
  useEffect(() => {
    if (charts?.length) {
      changeLayoutSettings(charts?.map((chart) => chart.layout));
    }
  }, [changeLayoutSettings, charts]);
  const onLayoutChange = useCallback(
    (data: Layout[]) => {
      changeLayoutSettings(data);
      layoutChangeForServerRequest(
        dashboard
          ? data.map((item) => ({
              dashboard_graph_id: item.i,
              layout: { x: item.x, y: item.y, w: item.w, h: item.h },
            }))
          : data.map((item) => ({
              graph_id: item.i,
              layout: { x: item.x, y: item.y, w: item.w, h: item.h },
            })),
      );
    },
    [changeLayoutSettings, dashboard],
  );
  useEffect(() => {
    if (
      layoutSettings.length &&
      flagForSendChartLayoutRequestChange &&
      layoutForServerRequest &&
      !isDraggable &&
      currentDashboardOrReportId
    ) {
      dashboard
        ? editDashboardLayoutSettings({
            body: layoutForServerRequest,
            params: { dashboard_id: currentDashboardOrReportId },
          })
        : editLiveReportChartLayout({
            body: layoutForServerRequest,
            liveReportId: currentDashboardOrReportId,
          });
    }
  }, [
    currentDashboardOrReportId,
    dashboard,
    editDashboardLayoutSettings,
    editLiveReportChartLayout,
    flagForSendChartLayoutRequestChange,
    isDraggable,
    layoutForServerRequest,
    layoutSettings.length,
  ]);
  // grid layout
  function layoutWidth(id: string) {
    return layoutSettings.find((item) => item.i === id)?.w || 0;
  }
  function layoutHeight(id: string) {
    return layoutSettings.find((item) => item.i === id)?.h || 0;
  }
  // helpers
  const getChartRange = (range: ReportTimeRange | undefined) => {
    if (!range) return;
    return reversedDateIntervals[
      range?.type === 'l' ? `l${range?.parameters?.[0].num}` : range.type
    ];
  };
  return (
    <>
      <div ref={(ref) => ref && changeLayoutWidthRef(ref)} className="w-full h-full">
        <GridLayout
          className="layout relative w-fit h-fit"
          draggableCancel={'.noDrag'}
          layout={layoutSettings}
          cols={24}
          rowHeight={LAYOUT_ROW_HEIGHT}
          width={gridWidth || 1000}
          onLayoutChange={onLayoutChange}
          isDraggable={isDraggable}
          isResizable={isDraggable}
          compactType={null}
          preventCollision={true}
          containerPadding={[0, 0]}
          // useCSSTransforms={false}
        >
          {charts?.map((graph, index) => (
            <div key={graph.layout.i || `key_${index + 1}`} className={'w-fit h-fit'}>
              <ChartComponent
                dashboard_graph_id={graph.dashboard_graph_id}
                viewOnly={viewOnly}
                layoutSettings={layoutSettings}
                onEditIntervalWithoutSave={onEditIntervalWithoutSave}
                commonRange={commonRange}
                dashboard={dashboard}
                data={graph}
                setData={setData}
                setOpenedCustomDateModal={setOpenedCustomDateModal}
                setMobile={setMobile}
                setOpenRemoveModal={setOpenRemoveModal}
                filterValue={filterValue}
                chartHeight={
                  LAYOUT_ROW_HEIGHT *
                  layoutHeight(dashboard ? graph.dashboard_graph_id : graph.graph_id)
                }
                chartWidth={
                  (gridWidth / 24) *
                  layoutWidth(dashboard ? graph.dashboard_graph_id : graph.graph_id)
                }
              />
            </div>
          ))}
        </GridLayout>
      </div>
      <ChartDatePickerModalBlock
        dataIdStartDate="chart-interval-date-from"
        dataIdEndDate="chart-interval-date-to"
        show={openCustomDateModal}
        onSuccess={changeCustomDateInterval}
        onClose={setOpenedCustomDateModal.bind(null, false)}
      />
      {dashboard
        ? openModal && (
            <ChartEditModal
              width={data?.width}
              range={dateToString(data?.range)}
              editMode={true}
              dashboard_graph_id={data?.dashboard_graph_id}
              show={openModal}
              live_report_graph_id={data?.graph_id || ''}
              onClose={setMobile.bind(null, false)}
              getChartName={data?.name || ''}
              getChartRange={getChartRange(data?.range) || ''}
            />
          )
        : openModal && (
            <ModalSettingsChartBlock
              isOpen={openModal}
              chartId={data?.graph_id}
              onClose={setMobile.bind(null, false)}
              onRemove={setOpenRemoveModal}
            />
          )}
      <EasyModal
        show={isOpenRemoveModal}
        variant="removal"
        label={t('modal_remove_chart_title')}
        onClose={setOpenRemoveModal.bind(null, false)}
        onRemove={handlerRemoveModal}
        withoutFooter
      >
        <div className="flex items-center justify-center">{t('button.remove_chart')}</div>
      </EasyModal>
    </>
  );
};

export default React.memo(GridLayoutChartsContainer);

import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  ChangeStatusRequest,
  LiveReport,
  LiveReportSettingsRequestData,
  LiveReportsRequestData,
} from '@app/interfaces/report.type';

import { Filter, Range } from '@app/components/ui/easy-filter/types';

import { RecordLiveDataResponse, RecordRequestData } from '@app/interfaces/records';

import { baseQuery } from '../baseQuery';

export const liveReportsApi = createApi({
  reducerPath: 'liveReportsApi',
  tagTypes: ['liveReports', 'singleLiveReport', 'liveReportRecords'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getAllLiveReportsList: build.query<
      { live_reports: LiveReport[]; total: number },
      LiveReportsRequestData
    >({
      query: (body) => ({
        url: serverRoutes.live_reports,
        method: 'POST',
        body,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.live_reports.map(({ live_report_id }) => ({
                type: 'liveReports' as const,
                live_report_id,
              })),
              'liveReports',
            ]
          : ['liveReports'],
    }),
    getLiveReportSettings: build.query<LiveReport, LiveReportSettingsRequestData>({
      query: (params) => ({
        url: serverRoutes.live_report,
        params,
      }),
      providesTags: ['singleLiveReport'],
    }),
    deleteLiveReport: build.mutation({
      query: (params: { id: string }) => ({
        url: serverRoutes.live_report,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['liveReports'],
    }),
    editLiveReport: build.mutation<
      LiveReport,
      { params: { id: string }; body: { name: string; filter: Filter & Range } }
    >({
      query: ({ params, body }) => ({
        url: serverRoutes.live_report,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['liveReports', 'singleLiveReport', 'liveReportRecords'],
    }),
    createLiveReport: build.mutation<
      LiveReport,
      { name: string; project_id: string; description: string; filter: Filter & Range }
    >({
      query: (body) => ({
        url: serverRoutes.live_report,
        method: 'POST',
        body,
      }),
      // invalidatesTags: ['liveReports', 'liveReportRecords'],
    }),
    copyLiveReport: build.mutation<
      LiveReport,
      { name: string; live_report_id: string; description?: string }
    >({
      query: (body) => ({
        url: serverRoutes.live_report_clone,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['liveReports'],
    }),
    // liveReportStatus
    editLiveReportStatus: build.mutation({
      query: (body: ChangeStatusRequest) => ({
        url: serverRoutes.changeStatus,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['liveReports', 'singleLiveReport'],
    }),
    // live-report-download-csv
    // downloadCsvLiveReport: build.query<Blob, { params: { id: string }; name: string }>({
    //   query: ({ params, name }) => ({
    //     url: serverRoutes.live_report_export,
    //     params,
    //     responseHandler: async (response) => {
    //       const a = document.createElement('a');
    //       document.body.appendChild(a);
    //       const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // add here
    //       const blob = new Blob([bom, response.body], { type: 'octet/stream' }), // add bom
    //         url = window.URL.createObjectURL(blob);
    //       a.href = url;
    //       a.download = `${name}.csv`;
    //       a.click();
    //       window.URL.revokeObjectURL(url);
    //     },
    //     cache: 'no-cache',
    //   }),
    // }),
    //liveReport records
    getLiveReportRecords: build.query<
      RecordLiveDataResponse,
      { params: { id: string }; body: Omit<RecordRequestData, 'id'> }
    >({
      query: ({ params, body }) => ({
        url: serverRoutes.live_report_records,
        method: 'POST',
        params,
        body,
      }),
      providesTags: ['liveReportRecords'],
    }),
  }),
});

export const {
  useGetAllLiveReportsListQuery,
  useDeleteLiveReportMutation,
  useGetLiveReportSettingsQuery,
  useEditLiveReportMutation,
  useGetLiveReportRecordsQuery,
  useEditLiveReportStatusMutation,
  useCreateLiveReportMutation,
  useCopyLiveReportMutation,
} = liveReportsApi;

module.exports = {
  popup_hints: {
    recog_model_button: 'Выбрать модель распознавания',
  },
  system: {
    timezone_default: 'По умолчанию',
    placeholder: 'Выберите'
  },
  create_new_ap_title: 'Создать проект',
  main_settings_title: 'Основные настройки',
  recog_model_modal_title: 'Список доступных моделей распознавания',
  delete_proj: 'Удалить проект',
  delete_proj_confirm: 'Все данные аналитического проекта, в том числе отчёты, будут удалены.',
  form_fields: {
    proj_name: 'Название проекта',
    group_proj: 'Группа проектов',
    proj_shared: 'Доступ к проекту',
    timezone: 'Временная зона',
    recog_model: 'Модель распознавания',
  },
  buttons: {
    create_project: 'Создать',
    save_project: 'Сохранить',
    delete_project: 'Удалить проект',

  }
};

import React, { FC, ReactNode, useMemo, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import { useNavigate } from 'react-router-dom';

import { Box, CircleButton, Divide, DropMenu } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { DropMenuItem } from '@app/components/ui/drop-menu/drop-menu.type';
import {
  ChartTypes,
  ReportChart,
  ReportChartResponse,
  ReportTimeRange,
} from '@app/interfaces/report.type';
import useChartDateIntervals, { ChartOptionDate } from '@app/hooks/use-chart-date-intervals';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { useGetDashboardQuery } from '@app/store/api/dashboard-page.api';
import { useLazyGetGraphDataQuery } from '@app/store/api/graph-data.api';
import { FilterItem } from '@app/interfaces/filter';

import { CheckIcon } from '@icons';

import { useAppSelector } from '@app/store/store';

import { RecordRangeRangeParametersItemForRType } from '@app/interfaces/record';

import moment from 'moment';

import { getBrowserType } from '@app/utils/get-browser-type';

import { ChartsLayoutType } from '@app/interfaces/dashboards.type';

import { chartBuildData } from './chart-build.data';
import ChartNative from './chart-native';

type ChartComponentProps = {
  data: ReportChartResponse['data'];
  chartId?: string;
  dashboard_graph_id?: string;
  dashboard?: boolean;
  commonRange?: ReportTimeRange | null;
  viewOnly?: boolean;
  onEditIntervalWithoutSave?: (data: {
    data: ReportChart;
    id: string;
    layout: ChartsLayoutType | undefined;
  }) => void;
  layoutSettings: ChartsLayoutType[];
  setOpenedCustomDateModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setMobile?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenRemoveModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setData?: React.Dispatch<React.SetStateAction<ReportChart | undefined>>;
  filterValue?: FilterItem[];
  chartWidth?: number;
  chartHeight?: number;
};

const ChartBlock: FC<ChartComponentProps> = (props) => {
  const {
    data,
    dashboard,
    viewOnly,
    onEditIntervalWithoutSave,
    setOpenedCustomDateModal,
    setOpenRemoveModal,
    setMobile,
    setData,
    filterValue,
    chartWidth,
    layoutSettings,
    dashboard_graph_id,
  } = props;
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const { currentData: currentDashboard } = useGetDashboardQuery(
    userSettings?.currentDashboardId || '',
    { skip: !dashboard },
  );
  const [visibleToolbarState, changeVisibleToolbarState] = useState(false);
  const [getGraphData] = useLazyGetGraphDataQuery();
  const navigate = useNavigate();
  const { t } = useTranslation('pages.chartPage');
  const { daysSelectOptions, dateIntervals, dateToString, dateKeys } = useChartDateIntervals();
  const editChartOptions: Array<DropMenuItem> = [
    {
      title: t('drop_menu.edit'),
      icon: 'EditIcon',
      key: 'edit',
      disable: dashboard && !currentDashboard?.owner,
    },
    {
      title: t('drop_menu.to_report'),
      icon: 'MetricIcon',
      key: 'to_report',
      disable: !!data.errorStatus,
    },
    {
      content: (
        <div className="flex items-center gap-[5px]">
          <div>{t('drop_menu.toolbar')}</div>
          {visibleToolbarState && (
            <div>
              <CheckIcon className="text-action" size={12} />
            </div>
          )}
        </div>
      ),
      icon: 'ChartIcon',
      key: 'toolbar',
    },
    {
      title: t('drop_menu.remove'),
      color: 'danger',
      icon: 'TrashIcon',
      key: 'remove',
      disable: dashboard && !currentDashboard?.owner,
    },
  ];

  const handlerChangeChartDateSelect = (dateInterval: string) => {
    if (dashboard) {
      if (dateKeys.customDate === dateInterval) {
        setData && setData(data);
        setOpenedCustomDateModal && setOpenedCustomDateModal(true);
      } else {
        getGraphData({
          id: data.graph_id,
          settings: {
            width: data.width,
            range: dateIntervals[dateInterval],
          },
        }).then((Cdata) => {
          const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
          const layout = layoutSettings.find((layout) => layout.i === dashboard_graph_id);
          onEditIntervalWithoutSave &&
            data.dashboard_graph_id &&
            onEditIntervalWithoutSave({
              data: chartData,
              id: data.dashboard_graph_id,
              layout: layout,
            });
        });
      }
    } else {
      if (dateKeys.customDate === dateInterval) {
        setData && setData(data);
        setOpenedCustomDateModal && setOpenedCustomDateModal(true);
      } else {
        getGraphData({
          id: data.graph_id,
          settings: {
            width: data.width,
            range: dateIntervals[dateInterval],
            filter: filterValue,
          },
        }).then((Cdata) => {
          const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
          const layout = layoutSettings.find((layout) => layout.i === chartData.graph_id);

          onEditIntervalWithoutSave &&
            data.graph_id &&
            onEditIntervalWithoutSave({ data: chartData, id: data.graph_id, layout: layout });
        });
      }
    }
  };
  function handleEditChart({ key }: { key: string }) {
    switch (key) {
      case 'edit': {
        setData && setData(data);
        setMobile && setMobile(true);
        break;
      }
      case 'toolbar': {
        changeVisibleToolbarState((prev) => !prev);
        break;
      }
      case 'remove': {
        setData && setData(data);
        setOpenRemoveModal && setOpenRemoveModal(true);
        break;
      }
      case 'to_report': {
        navigate(`/${navigationRoutes.reports}/${data.live_report_id}`);
        break;
      }
    }
  }
  const currentChartBuild = useMemo(
    () =>
      chartBuildData({
        chart: data,
        translationList: {
          download: t('chart_toolbar.download'),
          selectionZoom: t('chart_toolbar.selectionZoom'),
          zoomIn: t('chart_toolbar.zoomIn'),
          zoomOut: t('chart_toolbar.zoomOut'),
          pan: t('chart_toolbar.pan'),
          reset: t('chart_toolbar.reset'),
        },
      }),
    [data, t],
  );
  const rangeMarkup: Record<ChartOptionDate, ReactNode> = {
    all: <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'all')?.title}</div>,
    day: <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'day')?.title}</div>,
    week: <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'week')?.title}</div>,
    month: <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'month')?.title}</div>,
    today: <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'today')?.title}</div>,
    currentWeek: (
      <div>{daysSelectOptions.find((daysOptions) => daysOptions.key === 'currentWeek')?.title}</div>
    ),
    currentMonth: (
      <div>
        {daysSelectOptions.find((daysOptions) => daysOptions.key === 'currentMonth')?.title}
      </div>
    ),
    customDate: (
      <div className="flex items-center gap-[10px]">
        <div className="flex items-center gap-[5px]">
          {(data.range.parameters as unknown as RecordRangeRangeParametersItemForRType[]).map(
            (rangeItem, index) => (
              <div key={index}>
                {index === 0 ? t('interval_under_label.from') : t('interval_under_label.to')} &nbsp;
                {moment(rangeItem.value).format('DD.MM.YYYY  HH:mm')}
              </div>
            ),
          )}
        </div>
      </div>
    ),
  };
  const getTextWidth = (text: string, fontSize: number) => {
    const font = `bold ${fontSize}pt Helvetica`;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    (context as CanvasRenderingContext2D).font = font;
    return context?.measureText(text).width;
  };

  const trimText = (text: string | undefined, fontSize: number) => {
    if (!text) return;
    const chartWidthWithoutToolbar =
      (chartWidth as number) -
      (visibleToolbarState
        ? (chartWidth || 0) > 600
          ? 150
          : (chartWidth || 0) > 500
          ? 140
          : (chartWidth || 0) > 400
          ? 190
          : (chartWidth || 0) > 300
          ? 220
          : (chartWidth || 0) > 200
          ? 340
          : 100
        : (chartWidth || 0) > 400
        ? 20
        : (chartWidth || 0) > 300
        ? 70
        : (chartWidth || 0) > 200
        ? 100
        : 0);
    const oneCharacterWidth = getTextWidth(Array.from(text)[0], fontSize);
    if (!oneCharacterWidth) return;
    const threshold = Math.floor(chartWidthWithoutToolbar / oneCharacterWidth);
    if (text.length <= threshold) return text;
    return text.substring(0, threshold).concat('...');
  };
  const noDataTextMap = {
    500: t('system.no_metric'),
    403: t('system.no_access'),
  };
  const title = (chartWidth || 0) > 250 ? trimText(data.name, data.styles.header_size) : undefined;

  if (!data) return <></>;

  return (
    <Box className={`${data.errorStatus && '!bg-basic_red/[.4]'} min-w-full h-full  relative py-4`}>
      {/* gap-[3px]*/}
      {data && (chartWidth || 0) > 230 ? (
        <div
          style={{
            left: getBrowserType() === 'Apple Safari' ? 10 : 20,
            top: data.styles.header_size + 12,
          }}
          className="absolute z-10"
        >
          <DropMenu
            dropSize={210}
            onClick={(key) => handlerChangeChartDateSelect(key)}
            menu={daysSelectOptions.map((item) => ({
              ...item,
              isActive: dateToString(data?.range) === item.key,
            }))}
            position="start"
          >
            <div className="noDrag text-4color font-[400] hover:underline leading-[10px] tracking-tight cursor-pointer text-[12px]">
              {rangeMarkup[dateToString(data?.range)]}
            </div>
          </DropMenu>
        </div>
      ) : (
        <div></div>
      )}
      <div className="flex items-center z-[1] justify-between absolute w-full">
        <div></div>
        {!viewOnly && (
          <div className="flex items-center noDrag mr-[15px]">
            <Divide size={15} span={1} orientation="vertical" className="ml-[16px] mr-[5px]" />
            <DropMenu
              dropSize={210}
              onClick={(key) => handleEditChart({ key })}
              hintTitle={t('menu')}
              menu={
                !dashboard
                  ? editChartOptions.filter((item) => item.key !== 'to_report')
                  : editChartOptions
              }
            >
              <CircleButton icon="VerticalDotsIcon" size={12} className="text-3color" />
            </DropMenu>
          </div>
        )}
      </div>
      <ChartNative
        options={currentChartBuild?.options as unknown as ApexOptions}
        series={currentChartBuild?.series}
        height="100%"
        isShowToolbar={true}
        width="100%"
        handlers={{
          updateOptions: {
            noData: data.errorStatus
              ? { text: noDataTextMap[data.errorStatus] }
              : { text: t('system.no_data') },
            title: {
              text: title,
              offsetY: -19,
              margin: 30,
              style: {
                fontSize: `${data.styles.header_size}px`,
              },
            },
            chart: {
              toolbar: {
                show: true,
                offsetX:
                  (chartWidth || 0) > 950
                    ? 3
                    : (chartWidth || 0) > 950
                    ? 0
                    : (chartWidth || 0) > 800
                    ? -5
                    : (chartWidth || 0) > 700
                    ? -10
                    : (chartWidth || 0) > 500
                    ? -20
                    : (chartWidth || 0) > 300
                    ? -30
                    : -35,
                tools: {
                  pan: visibleToolbarState,
                  zoom: visibleToolbarState,
                  selection: visibleToolbarState,
                  zoomin: visibleToolbarState,
                  zoomout: visibleToolbarState,
                  reset: visibleToolbarState,
                },
              },
            },
            legend: {
              position:
                (chartWidth || 0) > 500 ? currentChartBuild.options.legend.position : 'bottom',
              // height: (chartHeight || 0) - 80,
            },
          },
        }}
        type={(currentChartBuild?.options?.chart?.type as ChartTypes) || 'bar'}
      />

      <div className="w-full h-[30px]"></div>
    </Box>
  );
};

export default React.memo(ChartBlock);

import React, { useCallback, useEffect, useState } from 'react';
import ReactWordcloud, { Word } from 'react-wordcloud';

import { Box, Skeleton } from '@app/components/ui';

import { useOutletContext } from 'react-router';

import { Empty } from '@ui';

import { useGetWordsNGramStaticCloudQuery } from '@app/store/api/get-words-n-gramm.api';

import { WordCloudContext } from '../types/types';

export const WordsNGramCloudContent = () => {
  const { data: dataForCloud } = useOutletContext<WordCloudContext>();
  const [cloudData, changeCloudData] = useState(dataForCloud);
  const { data, isFetching } = useGetWordsNGramStaticCloudQuery(cloudData, {
    skip: cloudData === undefined,
  });
  useEffect(() => {
    changeCloudData(dataForCloud);
  }, [dataForCloud]);

  const SimpleWordCloud = useCallback(() => {
    if (!data) return;
    return data?.map((item) => ({ text: item.text, value: item.freq })) as Word[];
  }, [data]);

  if (isFetching) return <Skeleton height={550} />;
  return (
    <Box className="w-full h-full flex justify-center">
      <div>
        {SimpleWordCloud()?.length ? (
          <ReactWordcloud
            options={{
              fontSizes: [13, 49],
              rotations: 0,
              deterministic: true,
              fontFamily: 'SF Pro Display',
              randomSeed: '0.6',
              padding: 2.5,
              tooltipOptions: { theme: 'tomato' },
              spiral: 'archimedean',
              scale: 'sqrt',
            }}
            size={[600, 630]}
            callbacks={{ getWordTooltip: (value) => `${value.text} ${value.value}%` }}
            words={SimpleWordCloud() || []}
          />
        ) : (
          <div className={'w-[600px] flex items-center justify-center h-[400px]'}>
            <Empty />
          </div>
        )}
      </div>
    </Box>
  );
};

import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Navigate, useSearchParams } from 'react-router-dom';

import { AuthForm, AuthResponse } from '@app/interfaces/auth';
import { getAuth } from '@app/api/auth.api';
import { AxiosResponse } from 'axios';
import { useAuthContext } from '@app/utils/auth-provider';

import { AnalyticsIcon, LogoIcon, UktechIcon } from '@icons';
import { Button, Input } from '@app/components/ui';
import useTranslation from '@app/hooks/use-translation';
import { useParams } from 'react-router';
import { getAuthRtk } from '@app/api/rtk-auth.api';

const AuthorizationPage: React.FC = () => {
  const node = useParams();
  const [searchParams] = useSearchParams();
  const { setIsAuth, login, isAuth } = useAuthContext();
  const { t } = useTranslation('pages.authorization');
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<AuthForm>();

  // const formValidation = {
  //   email: {
  //     required: {
  //       value: true,
  //     },
  //   },
  //   password: {
  //     required: {
  //       value: true,
  //     },
  //     minLength: {
  //       value: 6,
  //     },
  //   },
  // };

  const loginAuthClick = (data: AuthForm) => {
    const formData = new FormData();
    Object.keys(data).forEach((fieldKey) => formData.append(fieldKey, data[fieldKey]));
    getAuth(formData)
      .then((response: AxiosResponse<AuthResponse>) => {
        toast.success(t('notification.success'));
        login(response.data);
        setIsAuth(true);
        // localStorage.removeItem('LAST_URL');
      })
      .catch(() => {
        setError('username', { message: t('input.errors.wrong_email') });
        setError('password', { message: t('input.errors.wrong_password') });
      });
  };
  React.useEffect(() => {
    if (process.env.REACT_APP_THEME === 'rtk') {
      const hash = searchParams.get('hash_key');
      const context = searchParams.get('context');
      if (hash !== null) {
        getAuthRtk({ hash: hash, node: node['*'] || undefined })
          .then((response: AxiosResponse<AuthResponse>) => {
            localStorage.setItem('LAST_URL', context === null ? '/' : `/${context}`);
            toast.success(t('notification.success'));
            login(response.data);
            setIsAuth(true);
          })
          .catch(() => {
            window.location.replace(
              (process.env.REACT_APP_RTK_AUTH_URL +
                `?context=${window.location.pathname}`) as string,
            );
          });
      } else if (!isAuth) {
        window.location.replace(process.env.REACT_APP_RTK_AUTH_URL as string);
      }
    }
  }, [isAuth, login, node, searchParams, setIsAuth, t]);

  if (isAuth) return <Navigate to={localStorage.getItem('LAST_URL') || '/'} replace />;

  return (
    <div className="w-screen h-screen inline-flex items-center justify-center">
      {process.env.REACT_APP_THEME !== 'rtk' && (
        <div className="">
          <div className="inline-flex items-center justify-center mb-[61px] mt-[-3px] ml-[-8px] w-full">
            <LogoIcon className="text-primary_copy mr-[16px]" size={38} />
            <div className="flex items-start">
              <UktechIcon className="text-black mr-[9px]" size={73} />
              <AnalyticsIcon className="text-primary_copy mt-[1px]" size={139} />
            </div>
          </div>
          <div className="bg-white shadow-hard rounded-[10px] w-[409px]">
            <div className="w-full pt-[37px] flex flex-col">
              <div className="mb-[28px]">
                <h2 className="font-[700] text-0color w-full text-center text-[24px]">
                  {t('title')}
                </h2>
              </div>
              <form
                className={`${
                  errors.password?.message ? 'pb-[17px]' : 'pb-[49px]'
                } w-full flex flex-col pr-[57px] pl-[58px]`}
                onSubmit={handleSubmit(loginAuthClick)}
              >
                <div className="mb-[23px]">
                  <Input
                    label={t('input.login')}
                    {...register('username', { required: true })}
                    placeholder={t('input.placeholder.login')}
                    // error={errors.username?.message}
                  />
                </div>
                <div className="mb-[38px]">
                  <Input
                    label={t('input.password')}
                    type="password"
                    {...register('password', { required: true })}
                    placeholder={t('input.placeholder.password')}
                    // error={errors.password?.message}
                  />
                </div>
                <Button label={t('button.login')} type="submit" isFull />
              </form>
            </div>
            {errors.password?.message && (
              <div className="mt-[31px] text-basic_red text-[15px] leading-[18px] font-[700] flex flex-col gap-[13px] items-center pb-[35px]">
                <span>{errors.username?.message}</span>
                <span>{errors.password?.message}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthorizationPage;

// <div className="w-full pt-[30px] inline-flex justify-center">

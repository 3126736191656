import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import ChartListBlock from '@app/pages/reports/blocks/chart-list.block';

import { useGetLiveReportSettingsQuery } from '@app/store/api/live-reports.api';

import { SkeletonPageLoading } from '@ui';

import ReportHeaderWithStatusBlock from './blocks/report-header-with-status.block';
import ReportNavigatorBlock from './blocks/report-navigator.block';

const ReportChartPage: FC = () => {
  const { id } = useParams();
  const { currentData: liveReport, isLoading: liveReportLoading } = useGetLiveReportSettingsQuery(
    { id: id || '' },
    { skip: !id },
  );

  if (liveReportLoading) return <SkeletonPageLoading />;

  return (
    <div>
      <div>
        <ReportHeaderWithStatusBlock />
        <ReportNavigatorBlock />
      </div>
      <ChartListBlock live_report_id={liveReport?.live_report_id || ''} />
    </div>
  );
};

export default ReportChartPage;

import React, { FC } from 'react';
import { TableColumn, TableItem } from '@app/components/rubber-table/table.types';
import useTranslation from '@app/hooks/use-translation';
import { CircleButton } from '@ui';
import { IntegrationAccessKeyObject } from '@app/interfaces/settings.type';
import RubberTable from '@app/components/rubber-table';
type IntegrationTableBlockPropsType = {
  tableData: IntegrationAccessKeyObject[] | undefined;
  onEditIconClick: (id: string) => void;
  onDeleteIconClick: (id: string) => void;
};
export const IntegrationTableBlock: FC<IntegrationTableBlockPropsType> = (props) => {
  const { onEditIconClick, onDeleteIconClick, tableData } = props;
  const { t } = useTranslation('pages.settings.integrations.table');
  const tableIntegrationColumns: Array<TableColumn> = [
    { index: 'name', title: t('name') },
    { index: 'type', title: t('type') },
    { index: 'actions', title: t('actions'), maxWidth: 110, divider: 'left' },
  ];
  const tableIntegrationData = tableData
    ? (tableData.map((item) => ({
        ...item,
        actions: (
          <div className="inline-flex items-center">
            <CircleButton
              onClick={() => onEditIconClick(item.access_key)}
              className="mr-[20px] text-action"
              size={18}
              icon="EditIcon"
            />
            <CircleButton
              onClick={() => onDeleteIconClick(item.access_key)}
              className="text-basic_red"
              size={18}
              icon="TrashIcon"
            />
          </div>
        ),
        type: 'REST API',
      })) as unknown as TableItem[])
    : [];
  return <RubberTable columns={tableIntegrationColumns} dataSource={tableIntegrationData} />;
};

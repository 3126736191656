import useTranslation from '@app/hooks/use-translation';

import connector, { PropsFromRedux } from '@app/utils/store';
import { Button, DropMenu, EasyModal, Empty, PageHeader, Popover, Select } from '@ui';
import Icon from '@app/components/ui/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CreateChartModal from '@app/pages/dashboards/blocks/create-chart-modal';
import EditText from '@app/components/ui/edit-text';
import { sharedTranslate } from '@app/pages/dashboards/data/data';
import {
  useDeleteDashboardMutation,
  useEditDashboardMutation,
  useGetDashboardsListQuery,
} from '@app/store/api/dashboard-page.api';
import { DropMenuItem } from '@app/components/ui/drop-menu/drop-menu.type';
import useChartDateIntervals, { ReportTimeRange } from '@app/hooks/use-chart-date-intervals';
import {
  dashboardChartsApi,
  useGetDashboardGraphListQuery,
} from '@app/store/api/dashboard-charts.api';
import {
  ChartsLayoutType,
  dashboardChartsSettings,
  DashboardsSettingsType,
} from '@app/interfaces/dashboards.type';
import { ReportChart } from '@app/interfaces/report.type';
import SelectDashboardsList from '@app/pages/dashboards/blocks/SelectDashboardsList';
import { GridLayoutChartsContainer } from '@app/components/grid-layout-charts-container';
import ChartDatePickerModalBlock from '@app/pages/reports/blocks/chart-date-picker-modal.block';
import { useLazyGetGraphDataQuery } from '@app/store/api/graph-data.api';

import DashboardChartsSkeleton from '@app/pages/dashboards/blocks/dashboard-charts.skeleton';
import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';
import { useAppDispatch, useAppSelector } from '@app/store/store';

type PropsType = {
  createNewDashboard: (data: { name: string; shared: boolean }) => void;
  currentDashboard: DashboardsSettingsType | undefined;
};

const Dashboard: React.FC<PropsType & PropsFromRedux> = (props) => {
  const { createNewDashboard, currentDashboard } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation('pages.dashboard');
  const { t: tModal } = useTranslation('components.easyModal');
  const { daysSelectOptions, dateIntervals, intervalTimeBuilder } = useChartDateIntervals();
  const { userSettings } = useAppSelector((state) => state.userSettings);
  //api dashboard
  const [editDashboardSettings] = useEditDashboardMutation();
  const { data: dashboardList } = useGetDashboardsListQuery();
  const [deleteDashboardMutation] = useDeleteDashboardMutation();
  //api dashboard

  //api dashboardGraphs
  const { data: chartsData, isLoading: chartsLoading } = useGetDashboardGraphListQuery(
    userSettings?.currentDashboardId || currentDashboard?.dashboard_id || '',
    { skip: !userSettings?.currentDashboardId },
  );
  const [getGraphData] = useLazyGetGraphDataQuery();
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  //api dashboardGraphs

  //charts state
  const [flagForSendChartLayoutRequestChange, changeFlagForSendChartLayoutRequestChange] =
    useState<boolean>(false);
  const [layoutSettings, changeLayoutSettings] = useState<ChartsLayoutType[]>([]);
  const [charts, changeChartsState] = useState<
    (dashboardChartsSettings & ReportChart)[] | undefined
  >(chartsData || []);
  const [editDashboardLayout, changeEditDashboardLayout] = useState<boolean>(false);

  useEffect(() => {
    changeChartsState(chartsData);
  }, [chartsData]);
  //charts state

  //state
  const customDateTrigger = 'customDate';
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [isShowModelDatePicker, changeShowingModelDatePicker] = useState<boolean>(false);
  const [commonRange, setCommonRange] = useState<ReportTimeRange | null>(null);
  const [createModalState, changeCreateModalState] = useState<boolean>(false);
  const [editDashboardState, changeEditDashboardState] = useState<boolean>(false);
  const [sharedStatusValue, changeSharedStatusValue] = useState<boolean | undefined>();
  const [dashboardNameValue, changeDashboardNameValue] = useState<string | undefined>(
    currentDashboard?.name,
  );
  const [dashboardIdForDelete, changeDashboardIdForDelete] = useState<string>();
  const [deleteModalState, changeDeleteModalState] = useState(false);
  //state

  //effects
  useEffect(() => {
    changeSharedStatusValue(currentDashboard?.shared);
  }, [currentDashboard?.shared]);
  //effects

  //functions
  const handleChangeIntervalWithoutSave = useCallback(
    ({ data, id, layout }: { data: ReportChart; id: string; layout?: ChartsLayoutType }) => {
      if (!layout || !currentDashboard?.dashboard_id) return;
      dispatch(
        dashboardChartsApi.util?.updateQueryData(
          'getDashboardGraphList',
          currentDashboard?.dashboard_id,
          (draft) => {
            return draft?.map((draftedChart) => {
              if (draftedChart.dashboard_graph_id === id) {
                draftedChart = {
                  ...draftedChart,
                  categories: data.categories,
                  series: data.series,
                  range: data.range,
                  layout: layout,
                };
              }
              return draftedChart;
            });
          },
        ),
      );
    },
    [currentDashboard?.dashboard_id, dispatch],
  );
  function deleteModalApplyBtn() {
    if (!dashboardList) return;
    const deletedIndex = dashboardList?.dashboard.findIndex(
      (e) => currentDashboard?.dashboard_id === e.dashboard_id,
    );
    dashboardIdForDelete && deleteDashboardMutation(dashboardIdForDelete);
    if (deletedIndex !== 0 && currentDashboard?.dashboard_id === dashboardIdForDelete) {
      updateUserSettings({ ...userSettings, currentDashboardId: null })
        .unwrap()
        .then(() => {
          dashboardChange(dashboardList?.dashboard[deletedIndex - 1].dashboard_id);
        });
    } else if (
      deletedIndex === 0 &&
      dashboardList?.dashboard.length > 1 &&
      currentDashboard?.dashboard_id === dashboardIdForDelete
    ) {
      updateUserSettings({ ...userSettings, currentDashboardId: null })
        .unwrap()
        .then(() => {
          dashboardChange(dashboardList?.dashboard[deletedIndex + 1].dashboard_id);
        });
    }
    changeDeleteModalState(false);
  }

  const dashboardChange = (id: string) => {
    updateUserSettings({ ...userSettings, currentDashboardId: id });
  };
  function editSharedStatusHandler(shared: boolean) {
    if (currentDashboard) {
      editDashboardSettings({
        id: currentDashboard.dashboard_id,
        body: { name: currentDashboard?.name, shared: shared },
      });
    }
    changeEditDashboardState(false);
  }
  function editDashboardNameHandler(name: string) {
    if (currentDashboard) {
      editDashboardSettings({
        id: currentDashboard.dashboard_id,
        body: { name: name, shared: currentDashboard.shared },
      })
        .unwrap()
        .then(() => changeDashboardNameValue(name));
    }
  }
  function deleteDashboard(id: string) {
    changeDashboardIdForDelete(id);
    changeDeleteModalState(true);
  }
  function handlerDateDropTrigger(key: string | number) {
    if (!charts) return;
    if (key === customDateTrigger) {
      changeShowingModelDatePicker(true);
      return;
    } else {
      setLocalLoading(true);
      for (const chart of charts) {
        getGraphData({
          id: chart.graph_id,
          settings: {
            width: chart.width,
            range: dateIntervals[key],
          },
        }).then((Cdata) => {
          const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
          const layout = layoutSettings.find((layout) => layout.i === chart.dashboard_graph_id);
          handleChangeIntervalWithoutSave({
            data: chartData,
            id: chart.dashboard_graph_id,
            layout,
          });
        });
      }
    }
    setCommonRange(dateIntervals[key]);
    setTimeout(() => setLocalLoading(false), 500);
  }
  function handlerChangeCustomRangeInterval({
    start,
    end,
  }: {
    start: string | null | Date;
    end: string | null | Date;
  }) {
    if (!charts) return;
    const range = intervalTimeBuilder(start, end) as unknown as ReportTimeRange;
    for (const chart of charts) {
      getGraphData({
        id: chart.graph_id,
        settings: {
          width: chart.width,
          range,
        },
      }).then((Cdata) => {
        const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
        const layout = layoutSettings.find((layout) => layout.i === chart.dashboard_graph_id);
        handleChangeIntervalWithoutSave({ data: chartData, id: chart.dashboard_graph_id, layout });
      });
    }
  }
  //functions

  //popover
  const popoverDashboardsMenu = [
    {
      content: (
        <SelectDashboardsList
          dashboardList={dashboardList}
          currentData={currentDashboard}
          deleteDashboard={deleteDashboard}
          dashboardChange={dashboardChange}
          createNewDashboard={createNewDashboard}
        />
      ),
      title: '123',
      key: 'key',
    },
  ];
  //popover
  //charts
  const renderCharts = useMemo(
    () =>
      charts?.length ? (
        <GridLayoutChartsContainer
          layoutSettings={layoutSettings}
          changeLayoutSettings={changeLayoutSettings}
          dashboard
          currentDashboardOrReportId={currentDashboard?.dashboard_id}
          commonRange={commonRange}
          onEditIntervalWithoutSave={handleChangeIntervalWithoutSave}
          charts={charts}
          isDraggable={editDashboardLayout}
          flagForSendChartLayoutRequestChange={flagForSendChartLayoutRequestChange}
        />
      ) : (
        <Empty title={t('system.dashboard_empty')} />
      ),
    [
      charts,
      commonRange,
      currentDashboard?.dashboard_id,
      editDashboardLayout,
      flagForSendChartLayoutRequestChange,
      handleChangeIntervalWithoutSave,
      layoutSettings,
      t,
    ],
  );

  //charts
  return (
    <>
      <CreateChartModal
        createModalState={createModalState}
        setCreateModalState={changeCreateModalState}
      />
      <ChartDatePickerModalBlock
        dataIdStartDate="dashboard-all-charts-interval-date-from"
        dataIdEndDate="dashboard-all-charts-interval-date-to"
        onSuccess={handlerChangeCustomRangeInterval}
        show={isShowModelDatePicker}
        onClose={changeShowingModelDatePicker.bind(null, false)}
      />

      <PageHeader
        label={
          <div className="flex items-center gap-[18px]">
            {dashboardNameValue && (
              <EditText
                textStyle="!text-black !text-[24px]"
                text={dashboardNameValue}
                width="medium600"
                maxLength={64}
                tooltipTitle={dashboardNameValue}
                onEdited={editDashboardNameHandler}
                disableEdit={!currentDashboard?.owner}
              />
            )}
          </div>
        }
      >
        <div className="">
          {currentDashboard?.owner && (
            <div
              className={`flex items-center gap-[10px] font-[500] text-[14px] leading-[17px] text-1color ${
                !editDashboardState && 'opacity-[0.65]'
              }`}
            >
              {editDashboardState ? (
                <>
                  <Select
                    size={254}
                    defaultValue={String(sharedStatusValue)}
                    dropWidth={250}
                    options={Object.entries(sharedTranslate).map((item) => ({
                      value: item[0],
                      title: t(`system.${item[1]}`),
                    }))}
                    onChange={(item) => changeSharedStatusValue(item === 'true')}
                  />
                  <div
                    onClick={() => editSharedStatusHandler(sharedStatusValue as boolean)}
                    className="p-[8.5px_11.5px] bg-white rounded-[10px]"
                  >
                    <Icon size={11} name="CheckIcon" className="cursor-pointer text-basic_green" />
                  </div>
                  <div
                    onClick={() => {
                      changeEditDashboardState(false);
                      changeSharedStatusValue(currentDashboard?.shared);
                    }}
                    className="p-[8.5px_11.5px] bg-white rounded-[10px]"
                  >
                    <Icon
                      size={11}
                      className="cursor-pointer text-basic_red opacity-1"
                      name="XIcon"
                    />
                  </div>
                </>
              ) : (
                <>
                  <span>{t(`system.${sharedTranslate[String(sharedStatusValue)]}`)}</span>
                  <Icon
                    onClick={() => changeEditDashboardState(true)}
                    name="SettingIcon"
                    className="cursor-pointer"
                  />
                </>
              )}
            </div>
          )}
        </div>
      </PageHeader>

      <div className="border-[#E5E8EB] border-t-[1px]">
        <div className="flex items-center gap-[16px] relative z-[30]  justify-end py-[24px]">
          <Button
            data-id="new-chart-dashboard"
            disabled={!currentDashboard?.owner}
            onClick={() => changeCreateModalState(true)}
            icon="CirclePlusIcon"
            label={t('buttons.add_new_graph')}
          />
          <Popover
            hintTitle={t('popup_hints.dashboard_list')}
            size={300}
            icon="ColumnDisplayIcon"
            position="end"
            menu={popoverDashboardsMenu}
          />
          <DropMenu
            dropSize={140}
            hintTitle={t('popup_hints.dashboard_intervals')}
            onClick={(key) => handlerDateDropTrigger(key)}
            menu={daysSelectOptions as unknown as Array<DropMenuItem>}
          >
            <Button icon="CalendarEmptyIcon" />
          </DropMenu>
          {currentDashboard?.owner && (
            <Button
              onClick={() => {
                editDashboardLayout
                  ? changeFlagForSendChartLayoutRequestChange(true)
                  : changeFlagForSendChartLayoutRequestChange(false);
                changeEditDashboardLayout((prev) => !prev);
              }}
              icon="SettingIcon"
              hintTitle={t('popup_hints.grid_layout_settings')}
              fill={'outlined'}
              variant={editDashboardLayout ? 'danger' : 'primary'}
            />
          )}
        </div>
      </div>
      {currentDashboard?.dashboard_id ? (
        chartsLoading || localLoading ? (
          <DashboardChartsSkeleton count={6} />
        ) : (
          <div className="w-full">{renderCharts}</div>
        )
      ) : (
        <div className="flex items-center justify-center h-[calc(100%-300px)]">
          {t('dashboard_not_selected')}
        </div>
      )}
      <EasyModal
        variant={'removal'}
        show={deleteModalState}
        onClose={changeDeleteModalState.bind(null, false)}
        label={tModal('warning')}
        onRemove={() => deleteModalApplyBtn()}
        withoutFooter
      >
        {t('modals.delete_modal.main_text')}
      </EasyModal>
    </>
  );
};

export default connector(Dashboard);

import React from 'react';
import { useOutletContext } from 'react-router-dom';

import { AnalyticPageOutlet } from '@app/pages/analytics/single-analytic.page';

import ReportsTableBlock from './blocks/reports-table.block';

const ProjectReportsPage = () => {
  const { project } = useOutletContext<AnalyticPageOutlet>();

  return (
    <div>
      <ReportsTableBlock projectAp={project} currentProjectId={project?.project_id} />
    </div>
  );
};

export default ProjectReportsPage;

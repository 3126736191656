import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { IntegrationAccessKeyObject } from '@app/interfaces/settings.type';
type IntegrationFormPropsType = {
  onFormSubmitHandler(data: { name: string }, newKeys?: boolean): void;
  integrationData: IntegrationAccessKeyObject | undefined;
  editMode: boolean;
  onFormDeleteHandler(): void;
};
export const IntegrationForm: FC<IntegrationFormPropsType> = (props) => {
  const { onFormSubmitHandler, integrationData, editMode, onFormDeleteHandler } = props;
  const { t } = useTranslation('pages.settings.integrations.form');
  const { register, handleSubmit, getValues } = useForm<{ name: string }>({
    defaultValues: { name: editMode ? integrationData?.name : undefined },
  });
  function handleSubmitForm(data: { name: string }) {
    onFormSubmitHandler(data);
  }

  function onCreateNewKeys() {
    onFormSubmitHandler(getValues() as { name: string }, true);
  }
  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <div className={'px-[40px]'}>
        <div className={'flex items-center gap-[80px]'}>
          <div className={'text-1color text-[15px] font-[500] leading-[16.4px]'}>{t('name')}</div>
          <Input {...register('name')} />
        </div>
        <div className={'w-full flex flex-col gap-[10px] items-center mt-[50px]'}>
          {editMode && (
            <Button
              onClick={onCreateNewKeys}
              type={'button'}
              label={t('newKeysButton')}
              fill={'linked'}
            />
          )}
          <Button
            type={'submit'}
            className={'!px-[80px] !py-[20px]'}
            isFull
            label={t('saveButton')}
          />
          {editMode && (
            <Button
              onClick={onFormDeleteHandler}
              label={t('deleteButton')}
              fill={'linked'}
              variant={'danger'}
            />
          )}
        </div>
      </div>
    </form>
  );
};

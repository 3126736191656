import React, { FC, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  Button,
  Checkbox,
  CircleButton,
  Input,
  Select,
  TextArea,
  Toggle,
  TooltipWrapper,
} from '@ui';

import { useCreateProjectMetricGroupMutation } from '@app/store/api/metrics.api';
import { useOutletContext } from 'react-router-dom';
import { AnalyticPageOutlet } from '@app/pages/analytics/single-analytic.page';
import {
  MetricListArrType,
  MetricObjType,
} from '@app/interfaces/pages-types/anatylics-metric.type';

import { pictogramsData } from '../../data/pictograms.data';

import { labelClassName, loadFormClassName, titleTagFormType } from './handle-metric-modal.style';

type MetricTypeFields =
  | 'num_native_input'
  | 'str_native_input'
  | 'str_native_select'
  | 'str_icons_select';

type HandleMetricSelectBuilder = {
  value: string;
  icon: number | null;
  id: number;
};

type HandleMetricForm = {
  type: 'tags';
  result_value_type: string;
  visualization: string;
  name: string;
  description: string;
  settings: {
    multiselect: boolean;
    input: string;
    default: {
      type: null | 'value' | 'copy';
      value?: string | number | null;
      copy_from?: string;
    } | null;
    select?: Array<HandleMetricSelectBuilder>;
  };
};

type HandleMetricFormProps = {
  onClose: () => void;
  metricList: Array<MetricListArrType>;
  isEdit: boolean;
  metricSettings: MetricObjType;
};

const HandleMetricForm: FC<HandleMetricFormProps> = (props) => {
  const { onClose, metricList, metricSettings, isEdit } = props;
  const metricResultTypes = { text: 'str', number: 'num' };
  const metricTypeFields: Record<MetricTypeFields, string> = {
    num_native_input: 'число',
    str_native_input: 'текст',
    str_native_select: 'выбор из списка',
    str_icons_select: 'пиктограммы',
  };
  const iconsOptionList = Object.entries(pictogramsData).map(([iconIndex, iconName]) => {
    return { value: Number(iconIndex), icon: iconName, title: iconName };
  });
  const { project } = useOutletContext<AnalyticPageOutlet>();
  const [createMetricMutation] = useCreateProjectMetricGroupMutation();

  const resetFormData = (params: {
    description?: string;
    name?: string;
    visualization?: 'native' | 'select';
    result_value_type?: 'str' | 'num';
    input?: 'input' | 'select';
  }): HandleMetricForm => {
    const {
      description = '',
      name = '',
      visualization = 'native',
      input = 'input',
      result_value_type = 'str',
    } = params;
    return {
      type: 'tags',
      result_value_type,
      description,
      name,
      visualization,
      settings: {
        input,
        multiselect: false,
        default: {
          type: null,
          value: null,
        },
      },
    };
  };

  const {
    register,
    unregister,
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<HandleMetricForm>({
    ...{
      defaultValues: (isEdit && metricSettings
        ? {
            type: metricSettings.type,
            settings: metricSettings.settings,
            result_value_type: metricSettings.result_value_type,
            name: metricSettings.name,
            description: metricSettings.description,
            visualization: metricSettings.visualization,
          }
        : resetFormData({})) as unknown as HandleMetricForm,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'settings.select',
  });
  const completeFormParameter = `${watch().result_value_type}_${watch().visualization}_${
    watch().settings?.input || ''
  }`;

  function onSuccessMetricHandle(data: HandleMetricForm) {
    if (data.description.length < 3) {
      setError('description', {
        message: 'Поле не заполнено или описание очень короткое',
        type: 'required',
      });
      return;
    }
    project &&
      createMetricMutation({
        params: { project_id: project.project_id },
        body: data as unknown as MetricObjType,
      })
        .unwrap()
        .then(() => {
          toast.success('Метрика добавлена');
          onClose();
        })
        .catch((error) => {
          console.error(error);
          toast.error('Не удалось добавить метрику');
        });
  }
  useEffect(() => {
    if (watch().settings.input !== 'select') {
      unregister('settings.select');
    }
  }, [unregister, watch]);

  function changeAndResetFormHandler(value: MetricTypeFields) {
    const [result_value_type, visualization, input] = value.split('_');
    reset(
      resetFormData({
        name: watch().name,
        description: watch().description,
        result_value_type: result_value_type as 'str' | 'num',
        visualization: visualization as 'native' | 'select',
        input: input as 'input' | 'select',
      }) as HandleMetricForm,
    );
  }

  function recalculateIcons(iconList: Array<string | number>) {
    setValue('settings.default', { value: 1, type: 'value' });
    setValue(
      `settings.select`,
      iconList.map((iconValue, index) => ({
        id: ++index,
        icon: Number(iconValue),
        value: String(iconValue),
      })),
    );
  }
  function changeTextAreaValueHandler(value: string) {
    clearErrors('description');
    setValue('description', value);
  }
  function changeDefaultSettingsHandler({
    defaultValue,
    params,
  }: {
    defaultValue: string | number | null;
    params?: {
      defaultType: 'copy' | 'value' | null;
      copyFrom?: string;
      resultType: 'text' | 'number';
    };
  }) {
    unregister('settings.default');
    reset({ ...watch(), settings: { ...watch().settings, default: { type: null } } });
    defaultValue && setValue('result_value_type', metricResultTypes[params?.resultType || 'str']);
    params?.copyFrom && setValue('settings.default.copy_from', params?.copyFrom);
    setValue(`settings.default.value`, defaultValue || null);
    setValue('settings.default.type', params?.defaultType || null);
  }

  const commonInputRender = (inputType: 'text' | 'number') => (
    <Input
      type={inputType}
      defaultValue={watch().settings?.default?.value || ''}
      {...register('settings.default.value', {
        required: { value: true, message: 'Поле по умолчанию не заполнено' },
      })}
      error={errors?.settings?.default?.value?.message}
      onChange={(event) => {
        changeDefaultSettingsHandler({
          defaultValue: event.target.value,
          params: { defaultType: 'value', resultType: inputType },
        });
      }}
    />
  );

  const renderCheckDefault = (index: number) => (
    <TooltipWrapper content="Выбрать по умолчанию" id={`default_${index}`}>
      <Checkbox
        onChange={() =>
          changeDefaultSettingsHandler({
            defaultValue: index || null,
            params: { defaultType: 'value', resultType: 'text' },
          })
        }
        checked={index === watch().settings?.default?.value}
      />
    </TooltipWrapper>
  );

  const renderAddSelectButton = () => (
    <Button
      label="Добавить поле"
      fill="outlined"
      icon="PlusIcon"
      onClick={() =>
        append({ id: (watch().settings?.select?.length || 0) + 1, value: '', icon: null })
      }
    />
  );

  const renderValueFromMetricSelect = (result_type: 'text' | 'number') => (
    <Select
      placeholder="Значение метрики"
      options={metricList
        .filter((metric) => metric.result_value_type === metricResultTypes[result_type])
        .map((metric) => ({
          value: metric.metric_id,
          title: `${metric.name} (${metric.result_value_type})`,
        }))}
      onChange={(value) =>
        changeDefaultSettingsHandler({
          defaultValue: null,
          params: { defaultType: 'copy', copyFrom: String(value), resultType: result_type },
        })
      }
      defaultValue={watch().settings?.default?.copy_from}
      dropTop
    />
  );

  const renderSelectedTypeFields = {
    str_native_select: () => (
      <div className="w-full flex flex-col gap-y-[10px] py-[10px]">
        <h3 className={titleTagFormType}>Добавить список </h3>
        <div className="inline-flex items-center gap-[10px]">
          <Toggle
            checked={Boolean(watch().settings?.multiselect)}
            onChecked={() => setValue('settings.multiselect', !watch().settings.multiselect)}
            disabled={isEdit}
          />
          Множественный выбор
        </div>
        {fields.map((field, index) => (
          <div key={field.id} className=" w-full inline-flex items-center gap-[10px]">
            <div className="">{renderCheckDefault(index)}</div>
            <div className="flex-1">
              <label className={labelClassName}>Добавить пиктограмму</label>
              <Select
                defaultValue={watch().settings.select?.[index]?.icon || ''}
                placeholder="Без пиктограммы"
                onChange={(value) => setValue(`settings.select.${index}.icon`, Number(value))}
                options={iconsOptionList}
              />
            </div>
            <div className="flex-1">
              <label className={labelClassName}>Добавить значение</label>
              <Input
                {...register(`settings.select.${index}.value`)}
                defaultValue={watch().settings.select?.[index].value || ''}
              />
            </div>
            <div>
              <TooltipWrapper content="Удалить значение" id={`remove_select_${index}`}>
                <CircleButton
                  icon="TrashIcon"
                  size={20}
                  disable={isEdit}
                  className="text-basic_red"
                  onClick={() => !isEdit && remove(index)}
                />
              </TooltipWrapper>
            </div>
          </div>
        ))}
        <div>
          <div className="w-full flex-1 justify-end">{renderAddSelectButton()}</div>
        </div>
      </div>
    ),
    num_native_input: () => (
      <div className={loadFormClassName}>
        <h3 className={titleTagFormType}>Число по умолчанию</h3>
        <div className="inline-flex gap-x-[10px] items-center">
          <div className="flex-1">{commonInputRender('number')}</div>
          <div>или</div>
          <div className="flex-1">
            <div>{renderValueFromMetricSelect('number')}</div>
          </div>
        </div>
      </div>
    ),
    str_native_input: () => (
      <div className={loadFormClassName}>
        <h3 className={titleTagFormType}>Строка по умолчанию</h3>
        <div className="inline-flex items-center gap-x-[10px]">
          <div className="flex-1">{commonInputRender('text')}</div>
          <div>или</div>
          <div className="flex-1">{renderValueFromMetricSelect('text')}</div>
        </div>
      </div>
    ),
    str_icons_select: () => (
      <div className="w-full flex flex-col gap-y-[10px] py-[10px]">
        <h3 className={titleTagFormType}>Добавить пиктограммы</h3>
        <div className="flex flex-wrap gap-[10px]">
          <Select
            defaultValue={
              watch().settings?.select?.map((iconOption) => Number(iconOption.value)) || []
            }
            onChangeMultiple={(values) => recalculateIcons(values)}
            options={iconsOptionList}
            isMulti
          />
        </div>
      </div>
    ),
  };

  console.log('watch', watch());
  return (
    <form className="pb-[20px]" onSubmit={handleSubmit(onSuccessMetricHandle)}>
      <div className="min-h-[250px] pb-[80px] relative overflow-y-scroll">
        <div className="">
          <div className="flex w-full pb-[5px] gap-[10px]">
            <div className="basis-1/2">
              <label className={labelClassName}>Название метрики</label>
              <Input
                {...register('name', {
                  required: { value: true, message: 'Поле не заполнено' },
                  pattern: {
                    value: /^[\d\s\w а-яй А-ЯЙ]+$/,
                    message: 'Неверный формат, символы запрещены',
                  },
                })}
                error={errors.name?.message}
              />
            </div>
            <div className="basis-1/2">
              <label className={labelClassName}>Тип метрики</label>
              <Select
                options={Object.entries(metricTypeFields).map(([value, title]) => ({
                  title,
                  value,
                }))}
                defaultValue={`${watch().result_value_type}_${watch().visualization}_${
                  watch().settings?.input
                }`}
                onChange={(value) => changeAndResetFormHandler(value as MetricTypeFields)}
              ></Select>
            </div>
          </div>
          <div className="flex-1 mt-[10px]">
            <label className={labelClassName}>Описание</label>
            <TextArea
              height={100}
              error={errors?.description?.message}
              defaultValue={watch().description}
              onChange={(event) => changeTextAreaValueHandler(event.target.value)}
            />
          </div>
          <div className="flex-1">
            <div className="w-full">
              {watch().settings?.input && renderSelectedTypeFields[completeFormParameter]()}
            </div>
          </div>
        </div>
      </div>
      <div className="absolute w-full left-0  bottom-[0px] h-[60px] rounded-b-defaultR bg-white">
        <div className="w-full h-full items-center flex px-[20px] gap-[20px] justify-end">
          <Button label="Отмена" fill="outlined" onClick={onClose} />
          <Button label="Добавить метрику" type="submit" />
        </div>
      </div>
    </form>
  );
};

export default HandleMetricForm;

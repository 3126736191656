import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { v4 } from 'uuid';

import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';

import { Button, DropMenu, Empty, Skeleton, SkeletonPageLoading } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { DropMenuItem } from '@app/components/ui/drop-menu/drop-menu.type';
import { ReportChart, ReportListChart, ReportTimeRange } from '@app/interfaces/report.type';
import useChartDateIntervals from '@app/hooks/use-chart-date-intervals';
import { SelectUniversalFilter } from '@app/components/select-universal-filter';
import { GroupSharedStatus } from '@app/interfaces/analytics';
import { FilterItem } from '@app/interfaces/filter';

import { useLazyGetGraphDataQuery } from '@app/store/api/graph-data.api';

import { GridLayoutChartsContainer } from '@app/components/grid-layout-charts-container';

import { useGetLiveReportSettingsQuery } from '@app/store/api/live-reports.api';

import {
  liveReportsChartsApi,
  useGetLiveReportsGraphListQuery,
} from '@app/store/api/live-reports-charts.api';

import { useGetProjectMetricsListQuery } from '@app/store/api/metrics.api';

import { ChartsLayoutType } from '@app/interfaces/dashboards.type';

import { useAppDispatch } from '@app/store/store';

import ChartDatePickerModalBlock from './chart-date-picker-modal.block';
import ModalSettingsChartBlock from './chart-modal/modal-settings-chart.block';

const ChartListBlock: FC<{ live_report_id: string }> = () => {
  const { id } = useParams();
  const customDateTrigger = 'customDate';
  const { t } = useTranslation('pages.chartPage');
  const dispatch = useAppDispatch();
  // api
  const { currentData: liveReport } = useGetLiveReportSettingsQuery(
    { id: id || '' },
    { skip: !id },
  );
  const { data: metricList, isLoading: metricLoading } = useGetProjectMetricsListQuery(
    {
      project_id: liveReport?.project.project_id || '',
    },
    { skip: !liveReport?.project.project_id },
  );
  const { data: charts, isLoading: isPending } = useGetLiveReportsGraphListQuery(id || '', {
    skip: !id,
  });
  const [getGraphData] = useLazyGetGraphDataQuery();
  // useState
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [layoutSettings, changeLayoutSettings] = useState<ChartsLayoutType[]>([]);
  const [flagForSendChartLayoutRequestChange, changeFlagForSendChartLayoutRequestChange] =
    useState<boolean>(false);
  const [editReportChartsState, changeEditReportChartsState] = useState<boolean>(false);
  const [isShowModelDatePicker, changeShowingModelDatePicker] = useState<boolean>(false);
  const [showEditChartModal, changeShowOpenChartModel] = useState<boolean>(false);
  const [commonRange, setCommonRange] = useState<ReportTimeRange | null>(null);
  const [chartList, changeChartList] = useState<(ReportListChart & ReportChart)[]>();
  const [filterValue, changeFilterValue] = useState<FilterItem[]>();
  const { daysSelectOptions, dateIntervals, intervalTimeBuilder } = useChartDateIntervals();
  useEffect(() => {
    changeChartList(charts);
  }, [charts]);

  function handlerDateDropTrigger(key: string | number) {
    if (!chartList) return;
    if (key === customDateTrigger) {
      changeShowingModelDatePicker(true);
      return;
    }
    setLocalLoading(true);
    for (const chart of chartList) {
      getGraphData({
        id: chart.graph_id,
        settings: {
          range: dateIntervals[key],
          filter: filterValue,
        },
      }).then((Cdata) => {
        const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
        const layout = layoutSettings.find((layout) => layout.i === chartData.graph_id);
        onEditIntervalWithoutSave &&
          onEditIntervalWithoutSave({ id: chartData.graph_id, data: chartData, layout });
      });
    }
    setCommonRange(dateIntervals[key]);
    setTimeout(() => setLocalLoading(false), 500);
  }
  const onEditIntervalWithoutSave = useCallback(
    ({
      data,
      id: chartId,
      layout,
    }: {
      data: ReportChart;
      id: string;
      layout?: ChartsLayoutType;
    }) => {
      if (!layout) return;
      dispatch(
        liveReportsChartsApi.util?.updateQueryData('getLiveReportsGraphList', id, (draft) => {
          return draft?.map((item) => {
            if (item.graph_id === chartId) {
              item = {
                ...item,
                categories: data.categories,
                series: data.series,
                range: data.range,
                layout: layout,
              };
            }
            return item;
          });
        }),
      );
    },
    [dispatch, id],
  );

  function handlerChangeCustomRangeInterval({
    start,
    end,
  }: {
    start: string | null | Date;
    end: string | null | Date;
  }) {
    if (!chartList) return;
    const range = intervalTimeBuilder(start, end) as unknown as ReportTimeRange;
    for (const chart of chartList) {
      getGraphData({
        id: chart.graph_id,
        settings: {
          // width: chart.build.width,
          range,
          filter: filterValue,
        },
      }).then((Cdata) => {
        const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
        const layout = layoutSettings.find((layout) => layout.i === chartData.graph_id);
        onEditIntervalWithoutSave &&
          onEditIntervalWithoutSave({ id: chartData.graph_id, data: chartData, layout });
      });
    }
  }
  // const filteredCharts =
  //   (chartList && chartList?.filter((graph) => graph.live_report_id === live_report_id)) || [];
  const renderLoadingChartSkeletons = (
    <div className="flex flex-col gap-[10px]">
      {Array(3)
        .fill('')
        .map((_, index) => (
          <div key={index} className="flex flex-nowrap gap-[10px] w-full">
            <Skeleton height={400} count={2} />
          </div>
        ))}
    </div>
  );

  const renderCharts = useMemo(
    () =>
      chartList?.length ? (
        <GridLayoutChartsContainer
          flagForSendChartLayoutRequestChange={flagForSendChartLayoutRequestChange}
          layoutSettings={layoutSettings}
          changeLayoutSettings={changeLayoutSettings}
          onEditIntervalWithoutSave={onEditIntervalWithoutSave}
          viewOnly={
            !liveReport?.project?.owner && liveReport?.project?.shared === GroupSharedStatus.VIEW
          }
          commonRange={commonRange}
          charts={chartList}
          isDraggable={editReportChartsState}
          filterValue={filterValue}
          currentDashboardOrReportId={liveReport?.live_report_id}
        />
      ) : (
        <Empty title={t('empty')} />
      ),
    [
      chartList,
      commonRange,
      editReportChartsState,
      filterValue,
      flagForSendChartLayoutRequestChange,
      layoutSettings,
      liveReport?.live_report_id,
      liveReport?.project?.owner,
      liveReport?.project?.shared,
      onEditIntervalWithoutSave,
      t,
    ],
  );

  function onChangeFilterHandler(filter: FilterItem[]) {
    if (!chartList) return;
    changeFilterValue(filter);
    for (const chart of chartList) {
      getGraphData({
        id: chart.graph_id,
        settings: {
          range: chart.range,
          filter: filter,
        },
      }).then((Cdata) => {
        const { data: chartData } = Cdata.data as unknown as { data: ReportChart };
        const layout = layoutSettings.find((layout) => layout.i === chartData.graph_id);
        onEditIntervalWithoutSave &&
          onEditIntervalWithoutSave({ id: chartData.graph_id, data: chartData, layout });
      });
    }
  }
  const METRIC_CONFIG = { fontSize: '15px', color: '#000', fontWeight: '400', lineHeight: '18px' };
  const resultItemsForSelectUniversalFilter =
    metricList?.map((item) => ({
      ...item,
      front_id: v4(),
      style: METRIC_CONFIG,
    })) || [];
  if (metricLoading || isPending) return <SkeletonPageLoading />;
  return (
    <div>
      <div>
        <div className=" w-full inline-flex justify-between min-h items-center gap-[37px]">
          <SelectUniversalFilter
            onChangeReturnFilter={(filter) => onChangeFilterHandler(filter)}
            results={resultItemsForSelectUniversalFilter}
          />
          <div />
          <div className="flex  items-center relative z-10 my-[10px]">
            <Button
              icon="CirclePlusIcon"
              disabled={
                showEditChartModal ||
                (!liveReport?.project?.owner &&
                  liveReport?.project?.shared === GroupSharedStatus.VIEW)
              }
              label={t('popover.add_diagram')}
              onClick={() => changeShowOpenChartModel(true)}
            />
            <div className="ml-[13px]">
              <DropMenu
                dropSize={140}
                hintTitle={t('drop_menu.intervals')}
                onClick={(key) => handlerDateDropTrigger(key)}
                menu={daysSelectOptions as unknown as Array<DropMenuItem>}
              >
                <Button icon="CalendarEmptyIcon" />
              </DropMenu>
            </div>
            {(liveReport?.project?.owner ||
              liveReport?.project?.shared !== GroupSharedStatus.VIEW) && (
              <div className="ml-[13px]">
                <Button
                  onClick={() => {
                    editReportChartsState
                      ? changeFlagForSendChartLayoutRequestChange(true)
                      : changeFlagForSendChartLayoutRequestChange(false);
                    changeEditReportChartsState((prev) => !prev);
                  }}
                  hintTitle={t('popup_hints.grid_layout_settings')}
                  icon="SettingIcon"
                  fill={'outlined'}
                  variant={editReportChartsState ? 'danger' : 'primary'}
                />
              </div>
            )}
          </div>
        </div>
        <div className="w-full">
          {isPending || localLoading ? renderLoadingChartSkeletons : renderCharts}
        </div>
      </div>
      <ModalSettingsChartBlock isOpen={showEditChartModal} onClose={changeShowOpenChartModel} />
      <ChartDatePickerModalBlock
        dataIdStartDate="reports-all-charts-interval-date-from"
        dataIdEndDate="reports-all-charts-interval-date-to"
        onSuccess={handlerChangeCustomRangeInterval}
        show={isShowModelDatePicker}
        onClose={changeShowingModelDatePicker.bind(null, false)}
      />
    </div>
  );
};

export default ChartListBlock;

import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';

import { Checkbox, EasyModal, Paginator, PermissionAccessWrapper, Skeleton } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import {
  useDeleteRecordsMutation,
  useGetRecordsQuery,
  useLazyDownloadRecordQuery,
} from '@app/store/api/records.api';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import { FilterTableData, TableItem, TableTitle } from '@app/components/table/table.type';
import { navigationRoutes } from '@app/utils/navigation-routes';
import TimeDuration from '@app/pages/analytics/project-records/blocks/time-duration';
import { DownloadCloudIcon, Oscilogram, TrashIcon } from '@icons';
import { userPermissions } from '@app/utils/user-permissions';

import { GetFilterParamsNew } from '@app/components/ui/easy-filter/types';

import { useGetAliasesListQuery } from '@app/store/api/alias-settings-page.api';

import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';

import { useAppSelector } from '@app/store/store';

import { ChartsLayoutType } from '@app/interfaces/dashboards.type';

import Table from '@app/components/table';

import { tableUtilIconsClassName } from './records-table.styles';

type RecordsTableBlockPropsType = {
  recordsParams: GetFilterParamsNew;
  getRecordsParams: React.Dispatch<React.SetStateAction<GetFilterParamsNew>>;
  changeSelectedRecords?: React.Dispatch<React.SetStateAction<string[]>>;
  selectedRecords?: string[];
  columnSize?: number;
};
const ALL_RECORDS_FAKE_UUID = '722078c7-f8ee-4568-b7af-f92ad6e8633c';
const RecordsTableBlock: FC<RecordsTableBlockPropsType> = (props) => {
  const { recordsParams, getRecordsParams, selectedRecords, changeSelectedRecords, columnSize } =
    props;
  const { t } = useTranslation('pages.allRecords');
  const pageOptionsData = [
    { title: '10 ' + t('system.calls'), value: 10 },
    { title: '15 ' + t('system.calls'), value: 15 },
    { title: '25 ' + t('system.calls'), value: 25 },
    { title: '50 ' + t('system.calls'), value: 50 },
  ];
  const { data: recordsList, isLoading } = useGetRecordsQuery(recordsParams);
  const [deleteRecordRequest] = useDeleteRecordsMutation();
  const { data } = useGetAliasesListQuery();
  const [downloadRecord] = useLazyDownloadRecordQuery();
  const [updateUserSettings, { isLoading: userLoading }] = useUpdateUserSettingsMutation();
  const { userSettings, isLoading: userSettingsUpdateLoading } = useAppSelector(
    (state) => state.userSettings,
  );
  const [userSettingsLoading, setUserSettingsLoading] = useState(false);
  const [recordForDelete, changeRecordForDelete] = useState<string[]>([]);
  useEffect(() => {
    if (userLoading) {
      setUserSettingsLoading(true);
    }
    if (!userLoading) {
      setTimeout(() => setUserSettingsLoading(false), 300);
    }
  }, [userLoading]);
  const checkingAliasForSorting = (alias_id: string) => {
    const alias = data?.aliases.find((alias) => alias.alias_id === alias_id);
    if (alias) {
      return alias.type_meta !== 'channel';
    }
    return false;
  };
  const tableTitles: TableTitle[] = recordsList?.headers.length
    ? [
        {
          index: 'duration_records_items',
          title: (
            <div className="flex items-center">
              <>
                <div className="mr-[10px]">
                  <Checkbox
                    touched={(selectedRecords?.length as number) >= 1}
                    checked={selectedRecords?.length === recordsParams.limit}
                    onChange={() => onSelectAllRecords()}
                  />
                </div>
                <span>{t('table.first_column')}</span>
              </>
            </div>
          ),
          size: 160,
        },
        ...recordsList.headers.map((column) => ({
          index: column.alias_id,
          title: column.name,
          size: columnSize,
          filter: checkingAliasForSorting(column.alias_id),
        })),
        {
          index: 'table_record_details',
          title: t('table.last_column'),
          size: 140,
        },
      ]
    : [];
  const tableData: TableItem[] =
    recordsList?.records.reduce<TableItem[]>((dataTable, record) => {
      const cell: TableItem = {};
      cell['duration_records_items'] = (
        <div
          className="truncate w-full flex items-center justify-center"
          data-id={record.record_id}
        >
          <div className="!mr-[10px]">
            <Checkbox
              checked={selectedRecords?.includes(record.record_id)}
              onChange={() => onSelectRecord(record.record_id)}
            />
          </div>
          <div className="flex cursor-pointer">
            <TimeDuration
              link={`/${navigationRoutes.records}/${record.record_id}`}
              duration={record.duration}
            />
          </div>
        </div>
      );
      cell['table_record_details'] = (
        <div className="flex items-center w-[110px] gap-[15px] justify-center">
          <DownloadCloudIcon
            hintTitle={t('popup_hints.download_record')}
            size={18}
            className={tableUtilIconsClassName}
            onClick={() => downloadAudioHandler({ record_id: record.record_id })}
          />
          <Oscilogram
            hintTitle={t('popup_hints.to_record_title')}
            size={22}
            className={cn(tableUtilIconsClassName, 'text-bold')}
            onClick={() =>
              window.open(`/${navigationRoutes.records}/${record.record_id}`, '_blank')
            }
          />
          <PermissionAccessWrapper
            permissions={'action'}
            tag={userPermissions.action.actionDeleteRecord}
          >
            <TrashIcon
              hintTitle={t('popup_hints.delete_icon')}
              size={18}
              className={cn(tableUtilIconsClassName, '!text-basic_red')}
              onClick={() => changeRecordForDelete([record.record_id])}
            />
          </PermissionAccessWrapper>
        </div>
      );
      for (const tableCell of record.data) {
        cell[tableCell.alias_id] = tableCell.value;
      }
      dataTable.push(cell);
      return dataTable;
    }, []) || [];

  function onSelectAllRecords() {
    if (!recordsList) return;
    const notImportantArray: string[] = [];
    if (selectedRecords?.length !== recordsParams.limit) {
      for (const record of recordsList.records) {
        notImportantArray.push(record.record_id);
      }
    }
    changeSelectedRecords?.(notImportantArray);
  }
  function onSelectRecord(record_id: string) {
    changeSelectedRecords?.((prev) =>
      prev.includes(record_id) ? prev.filter((id) => id !== record_id) : [...prev, record_id],
    );
  }

  function paginatorChangePageHandler(paginationData: PaginationResponse) {
    getRecordsParams((prevState) => ({ ...prevState, ...paginationData }));
    updateUserSettings({
      ...userSettings,
      tablesLimit: { ...userSettings?.tablesLimit, allRecords: paginationData.limit },
    });
  }
  function tableChangeFilterHandler(tableFilter: FilterTableData | undefined) {
    getRecordsParams((prevState) => ({ ...prevState, ...tableFilter }));
  }

  async function downloadAudioHandler(params: { record_id: string }) {
    downloadRecord(params);
  }
  function removeRecords() {
    deleteRecordRequest({ items: recordForDelete }).then(() => {
      changeRecordForDelete([]);
    });
  }

  const dateTimeAlias = data?.aliases.filter((item) => item.type_value === 'datetime');
  function onChangeLayoutHandler(layout: ChartsLayoutType[]) {
    const currentChangedLayout = [
      { id: ALL_RECORDS_FAKE_UUID, layout: layout.map((item) => ({ ...item, maxW: 24 })) },
    ];
    // changeLayout(currentChangedLayout?.[0].layout);
    updateUserSettings({
      ...userSettings,
      tableColsSize: {
        ...userSettings?.tableColsSize,
        allRecords: currentChangedLayout,
      },
    });
  }
  const LoadingSkeleton = (
    <div className={'flex flex-col gap-[10px] pb-2'}>
      <Skeleton height={400} className={'mt-6'} />
      <Skeleton height={50} />
    </div>
  );
  const [layout, changeLayout] = useState(userSettings?.tableColsSize?.allRecords?.[0]?.layout);
  useEffect(() => {
    changeLayout(
      userSettings?.tableColsSize?.allRecords?.[0]?.layout.map((item) => ({
        ...item,
        maxW: 16,
      })),
    );
  }, [userSettings?.tableColsSize?.allRecords]);
  if (isLoading || !userSettings || userSettingsUpdateLoading) return LoadingSkeleton;
  return (
    <div className="relative">
      <div className="flex flex-col gap-[10px] pb-2">
        {userSettingsLoading && <Skeleton height={800} className={'absolute z-[999] top-0 mt-6'} />}
        <Table
          columns={tableTitles}
          dataSource={tableData}
          layout={layout}
          onFilter={tableChangeFilterHandler}
          allRec
          onLayoutChange={onChangeLayoutHandler}
        />
        <Paginator
          limit={recordsParams.limit}
          page={Number(recordsParams.offset) + 1}
          count={Number(recordsList?.total)}
          onChange={paginatorChangePageHandler}
          pageOptions={pageOptionsData}
        />
      </div>
      <EasyModal
        show={Boolean(recordForDelete.length)}
        onClose={changeRecordForDelete.bind(null, [])}
        label={
          <div className="flex items-center justify-center flex-col">
            <div>{t('record_delete_modal_label')}</div>
          </div>
        }
        onRemove={removeRecords}
        variant="removal"
        withoutFooter
      >
        <div className="pointer-events-none flex flex-col justify-center items-center">
          <div>
            {recordsList?.records
              ?.find((record) => record.record_id === recordForDelete[0])
              ?.data.find(
                (recordDataItem) => recordDataItem.alias_id === dateTimeAlias?.[0].alias_id,
              )?.value || ''}
          </div>
          <div>{recordForDelete[0]}</div>
        </div>
      </EasyModal>
    </div>
  );
};

export default RecordsTableBlock;

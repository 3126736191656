import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import AccordionUI from '@app/components/ui/Accordion/Aссordion';
import AccordionItemUI from '@app/components/ui/Accordion/AccordionItem';
import { AccordionItemUIPropsClickChildParams } from '@app/interfaces/userInterface';
import { Button, EditText, PageHeader } from '@app/components/ui';
import { navigationRoutes } from '@app/utils/navigation-routes';
import useTranslation from '@app/hooks/use-translation';
import { ColsWithoutTable } from '@app/components/rubber-table/blocks/cols-without-table';

import {
  useCreatePresetTemplateGroupMutation,
  useGetPresetTemplateGroupsListQuery,
} from '@app/store/api/preset-template.api';

import { useAppSelector } from '@app/store/store';

import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';

import { AccordionTitle } from './blocks/accordionTitle';
import { Container } from './blocks/container';
import PresetsPageSkeleton from './blocks/presets-page.skeleton';

export const PresetsPage: React.FC = () => {
  const [edit, setEdit] = React.useState(false);
  const [groupName, setNewGroupName] = React.useState('');
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('pages.presets');
  // api
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const { data: groups, isLoading: loading } = useGetPresetTemplateGroupsListQuery();
  const [createPresetGroup] = useCreatePresetTemplateGroupMutation();
  // handlers

  const createNewGroup = (name: string) => {
    setNewGroupName(name);
    if (name !== '') {
      createPresetGroup({ name }).then(() => {
        setNewGroupName('');
        setEdit(false);
      });
    } else if (groupName === '') {
      setEdit(false);
      toast.error(t('cant_create_group'));
    }
  };

  const [activeAccordionItem, setActiveAccordionItem] = React.useState<Array<string | undefined>>(
    userSettings?.accordions?.presetTemplates || [],
  );

  useEffect(() => {
    const accordionListFromStorage = userSettings?.accordions?.presetTemplates;
    if (!accordionListFromStorage?.length) {
      const defaultGroup = groups?.find((group) => group.is_default === true)?.preset_group_id;
      setActiveAccordionItem([defaultGroup]);
      defaultGroup &&
        updateUserSettings({
          ...userSettings,
          accordions: {
            ...userSettings?.accordions,
            presetTemplates: [defaultGroup],
          },
        });
    }
  }, [groups, updateUserSettings, userSettings]);
  useEffect(() => {
    setActiveAccordionItem(userSettings?.accordions?.presetTemplates || []);
  }, [userSettings?.accordions?.presetTemplates]);
  const clickAccordionHeaderBtn = (event: AccordionItemUIPropsClickChildParams) => {
    setActiveAccordionItem((prev) => {
      if (!prev.includes(event.id)) {
        userSettings?.accordions?.presetTemplates &&
          updateUserSettings({
            ...userSettings,
            accordions: {
              ...userSettings?.accordions,
              presetTemplates: [...(userSettings?.accordions?.presetTemplates || []), event.id],
            },
          });
        return [...prev, event.id];
      } else {
        const accordionListFromStorage = userSettings?.accordions?.presetTemplates;
        const filteredAccordionListFromStorage = accordionListFromStorage?.filter(
          (item) => item !== event.id,
        ) as string[];
        updateUserSettings({
          ...userSettings,
          accordions: {
            ...userSettings?.accordions,
            presetTemplates: filteredAccordionListFromStorage,
          },
        });

        return prev.filter((item) => item !== event.id);
      }
    });
  };

  function createNewPreset() {
    navigate(`/${navigationRoutes.presets}/new`);
  }
  const columns = [
    {
      title: t('all_preset_templates_table.name'),
      index: 'name',
      className: 'ml-[15px]',
    },
    {
      title: t('all_preset_templates_table.time'),
      index: 'time',
    },
    {
      title: t('all_preset_templates_table.desc'),
      index: 'filter',
    },
    {
      title: t('all_preset_templates_table.actions'),
      index: 'utils',
      maxWidth: 150,
    },
  ];
  if (loading) return <PresetsPageSkeleton />;
  return (
    <>
      <PageHeader icon="MenuSmallIcon" label={t('presets_templates')}>
        <Button
          data-id="new-preset-template"
          icon="CirclePlusIcon"
          label={t('create_preset_template')}
          onClick={() => createNewPreset()}
        />
      </PageHeader>
      <div>
        <ColsWithoutTable columns={columns} />
        <div className={'flex flex-col gap-[20px]'}>
          <AccordionUI>
            {groups?.map((group) => {
              return (
                <AccordionItemUI
                  variant="filled"
                  dataId={`preset-group-${group.name}`}
                  key={group.preset_group_id}
                  id={group.preset_group_id}
                  disabled={
                    activeAccordionItem.length === 1
                      ? activeAccordionItem[0] === group.preset_group_id
                      : false
                  }
                  title={
                    <AccordionTitle
                      setActiveAccordionItem={setActiveAccordionItem}
                      name={group.name}
                      isDefault={group.is_default}
                      active={activeAccordionItem?.includes(group.preset_group_id)}
                      presetsCount={group.presets.length}
                      preset_group_id={group.preset_group_id}
                    />
                  }
                  clickBtnChild={clickAccordionHeaderBtn}
                  active={activeAccordionItem?.includes(group.preset_group_id)}
                >
                  {activeAccordionItem?.includes(group.preset_group_id) && (
                    <Container groupName={group.name} group={group.presets} />
                  )}
                </AccordionItemUI>
              );
            })}
            {edit && (
              <AccordionItemUI
                variant="filled"
                title={
                  <div className="max-w-[240px]">
                    <EditText
                      textStyle="font-[700] text-[13px] text-2color leading-[14px]"
                      editIconSize={14}
                      text={groupName}
                      onEdited={createNewGroup}
                      dataId="new-preset-group-name"
                      dataIdSubmit="new-preset-group-name-submit"
                      defaultOpen
                    />
                  </div>
                }
                id={''}
              >
                {''}
              </AccordionItemUI>
            )}
          </AccordionUI>

          <div className="">
            <Button
              data-id="new-group"
              label={t('new_group')}
              icon="CirclePlusIcon"
              onClick={() => setEdit(!edit)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import ReportRecordsTableBlock from '@app/pages/reports/blocks/report-records-table.block';
import useTranslation from '@app/hooks/use-translation';

import { useGetLiveReportSettingsQuery } from '@app/store/api/live-reports.api';

import { EasyFilterView, SkeletonPageLoading } from '@ui';

import { Filter, Range } from '@app/components/ui/easy-filter/types';

import { useGetProjectMetricsListQuery } from '@app/store/api/metrics.api';

import ReportHeaderWithStatusBlock from './blocks/report-header-with-status.block';
import ReportNavigatorBlock from './blocks/report-navigator.block';
const emptyFilter: Filter & Range = {
  range: {
    type: 'n',
    parameters: [],
  },
  filter: [],
};
const ReportRecordsPage: FC = () => {
  const { t } = useTranslation('pages.reports');
  const { id } = useParams();
  const { currentData: liveReport, isLoading: liveReportLoading } = useGetLiveReportSettingsQuery(
    { id: id || '' },
    { skip: !id },
  );
  const { data: metricList, isLoading: projectMetricLoading } = useGetProjectMetricsListQuery(
    {
      project_id: liveReport?.project.project_id || '',
    },
    { skip: !liveReport?.project.project_id },
  );

  const [localFilter, setLocalFilter] = useState<Filter & Range>(emptyFilter);

  function handleFilterLiveReportRecords(newFilter: Filter & Range) {
    setLocalFilter(newFilter);
  }
  function getFilteringKeys() {
    return metricList?.map((metric) => ({
      id: metric.metric_id,
      title: metric.name,
      type_value: metric.result_value_type,
    }));
  }

  if (liveReportLoading) return <SkeletonPageLoading />;

  return (
    <div>
      <ReportHeaderWithStatusBlock />
      <ReportNavigatorBlock />
      <div>
        <EasyFilterView
          dataIdStartDate="reports-records-date-from"
          dataIdEndDate="reports-records-date-to"
          data={localFilter}
          isLoading={projectMetricLoading}
          titleMarkup={t('filter_title')}
          onChangeHandler={handleFilterLiveReportRecords}
          keysForFiltering={getFilteringKeys() || []}
        />
      </div>
      <div className="mt-[20px]">
        <ReportRecordsTableBlock reportId={liveReport?.live_report_id || ''} filter={localFilter} />
      </div>
    </div>
  );
};

export default ReportRecordsPage;

import apiInstance from './common.api';

export const getAuth = async (data: FormData) => {
  return await apiInstance.post('/api/auth/login', data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export const getAuthRefresh = async (refresh_token: string) => {
  return await apiInstance.get('/api/auth/refresh', {
    headers: {
      Authorization: `Bearer ${refresh_token}`,
    },
  });
};

export const getUserInfo = async () => {
  return await apiInstance.get('/api/front/me');
};

import React from 'react';
import { Route } from 'react-router-dom';

import { navigationRoutes } from '@app/utils/navigation-routes';
import { BreadcrumbsItem } from '@app/layouts/layout';
import RequiredAuth from '@app/routes/required-auth.route';
import { RecordsPage, SingleRecordPage } from '@app/pages';

export const recordsRoute = (
  <Route
    path={navigationRoutes.records}
    handle={{
      crumb: () => <BreadcrumbsItem path={navigationRoutes.records} crumb="records" />,
    }}
  >
    <Route
      index
      element={
        <RequiredAuth>
          <RecordsPage />
        </RequiredAuth>
      }
    />
    <Route
      path={`:id`}
      element={
        <RequiredAuth>
          <SingleRecordPage />
        </RequiredAuth>
      }
      handle={{
        crumb: () => <BreadcrumbsItem path={navigationRoutes.records} crumb="recordsId" />,
      }}
    />
  </Route>
);

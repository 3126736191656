import React, { FC, Fragment, useEffect } from 'react';
import { Input } from '@ui';
import { FieldValues, useFieldArray, UseFieldArrayAppend, useFormContext } from 'react-hook-form';
import useTranslation from '@app/hooks/use-translation';
import { KeysForFilteringType } from '@app/components/ui/easy-filter/types';
import { UniversalFilterConstants } from '@app/hooks/use-filter-data';
type NestedFieldPropsType = {
  nestIndex: number;
  appendFilter: UseFieldArrayAppend<FieldValues, 'filter'>;
  fieldsLength: number;
  getConditionOptionsWhenMoreThatOneValue(): { title: string; value: string }[];
  getConditionsSelectOptions(): { title: string; value: string }[];
  keysForFiltering: KeysForFilteringType[];
};
const NestedField: FC<NestedFieldPropsType> = ({
  nestIndex,
  appendFilter,
  getConditionOptionsWhenMoreThatOneValue,
  fieldsLength,
  // getConditionsSelectOptions,
  keysForFiltering,
}) => {
  const { t } = useTranslation('components.filter');
  const { conditionsWithoutValue } = UniversalFilterConstants();
  const { control, watch, setValue } = useFormContext();
  const { fields, remove, append } = useFieldArray({
    control,
    name: `filter[${nestIndex}].value`,
  });
  useEffect(() => {
    fields.length === 0 && append('');
  }, [append, fields, watch]);
  function onInputChange(event, index) {
    const valueType = keysForFiltering.find(
      (item) => item.id === watch(`filter[${nestIndex}].id`),
    )?.type_value;
    const value =
      valueType === 'num' ? event.target.value.replace(/[^\d.,]+/g, '') : event.target.value;
    if (!watch(`filter[${nestIndex}].value[${index}]`) && value && fieldsLength === nestIndex + 1) {
      appendFilter('');
    }
    setValue(`filter[${nestIndex}].value[${index}]`, value);
  }

  function onAppendNestedField() {
    append('');
    watch(`filter[${nestIndex}].value`).length === 2 &&
      setValue(
        `filter[${nestIndex}].condition`,
        getConditionOptionsWhenMoreThatOneValue()[0].value,
      );
  }
  function onDeleteNestedField(fieldIndex: number) {
    remove(fieldIndex);
    // watch(`filter[${nestIndex}].value`).length <= 1 &&
    //   setValue(`filter[${nestIndex}].condition`, getConditionsSelectOptions()[0].value);
  }
  // const inputIconAccept = /^[0-9]$/gim.test(watch(`filter[${nestIndex}].value`)[0]);
  // console.log(!conditionIdsForOnlyOneValueMetric.includes(watch(`filter[${nestIndex}].condition`)));
  // console.log(inputIconAccept, 'reg');
  // console.log(watch(`filter[${nestIndex}].value`)[0]);
  return (
    <div className={'flex flex-col items-center '}>
      {fields.map((item, fieldsIndex) => {
        return (
          <Fragment key={item.id}>
            {fieldsIndex !== 0 && (
              <div
                className={
                  'h-[17px] w-full flex items-center justify-center text-[14px] leading-[17px] font-[400] text-4color'
                }
              >
                {t('or')}
              </div>
            )}
            <div className={'w-[430px] transition-all cursor-pointer '}>
              {conditionsWithoutValue.includes(watch(`filter[${nestIndex}].condition`)) ? (
                <div className="relative flex h-full w-full items-center justify-center">
                  <div className="w-[10px] mt-[15px] border-3color border-[1px]  border-solid"></div>
                </div>
              ) : (
                <Input
                  defaultValue={watch(`filter[${nestIndex}].value[${fieldsIndex}]`)}
                  onChange={(value) => onInputChange(value, fieldsIndex)}
                  onClick={() =>
                    fieldsIndex !== fields.length - 1
                      ? onDeleteNestedField(fieldsIndex)
                      : onAppendNestedField()
                  }
                  iconColor={'primary'}
                  {...{ icon: fieldsIndex !== fields.length - 1 ? 'MinusIcon' : 'CirclePlusIcon' }}
                />
              )}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default NestedField;

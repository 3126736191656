import React from 'react';
import { EditText, PageHeader, SharedStatus, TooltipWrapper } from '@ui';
import { useParams } from 'react-router-dom';
import { StatusLabel } from '@app/components';
import { navigationRoutes } from '@app/utils/navigation-routes';
import useTranslation from '@app/hooks/use-translation';
import connector from '@app/utils/store';
import { StatusVariant } from '@app/components/status-label/status-label';
import { reportStatusPropertiesData } from '@app/pages/reports/data/report-status-properties.data';
import { GroupSharedStatus } from '@app/interfaces/analytics';
import { useLocation, useNavigate } from 'react-router';
import {
  useEditLiveReportMutation,
  useEditLiveReportStatusMutation,
  useGetLiveReportSettingsQuery,
} from '@app/store/api/live-reports.api';

const ReportHeaderWithStatusBlock = () => {
  const status: StatusVariant = 'LIFE';
  const navigate = useNavigate();
  const history = useLocation();
  const { t } = useTranslation('pages.chartPage');
  const { id } = useParams();
  const { currentData: liveReport } = useGetLiveReportSettingsQuery(
    { id: id || '' },
    { skip: !id },
  );
  const [editLiveReport] = useEditLiveReportMutation();
  const [editLiveReportStatus] = useEditLiveReportStatusMutation();

  function handleChangeStatus(new_status) {
    if (!liveReport) return;
    editLiveReportStatus({ id: liveReport?.live_report_id, new_status });
  }

  function handleChangeReportName(name: string) {
    if (!liveReport) return;
    editLiveReport({
      params: { id: liveReport?.live_report_id },
      body: { name, filter: liveReport?.filter },
    });
  }
  // if (liveReportLoading) return <h1>loading</h1>;
  return (
    <PageHeader
      label={
        <div className="flex items-center gap-[20px]">
          <EditText
            editIconClassNames="mt-[3px]"
            defaultOpen={history.state !== null}
            showEdit={
              !liveReport?.project?.owner && liveReport?.project?.shared === GroupSharedStatus.VIEW
            }
            textStyle="font-[700] text-[24px] text-1color"
            text={liveReport?.name || ''}
            onEdited={handleChangeReportName}
          />

          <div className="flex items-end gap-[10px]">
            {process.env.REACT_APP_THEME !== 'rtk' && (
              <StatusLabel
                status={status as StatusVariant}
                onChangeStatus={handleChangeStatus}
                statusProperties={reportStatusPropertiesData}
              />
            )}
            <SharedStatus status={liveReport?.project?.shared || ''} />
          </div>
        </div>
      }
      popLink={-1}
      // afterAction={handleEditReportName}
    >
      <div className="flex items-center font-[500] text-[14px] leading-[17px] text-1color">
        <TooltipWrapper content={t('analytic_project')} id={'AP_project'}>
          <div className="cursor-default">{t('ap')}:</div>
        </TooltipWrapper>
        <div
          onClick={() =>
            navigate(`/${navigationRoutes.reports}?project=${liveReport?.project.project_id}`)
          }
          className="cursor-pointer"
        >
          &nbsp;{liveReport?.project.project_name}
        </div>
      </div>
    </PageHeader>
  );
};

export default connector(ReportHeaderWithStatusBlock);

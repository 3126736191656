import React, { useState } from 'react';
import {
  useCreateIntegrationsAccessKeyMutation,
  useDeleteIntegrationsAccessKeyMutation,
  useEditIntegrationsAccessKeyMutation,
  useGetIntegrationsAccessKeysListQuery,
  useLazyGetIntegrationsAccessKeyQuery,
} from '@app/store/api/intergation-settings.api';
import { Button, EasyModal } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { IntegrationTableBlock } from '@app/pages/settings/blocks/integration-settings/integration-table.block';
import { ModalWithForm } from '@app/pages/settings/blocks/integration-settings/modal-with-form';
import { IntegrationAccessKeyCreateObject } from '@app/interfaces/settings.type';
import { CircleChannelIcon } from '@icons';

const IntegrationSettingsPage = () => {
  const { t } = useTranslation('pages.settings');
  // useState
  const [isOpenAddIntegrationModal, changeOpenAddIntegrationModal] = useState(false);
  const [editMode, changeEditMode] = useState(false);
  const [isOpenDeleteModal, changeOpenDeleteModal] = useState(false);
  const [integrationIdForDelete, changeIntegrationIdForDelete] = useState<string>();
  const [createdIntegrationData, changeCreatedIntegrationData] =
    useState<IntegrationAccessKeyCreateObject>();
  const [createdIntegrationModal, changeCreatedIntegrationModal] = useState(false);
  // api
  const { data } = useGetIntegrationsAccessKeysListQuery();
  const [getIntegration, { currentData }] = useLazyGetIntegrationsAccessKeyQuery();
  const [createIntegration] = useCreateIntegrationsAccessKeyMutation();
  const [editIntegration] = useEditIntegrationsAccessKeyMutation();
  const [deleteIntegration] = useDeleteIntegrationsAccessKeyMutation();
  //handlers
  function onFormSubmitHandler(data: { name: string }, newKeys = false) {
    editMode && currentData
      ? newKeys
        ? deleteIntegration({ access_key: currentData.access_key }).then(() => {
            createIntegration(data)
              .unwrap()
              .then((data) => {
                changeCreatedIntegrationData(data);
                changeCreatedIntegrationModal(true);
              });
          })
        : editIntegration({ params: { access_key: currentData.access_key }, body: data })
      : createIntegration(data)
          .unwrap()
          .then((data) => {
            changeCreatedIntegrationData(data);
            changeCreatedIntegrationModal(true);
          });
    changeOpenAddIntegrationModal((prev) => !prev);
  }
  function onFormDeleteHandler() {
    currentData &&
      deleteIntegration({ access_key: currentData.access_key }).then(() => {
        changeOpenAddIntegrationModal((prev) => !prev);
      });
  }
  function onDeleteIconClick(access_key: string) {
    changeIntegrationIdForDelete(access_key);
    changeOpenDeleteModal(true);
  }
  function onEditIconClick(access_key: string) {
    getIntegration({ access_key }).then(() => {
      changeEditMode(true);
      changeOpenAddIntegrationModal(true);
    });
  }
  //todo
  function copyText(text: string | undefined) {
    if (navigator.clipboard && text) {
      navigator?.clipboard.writeText(text);
    } else {
      console.error('не доступен');
    }
  }
  function renderModal() {
    if (!isOpenAddIntegrationModal) return;
    return (
      <ModalWithForm
        isOpenAddIntegrationModal={isOpenAddIntegrationModal}
        changeOpenAddIntegrationModal={changeOpenAddIntegrationModal}
        changeEditMode={changeEditMode}
        onFormSubmitHandler={onFormSubmitHandler}
        editMode={editMode}
        integrationData={currentData}
        onFormDeleteHandler={onFormDeleteHandler}
      />
    );
  }
  return (
    <div>
      <div className="flex items-center w-full justify-between py-[30px]">
        <h1 className="text-[18px] font-[700] ml-[20px]">{t('integrations.integrations_title')}</h1>
        <div>
          <Button
            data-id="new-integration"
            onClick={() => {
              changeEditMode(false);
              changeOpenAddIntegrationModal(true);
            }}
            icon="CirclePlusIcon"
            label={t('integrations.add_integration')}
          />
        </div>
      </div>
      <div>
        <IntegrationTableBlock
          tableData={data}
          onDeleteIconClick={onDeleteIconClick}
          onEditIconClick={onEditIconClick}
        />
      </div>
      {renderModal()}
      <EasyModal
        withoutFooter
        onClose={changeOpenDeleteModal.bind(null, false)}
        label={t('warning')}
        show={isOpenDeleteModal}
        variant="removal"
        onRemove={() =>
          integrationIdForDelete && deleteIntegration({ access_key: integrationIdForDelete })
        }
      >
        <div className="flex items-center justify-center">
          {t('integrations.delete_integration_text')}
        </div>
      </EasyModal>
      <EasyModal
        withoutFooter
        onClose={changeCreatedIntegrationModal.bind(null, false)}
        label={
          <div className="flex flex-col gap-[13px] items-center justify-center">
            <div className="w-[57px] h-[57px] rounded-full bg-blue_pal flex items-center justify-center">
              <CircleChannelIcon className="text-action" size={25} />
            </div>
            <span className="text-0color text-[24px] font-[700] leading-[29px]">
              {t('integrations.createdIntModalLabel')}
            </span>
            <span className="text-basic_red text-[15px] font-[400] leading-[29px] text-center">
              {t('integrations.createdIntModalKey')}
              <br />
              {t('integrations.createdIntModalDescription')}
            </span>
          </div>
        }
        show={createdIntegrationModal}
        variant="mediumW650"
      >
        <div className={'flex flex-col items-center gap-[20px] py-[20px]'}>
          <div className={'flex items-center gap-[20px]'}>
            <span className={'text-[15px] font-[500]'}>ACCESS_KEY:</span>
            <span className={'text-[13px] font-[700] w-[300px] truncate'}>
              {createdIntegrationData?.access_key}
            </span>
            <Button
              onClick={() => copyText(createdIntegrationData?.access_key)}
              label={'copy'}
              fill={'linked'}
            />
          </div>
          <div className={'flex items-center gap-[20px]'}>
            <span className={'text-[15px] font-[500]'}>SECRET_KEY:</span>
            <span className={'text-[13px] font-[700] w-[300px] truncate'}>
              {createdIntegrationData?.secret_key}
            </span>
            <Button
              onClick={() => copyText(createdIntegrationData?.secret_key)}
              label={'copy'}
              fill={'linked'}
            />
          </div>
        </div>
      </EasyModal>
    </div>
  );
};

export default IntegrationSettingsPage;

import React, { useEffect, useState } from 'react';
import { Button, EasyFilterView, PageHeader, Select } from '@ui';
import {
  useGetAnalyticProjectsForLastProjectsQuery,
  useGetAnalyticsProjectsListQuery,
  useGetSingleAnalyticProjectQuery,
} from '@app/store/api/analytics-projects.api';
import useTranslation from '@app/hooks/use-translation';
import { LastProjectRecordsTableBlock } from '@app/pages/last-project-records/blocks/last-project-records-table.block';
import { useGetProjectMetricsListQuery } from '@app/store/api/metrics.api';
import { Filter, Range } from '@app/components/ui/easy-filter/types';
import { GroupSharedStatus } from '@app/interfaces/analytics';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { useCreateLiveReportMutation } from '@app/store/api/live-reports.api';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@app/store/store';
import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';
import SkeletonPageLoading from '@app/components/ui/skeleton-page-loading';

export interface GetFilterParams {
  offset: number | string;
  limit: number;
  filter: Filter & Range;
  sortBy?: string;
  sortDesc?: boolean;
  preset_mode?: boolean;
}
const DEFAULT_RECORDS_LIMIT = 10;
const DEFAULT_RECORDS_OFFSET = 0;
const LastProjectRecordsPage = () => {
  const { t } = useTranslation('pages.recordsByAnalytics');
  const navigate = useNavigate();
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const DEFAULT_FILTER_SETUP: GetFilterParams = {
    offset: DEFAULT_RECORDS_OFFSET,
    limit: userSettings?.tablesLimit?.lastProjectRecords || DEFAULT_RECORDS_LIMIT,
    filter: {
      range: {
        type: 'n',
        parameters: [],
      },
      filter: [],
    },
  };
  const [filterParams, changeFilterParams] = useState<GetFilterParams>(DEFAULT_FILTER_SETUP);
  useEffect(() => {
    userSettings?.tablesLimit?.lastProjectRecords &&
      changeFilterParams((prev) => ({
        ...prev,
        limit: userSettings?.tablesLimit?.lastProjectRecords as number,
      }));
  }, [userSettings?.tablesLimit?.lastProjectRecords]);
  //api
  const [updateUserSettings, { isLoading: updateNewApLoading }] = useUpdateUserSettingsMutation();
  const { data: analyticsProjectsGroups, isLoading: allProjectsLoading } =
    useGetAnalyticsProjectsListQuery();
  const { data: analyticsProjects, isLoading: projectLoadingForLast } =
    useGetAnalyticProjectsForLastProjectsQuery();
  const { data: project, isLoading: projectLoading } = useGetSingleAnalyticProjectQuery(
    { id: userSettings?.lastProjectRecords || '' },
    { skip: !userSettings?.lastProjectRecords },
  );
  const { data: metricList, isLoading: projectMetricLoading } = useGetProjectMetricsListQuery(
    {
      project_id: project?.project_id || '',
    },
    { skip: !project?.project_id },
  );
  const [createLiveReportMutation] = useCreateLiveReportMutation();
  //useEffect
  useEffect(() => {
    const localStorageId = userSettings?.lastProjectRecords;
    const project_id_default = analyticsProjectsGroups?.find((i) => i.is_default)?.projects?.[0]
      ?.project_id;
    if (!localStorageId) {
      project_id_default &&
        updateUserSettings({ ...userSettings, lastProjectRecords: project_id_default });
    }
    if (analyticsProjects && localStorageId) {
      const condition = analyticsProjects.find((item) => item.project_id === localStorageId)?.name;
      if (!condition) {
        project_id_default &&
          updateUserSettings({ ...userSettings, lastProjectRecords: project_id_default });
      }
    }
  }, [analyticsProjects, analyticsProjectsGroups, updateUserSettings, userSettings]);
  // handlers
  const getOptionsSelect = () => {
    if (!analyticsProjects?.length) return [];
    return analyticsProjects.map(({ name, project_id }) => ({
      title: name,
      value: project_id,
    }));
  };

  function changeProjectSelect(value: string) {
    changeFilterParams(DEFAULT_FILTER_SETUP);
    updateUserSettings({ ...userSettings, lastProjectRecords: value });
  }
  function getFilteringKeys() {
    return metricList?.map((metric) => ({
      id: metric.metric_id,
      title: metric.name,
      type_value: metric.result_value_type,
    }));
  }
  function handleFilterChange(newFilter: Filter & Range) {
    changeFilterParams((prev) => ({ ...prev, filter: newFilter }));
  }
  function createLiveReport() {
    if (!project) return;
    const result = {
      name: `${project.name}`,
      description: project.description,
      filter: filterParams.filter,
      project_id: project.project_id,
    };
    createLiveReportMutation(result)
      .unwrap()
      .then((data) => {
        navigate(
          `/${navigationRoutes.reports}/${data.live_report_id}/${navigationRoutes.settings}`,
          {
            state: navigationRoutes.reports,
          },
        );
      });
  }
  // jsx markup
  const getTitleFilter = <h2 className="text-1color font-bold">{t('filter.title')}</h2>;

  const getFooterFilter = (
    <div className="flex items-center gap-[10px]">
      <Button
        data-id="new-report"
        label={
          process.env.REACT_APP_THEME === 'rtk'
            ? t('filter.buttons.create_report_rtk')
            : t('filter.buttons.create_report')
        }
        onClick={() => createLiveReport()}
        disabled={!project?.owner && project?.shared === GroupSharedStatus.VIEW}
      />
      <Button
        label={t('filter.buttons.reset_filter')}
        fill="linked"
        disabled={filterParams.filter.range.type === 'n'}
        onClick={() => changeFilterParams(DEFAULT_FILTER_SETUP)}
      />
    </div>
  );
  if (projectLoading || allProjectsLoading || projectLoadingForLast || updateNewApLoading)
    return <SkeletonPageLoading />;

  return (
    <div className="pb-[25px]">
      <PageHeader icon="FolderMediaIcon" label={project?.name || <span>&mdash;</span>}>
        <div className="inline-flex items-center">
          {t('select_analytic_project.label')}:
          <div className="ml-[11px]">
            <Select
              defaultValue={userSettings?.lastProjectRecords || ''}
              options={getOptionsSelect()}
              placeholder={t('select_analytic_project.select_placeholder')}
              size={300}
              dropHeight={250}
              dropWidth={296}
              onChange={(value) => changeProjectSelect(value as string)}
            />
          </div>
        </div>
      </PageHeader>
      <div>
        <EasyFilterView
          dataIdStartDate="project-records-date-from"
          dataIdEndDate="project-records-date-to"
          data={filterParams.filter}
          isLoading={projectMetricLoading}
          titleMarkup={getTitleFilter}
          footerMarkup={getFooterFilter}
          onChangeHandler={handleFilterChange}
          keysForFiltering={getFilteringKeys() || []}
          disabledChangeButton={!project?.project_id}
        />
        <LastProjectRecordsTableBlock
          project_id={userSettings?.lastProjectRecords || ''}
          filterParams={filterParams}
          changeFilterParams={changeFilterParams}
        />
      </div>
    </div>
  );
};

export default LastProjectRecordsPage;

import { EasyModal, Loading } from '@app/components/ui';
import AccordionItemUI from '@app/components/ui/Accordion/AccordionItem';
import AccordionUI from '@app/components/ui/Accordion/Aссordion';
import { AccordionItemUIPropsClickChildParams } from '@app/interfaces/userInterface';
import React, { Dispatch, SetStateAction } from 'react';
import { BigChartIcon } from '@app/components/ui/icons/icons-list';

import useTranslation from '@app/hooks/use-translation';

import { ReportChart } from '@app/interfaces/report.type';

import { useGetAllLiveReportsListQuery } from '@app/store/api/live-reports.api';

import { useLazyGetLiveReportsGraphListQuery } from '@app/store/api/live-reports-charts.api';

import useChartDateIntervals from '@app/hooks/use-chart-date-intervals';

import { ChartsLayoutType } from '@app/interfaces/dashboards.type';

import { ChartEditModal } from './chart-edit-modal';

type PropsType = {
  createModalState: boolean;
  setCreateModalState: Dispatch<SetStateAction<boolean>>;
  loading?: boolean;
};

const CreateChartModal: React.FC<PropsType> = (props) => {
  const { createModalState, setCreateModalState } = props;
  const [chartList, setChartList] = React.useState<(ReportChart & { layout: ChartsLayoutType })[]>(
    [],
  );
  const [loading, setLoading] = React.useState(false);
  const [chartSettingsModal, setChartSettingsModal] = React.useState(false);
  const [chartId, setChartId] = React.useState('');
  const { t } = useTranslation('pages.dashboard.modals.create_chart_modal');
  const { data: liveReports, isLoading } = useGetAllLiveReportsListQuery({ limit: 100 });
  const [getChartInfo] = useLazyGetLiveReportsGraphListQuery();
  const { reversedDateIntervals } = useChartDateIntervals();

  //accordion
  const [activeAccordionItem, setActiveAccordionItem] = React.useState<string | undefined>(
    undefined,
  );
  const clickAccordionHeaderBtn = (event: AccordionItemUIPropsClickChildParams) => {
    if (event.id === activeAccordionItem) {
      if (event.id === '') {
        setActiveAccordionItem(undefined);
      } else {
        setActiveAccordionItem('');
      }
    } else {
      setActiveAccordionItem(event.id);
      setLoading(true);
      getChartInfo(event.id)
        .unwrap()
        .then((res) => {
          setChartList(res as any);
        })
        .catch(() => setLoading(false))
        .finally(() => setLoading(false));
    }
  };
  //accordeon
  const getChartName = chartList.find((e) => e.graph_id === chartId)?.name || '';
  // const getLayout = chartList.find((e) => e.graph_id === chartId)?.layout;

  const getChartRange = () => {
    const range = chartList.find((e) => e.graph_id === chartId)?.range;
    if (!range) return;
    return reversedDateIntervals[
      range?.type === 'l' ? `l${range?.parameters?.[0].num}` : range.type
    ];
  };

  const onChartClick = (id: string) => {
    setChartId(id);
    setCreateModalState(false);
    setChartSettingsModal(true);
  };
  if (isLoading) return <Loading />;
  return (
    <>
      <EasyModal
        variant="mediumW650"
        withoutFooter
        show={createModalState}
        onClose={() => setCreateModalState(false)}
        label={<div className="text-0color text-[24px] font-bold mb-[25px]">{t('title')}</div>}
      >
        {createModalState && (
          <div className="w-full">
            <AccordionUI>
              {liveReports?.live_reports
                .filter((liveReport) => liveReport.graphs !== 0)
                .map((item) => {
                  return (
                    <AccordionItemUI
                      dataId={`dashboard-chart-create-${item.name}`}
                      key={item.live_report_id}
                      id={item.live_report_id}
                      title={item.name}
                      clickBtnChild={clickAccordionHeaderBtn}
                      active={activeAccordionItem === item.live_report_id}
                    >
                      {activeAccordionItem === item.live_report_id && (
                        <ul className="flex w-full rounded-[12px] flex-col border-[1px] border-basic_app_bg">
                          {loading ? (
                            <Loading />
                          ) : (
                            chartList.map((chartItem) => {
                              return (
                                <li
                                  data-id={`dashboard-chart-create-${item.name}-${chartItem.name}`}
                                  className="w-full items-center flex cursor-pointer justify-between border-b-[1px] border-basic_app_bg p-[10px] text-1color text-[16px] min-h-[90px]"
                                  key={chartItem.graph_id}
                                  onClick={() => onChartClick(chartItem.graph_id)}
                                >
                                  <BigChartIcon
                                    className="p-[16px_11px_11px_11px] bg-basic_app_bg"
                                    size={67}
                                  />
                                  <div className="flex">
                                    <div className="flex items-start flex-col">
                                      <div className="font-medium max-w-[250px] whitespace-nowrap text-ellipsis overflow-hidden">
                                        {chartItem.name}
                                      </div>
                                    </div>
                                  </div>
                                  <span className="w-[28px] text-[#1C1C1E] bold">&gt;</span>
                                </li>
                              );
                            })
                          )}
                        </ul>
                      )}
                    </AccordionItemUI>
                  );
                })}
            </AccordionUI>
          </div>
        )}
      </EasyModal>
      {chartSettingsModal && (
        <ChartEditModal
          live_report_graph_id={chartId}
          onClose={setChartSettingsModal}
          show={chartSettingsModal}
          getChartName={getChartName}
          // getLayout={getLayout}
          getChartRange={getChartRange()}
        />
      )}
    </>
  );
};

export default CreateChartModal;

import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import { BreadcrumbsItem } from '@app/layouts/layout';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { analyticRoute } from '@app/routes/analytic.route';
import RequiredAuth from '@app/routes/required-auth.route';
import { recordsRoute } from '@app/routes/records.route';
import { reportsRoute } from '@app/routes/reports.route';
import { lastRecordRoute } from '@app/routes/last-record.route';
import { MetricTemplates } from '@app/pages/metric-templates/metric-templates.page';
import { dictionariesRoute } from '@app/routes/dictionaries.route';
import { settingsRoute } from '@app/routes/settings.route';
import { AuthorizationPage, EmailPage, ErrorBoundaryPage, SettingsOutlet } from '@app/pages';
import { RouterLoadingHook } from '@app/routes/router-loading-hook';
import { wordsNGramm } from '@app/routes/words-n-gramm.route';

import LayoutSwitcher from '@app/layouts/layout-switcher';

import { presetTemplateRoute } from './routes/preset-templates.route';
import DashboardPage from './pages/dashboards';
import AuditLogs from './pages/audit-logs';
import { wordsCloudRoute } from './routes/words-cloud.route';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <LayoutSwitcher switchOffLayout={{ [navigationRoutes.email_report]: 'EmailLayout' }} />
      }
      errorElement={<ErrorBoundaryPage />}
    >
      <Route
        index
        element={
          <RequiredAuth>
            <DashboardPage />
          </RequiredAuth>
        }
      />
      {/* RECORDS ROUTES */}
      {recordsRoute}
      {/* ANALYTICS */}
      {analyticRoute}
      {/*REPORTS*/}
      {reportsRoute}
      {/* latest project are looked */}
      {lastRecordRoute}
      {/* DictionariesPage */}
      {dictionariesRoute}
      {/* Preset Templates Page */}
      {presetTemplateRoute}
      <Route
        path={navigationRoutes.templates}
        handle={{
          crumb: () => (
            <BreadcrumbsItem path={navigationRoutes.templates} crumb="metric_templates" />
          ),
        }}
      >
        <Route
          index
          element={
            <RequiredAuth>
              <div>TEMPLATES PAGE</div>
            </RequiredAuth>
          }
        />
        {/* METRIC TEMPLATES */}
        <Route
          path={navigationRoutes.metric}
          element={
            <RequiredAuth>
              <MetricTemplates />
            </RequiredAuth>
          }
        />
        {/* METRIC TEMPLATES */}
      </Route>
      {/* SETTINGS */}
      {settingsRoute}
      {wordsCloudRoute}
      {wordsNGramm}
      <Route
        path={navigationRoutes.settings}
        element={
          <RequiredAuth>
            <SettingsOutlet />
          </RequiredAuth>
        }
        handle={{
          crumb: () => <BreadcrumbsItem path={`/${navigationRoutes.settings}`} crumb="settings" />,
        }}
      ></Route>
      <Route
        path={navigationRoutes.authorization + '/*'}
        element={
          <RouterLoadingHook>
            <AuthorizationPage />
          </RouterLoadingHook>
        }
      />

      <Route
        path={navigationRoutes.auditLogs}
        element={
          <RequiredAuth>
            <AuditLogs />
          </RequiredAuth>
        }
      />
      <Route path={navigationRoutes.email_report} index element={<EmailPage />} />
      <Route path={navigationRoutes.error} element={<ErrorBoundaryPage />} />
    </Route>,
  ),
);

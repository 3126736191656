import apiInstance from './common.api';

export const downloadCsvReport = async (id: string, format?: 'csv' | 'xls') => {
  return await apiInstance.get('/api/front/live_report/record/export', {
    params: {
      id: id,
      format,
    },
    responseType: 'blob',
  });
};

import React from 'react';

import useTranslation from '@app/hooks/use-translation';
import { appThemes, config } from '@app/utils/config';

const FooterBlock = () => {
  const { t } = useTranslation('components.footer');
  return (
    <div className="h-[40px] bg-bg_3 w-full fixed bottom-0">
      <div className="inline-flex w-full items-center h-full justify-end px-[20px]">
        {config.appTheme !== appThemes.rtk && (
          <div className="text-[14px] font-[500px] inline-flex items-center text-2color">
            <div className="mr-[20px]"> {t('version')}</div>
            <div>
              {config.appTheme === appThemes.calltraffic
                ? t('copyrightCallTraffic')
                : t('copyright')}{' '}
              {new Date().getFullYear()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FooterBlock;

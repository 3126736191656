import React from 'react';
import { Skeleton } from '@ui';

const DashboardChartsSkeleton = ({ count }: { count?: number }) => {
  return (
    <div>
      <div className="w-full h-[100vh] flex flex-wrap gap-[10px]">
        {Array(count)
          .fill('_')
          .map((_, index) => (
            <div key={index} className={'w-[calc(50%-10px)]'}>
              <Skeleton height={360} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default DashboardChartsSkeleton;

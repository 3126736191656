import React, { FC, useEffect, useState } from 'react';

import { navigationRoutes } from '@app/utils/navigation-routes';
import TimeDuration from '@app/pages/analytics/project-records/blocks/time-duration';
import { Paginator, Skeleton } from '@ui';
import { FilterTableData, TableItem } from '@app/components/table/table.type';
import useTranslation from '@app/hooks/use-translation';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import { DownloadCloudIcon, Oscilogram } from '@app/components/ui/icons/icons-list';
import {
  getMetricScoreStyles,
  getMetricScoreStyles100,
} from '@app/pages/analytics/project-records/styles/styles';
import { Filter, Range } from '@app/components/ui/easy-filter/types';
import {
  useGetLiveReportRecordsQuery,
  useGetLiveReportSettingsQuery,
} from '@app/store/api/live-reports.api';
import { useLazyDownloadRecordQuery } from '@app/store/api/records.api';
import { useAppSelector } from '@app/store/store';
import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';
import { ChartsLayoutType } from '@app/interfaces/dashboards.type';
import Table from '@app/components/table';

const DEFAULT_REPORT_RECORDS_LIMIT = 10;
const DEFAULT_REPORT_RECORDS_OFFSET = 0;

type ReportRecordsTableBlockProps = {
  filter: Filter & Range;
  reportId: string;
};

const ReportRecordsTableBlock: FC<ReportRecordsTableBlockProps> = (props) => {
  const { filter, reportId } = props;
  const { t } = useTranslation('pages.projectRecords');
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const [updateUserSettings, { isLoading: userLoading }] = useUpdateUserSettingsMutation();
  const [reportRecordsLimit, changeReportRecordsLimit] = useState<number>(
    userSettings?.tablesLimit?.reports?.reportRecords || DEFAULT_REPORT_RECORDS_LIMIT,
  );
  const [reportRecordsOffset, changeReportRecordsOffset] = useState<number>(
    DEFAULT_REPORT_RECORDS_OFFSET,
  );
  const [reportRecordsOrderBy, changeRecordsReportOrderBy] = useState<FilterTableData>();
  // api
  const [downloadRecord] = useLazyDownloadRecordQuery();
  const { currentData: liveReport } = useGetLiveReportSettingsQuery(
    { id: reportId || '' },
    { skip: !reportId },
  );
  const { data: liveReportRecords, isLoading: isPending } = useGetLiveReportRecordsQuery({
    params: { id: reportId },
    body: {
      offset: reportRecordsOffset,
      limit: reportRecordsLimit,
      filter,
      ...reportRecordsOrderBy,
    },
  });
  const [userSettingsLoading, setUserSettingsLoading] = useState(false);
  useEffect(() => {
    if (userLoading) {
      setUserSettingsLoading(true);
    }
    if (!userLoading) {
      setTimeout(() => setUserSettingsLoading(false), 300);
    }
  }, [userLoading]);

  useEffect(() => {
    changeReportRecordsLimit(userSettings?.tablesLimit?.reports?.reportRecords || 10);
  }, [userSettings?.tablesLimit?.reports?.reportRecords]);

  useEffect(() => {
    if (!liveReportRecords) return;
    if (liveReportRecords?.total <= reportRecordsLimit) {
      changeReportRecordsOffset(0);
    }
  }, [liveReportRecords, reportRecordsLimit]);

  const checkingForSorting = (index) => {
    if (!liveReportRecords) return;
    return index !== liveReportRecords?.headers.length + 1;
  };

  const recordsLiveReportDataColumns = [
    { index: 'duration', title: t('table.duration'), size: 160, hintTitle: t('to_record_title') },
    ...(liveReportRecords?.headers.map((titleData, index) => ({
      index: titleData.metric_id,
      title: titleData.name,
      size: 190,
      filter: checkingForSorting(index),
    })) || []),
    { index: 'utils', title: t('actions'), size: 110 },
  ];
  function displayItemVisualization(item) {
    if (item.visualization) {
      if (item.visualization === 'score5') {
        if (Number(item.value) > 5 || Number(item.value) < 0) {
          return item.value;
        } else {
          return (
            <div className="w-full flex items-center justify-start px-[10px]">
              <div
                className={`${getMetricScoreStyles(
                  Number(item.value),
                )} h-[23px] text-[15px] text-white font-[400] leading-[18px] flex items-center p-[3px_9px_2px_0px] justify-end rounded-[2px]`}
              >
                {item.value}
              </div>
            </div>
          );
        }
      } else if (item.visualization === 'score100') {
        if (Number(item.value) > 100 || Number(item.value) < 0) {
          return item.value;
        } else {
          return (
            <div className="w-full flex items-center justify-start px-[10px]">
              <div
                style={{ width: `${30 + Math.ceil(Number(item.value)) * 1.5}px` }}
                className={`${getMetricScoreStyles100(
                  Number(item.value),
                )} h-[23px] text-[15px] text-white font-[400] leading-[18px] flex items-center p-[3px_9px_2px_0px] justify-end rounded-[2px]`}
              >
                {item.value}
              </div>
            </div>
          );
        }
      } else return item.value;
    } else return item.value;
  }

  const formattedLiveReportRecordsTableData = liveReportRecords?.records?.map((dataItem) => ({
    duration: (
      <div className="inline-flex items-center h-full mt-[8px]">
        <TimeDuration
          link={`/${navigationRoutes.reports}/${reportId}/${navigationRoutes.reportRecords}/${liveReport?.project.project_id}/${dataItem.record_id}`}
          duration={dataItem.duration}
        />
      </div>
    ),
    ...Object.fromEntries(
      dataItem.data.map((metrics) => [[metrics.metric_id], displayItemVisualization(metrics)]),
    ),
    utils: (
      <div className="flex w-full items-start pl-[19px] pr-[10px]">
        <DownloadCloudIcon
          onClick={() => {
            downloadRecord({ record_id: dataItem.record_id });
          }}
          hintTitle={t('download_record')}
          size={18}
          className={`mr-[18px] text-3color hover:text-action transition cursor-pointer`}
        />
        <Oscilogram
          onClick={() =>
            window.open(
              `/${navigationRoutes.reports}/${reportId}/${navigationRoutes.reportRecords}/${liveReport?.project.project_id}/${dataItem.record_id}`,
              '_blank',
            )
          }
          hintTitle={t('to_record_title')}
          size={22}
          className={'text-3color hover:text-action cursor-pointer transition'}
        />
      </div>
    ),
  }));

  function onChangePagination({ offset, limit }: PaginationResponse) {
    changeReportRecordsOffset(offset);
    updateUserSettings({
      ...userSettings,
      tablesLimit: {
        ...userSettings?.tablesLimit,
        reports: { ...userSettings?.tablesLimit?.reports, reportRecords: limit },
      },
    });
    changeReportRecordsLimit(limit);
  }

  const [tableLayouts, changeTableLayouts] = useState(
    userSettings?.tableColsSize?.reports?.reportRecords,
  );

  useEffect(() => {
    const condition = userSettings?.tableColsSize?.reports?.reportRecords?.find(
      (layoutItem) => layoutItem.id === reportId,
    );
    if (!condition) {
      const systemArr = [
        ...(userSettings?.tableColsSize?.reports?.reportRecords || []),
        { id: reportId, layout: [] },
      ];
      updateUserSettings({
        ...userSettings,
        tableColsSize: {
          ...userSettings?.tableColsSize,
          reports: { ...userSettings?.tableColsSize?.reports, reportRecords: systemArr },
        },
      });
    } else {
      changeTableLayouts(userSettings?.tableColsSize?.reports?.reportRecords);
    }
  }, [reportId, updateUserSettings, userSettings]);

  function onChangeLayoutHandler(layout: ChartsLayoutType[]) {
    const currentChangedLayout = tableLayouts?.map((tablesLayout) => {
      if (tablesLayout.id === reportId) {
        return { id: tablesLayout.id, layout: layout.map((item) => ({ ...item, maxW: 24 })) };
      }
      return tablesLayout;
    });

    updateUserSettings({
      ...userSettings,
      tableColsSize: {
        ...userSettings?.tableColsSize,
        reports: { ...userSettings?.tableColsSize?.reports, reportRecords: currentChangedLayout },
      },
    });
  }

  if (isPending) return <Skeleton height={360} />;
  return (
    <div className="relative">
      {userSettingsLoading && <Skeleton height={800} className={'absolute z-[999] mt-6 top-0'} />}
      <Table
        columns={recordsLiveReportDataColumns}
        dataSource={formattedLiveReportRecordsTableData as unknown as Array<TableItem>}
        onFilter={changeRecordsReportOrderBy}
        isEmpty={t('empty_records')}
        layout={tableLayouts
          ?.find((layoutItem) => layoutItem.id === reportId)
          ?.layout.map((item) => ({ ...item, maxW: 16 }))}
        onLayoutChange={onChangeLayoutHandler}
      />
      <div className="my-[20px]">
        <Paginator
          page={Math.ceil(reportRecordsOffset / reportRecordsLimit) + 1}
          count={liveReportRecords?.total || 0}
          limit={reportRecordsLimit}
          onChange={onChangePagination}
        />
      </div>
    </div>
  );
};

export default ReportRecordsTableBlock;

import React, { FC, useCallback, useEffect, useState } from 'react';
import { MetricMetricItemData } from '@app/interfaces/metric.type';
import {
  getMetricScoreStyles,
  getMetricScoreStyles100,
} from '@app/pages/analytics/project-records/styles/styles';
import TimeDuration from '@app/pages/analytics/project-records/blocks/time-duration';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { DownloadCloudIcon, Oscilogram } from '@icons';
import { Link } from 'react-router-dom';
import useTranslation from '@app/hooks/use-translation';
import classNames from 'classnames';
import { useGetMetricRecordsQuery } from '@app/store/api/metric-records.api';
import { Paginator, Skeleton } from '@ui';
import { PaginationResponse } from '@app/components/ui/paginator/paginator';
import { useLazyDownloadRecordQuery } from '@app/store/api/records.api';
import { FilterTableData } from '@app/components/table/table.type';
import { GetFilterParams } from '@app/pages/analytics/project-records/project-records.page';
import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';
import { useAppSelector } from '@app/store/store';
import { ChartsLayoutType } from '@app/interfaces/dashboards.type';
import Table from '@app/components/table';

type LastProjectRecordsTableBlockPropsType = {
  project_id: string;
  filterParams: GetFilterParams;
  changeFilterParams: React.Dispatch<React.SetStateAction<GetFilterParams>>;
};
export const ProjectRecordsTableBlock: FC<LastProjectRecordsTableBlockPropsType> = (props) => {
  const { project_id, filterParams, changeFilterParams } = props;
  const { t } = useTranslation('pages.recordsAp');
  const tableUtilIconsClassName = classNames(
    'text-3color transition hover:text-action cursor-pointer transition cursor-pointer',
  );
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const [updateUserSettings, { isLoading: userLoading }] = useUpdateUserSettingsMutation();
  const [reportRecordsOrderBy, changeRecordsOrderBy] = useState<FilterTableData>();
  const { data } = useGetMetricRecordsQuery({
    ...{
      ...filterParams,
      sortBy: reportRecordsOrderBy?.sortBy,
      sortDesc: reportRecordsOrderBy?.sortDesc,
    },
    project_id: project_id,
  });
  console.log('data', data);
  const [downloadRecord] = useLazyDownloadRecordQuery();
  const [userSettingsLoading, setUserSettingsLoading] = useState(false);
  useEffect(() => {
    if (userLoading) {
      setUserSettingsLoading(true);
    }
    if (!userLoading) {
      setTimeout(() => setUserSettingsLoading(false), 300);
    }
  }, [userLoading]);
  const checkingAliasForSorting = useCallback(
    (index: number) => {
      return index !== (data?.headers.length as number) + 1;
    },
    [data?.headers.length],
  );
  function displayItemVisualization(item: MetricMetricItemData) {
    if (item.visualization) {
      if (item.visualization === 'score5') {
        if (Number(item.value) > 5 || Number(item.value) < 0) {
          return item.value;
        } else {
          return (
            <div className="w-full flex items-center justify-start px-[10px]">
              <div
                className={`${getMetricScoreStyles(
                  Number(item.value),
                )} h-[23px] text-[15px] text-white font-[400] leading-[18px] flex items-center p-[3px_9px_2px_0px] justify-end rounded-[2px]`}
              >
                {item.value}
              </div>
            </div>
          );
        }
      } else if (item.visualization === 'score100') {
        if (Number(item.value) > 100 || Number(item.value) < 0) {
          return item.value;
        } else {
          return (
            <div className="w-full flex items-center justify-start px-[10px]">
              <div
                style={{ width: `${30 + Math.ceil(Number(item.value)) * 1.5}px` }}
                className={`${getMetricScoreStyles100(
                  Number(item.value),
                )} h-[23px] text-[15px] text-white font-[400] leading-[18px] flex items-center p-[3px_9px_2px_0px] justify-end rounded-[2px]`}
              >
                {item.value}
              </div>
            </div>
          );
        }
      } else return item.value;
    } else return item.value;
  }
  const tableColumns = [
    {
      index: 'duration_project_records_items',
      title: t('table.first_column_title'),
      size: 160,
      hintTitle: t('popup_hints.to_record_title'),
    },
    ...(data?.headers?.map((header, index) => ({
      title: header.name,
      index: header.metric_id,
      size: 190,
      truncate: true,
      filter: checkingAliasForSorting(index),
    })) || []),
    { index: 'actions_project_records_items', title: t('table.last_column_title'), size: 110 },
  ];

  const tableData = data?.metrics.length
    ? data?.metrics?.map((item) => ({
        ...item,
        duration_project_records_items: (
          <div className="flex cursor-pointer">
            <TimeDuration
              link={`/${navigationRoutes.projectRecords}/${project_id}/${item.record_id}`}
              duration={item.duration}
            />
          </div>
        ),
        actions_project_records_items: (
          <div className="flex items-center pl-[19px]">
            <DownloadCloudIcon
              hintTitle={t('popup_hints.download_record_button')}
              size={18}
              className={`${tableUtilIconsClassName}, mr-[18px]`}
              onClick={() => downloadRecord({ record_id: item.record_id })}
            />
            <Link
              target="_blank"
              to={`/${navigationRoutes.projectRecords}/${project_id}/${item.record_id}`}
            >
              <Oscilogram
                hintTitle={t('popup_hints.to_record_title')}
                size={22}
                className={tableUtilIconsClassName}
              />
            </Link>
          </div>
        ),
        ...Object.fromEntries(
          item.data.map((metrics) => [
            [metrics.metric_id],
            item.calculated ? displayItemVisualization(metrics) : <div></div>,
          ]),
        ),
      }))
    : [];
  function changePage({ offset, limit }: PaginationResponse) {
    changeFilterParams((prev) => ({ ...prev, offset: offset, limit: limit }));
    updateUserSettings({
      ...userSettings,
      tablesLimit: {
        ...userSettings?.tablesLimit,
        analyticProject: { ...userSettings?.tablesLimit?.analyticProject, records: limit },
      },
    });
  }
  const [tableLayouts, changeTableLayouts] = useState(
    userSettings?.tableColsSize?.analyticProject?.records,
  );

  useEffect(() => {
    const condition = userSettings?.tableColsSize?.analyticProject?.records?.find(
      (layoutItem) => layoutItem.id === project_id,
    );
    if (!condition && project_id) {
      const systemArr = [
        ...(userSettings?.tableColsSize?.analyticProject?.records || []),
        { id: project_id, layout: [] },
      ];
      updateUserSettings({
        ...userSettings,
        tableColsSize: {
          ...userSettings?.tableColsSize,
          analyticProject: { ...userSettings?.tableColsSize?.analyticProject, records: systemArr },
        },
      });
    } else {
      changeTableLayouts(userSettings?.tableColsSize?.analyticProject?.records);
    }
  }, [project_id, updateUserSettings, userSettings]);

  function onChangeLayoutHandler(layout: ChartsLayoutType[]) {
    const currentChangedLayout = tableLayouts?.map((tablesLayout) => {
      if (tablesLayout.id === project_id) {
        return { id: tablesLayout.id, layout: layout.map((item) => ({ ...item, maxW: 24 })) };
      }
      return tablesLayout;
    });

    updateUserSettings({
      ...userSettings,
      tableColsSize: {
        ...userSettings?.tableColsSize,
        analyticProject: {
          ...userSettings?.tableColsSize?.analyticProject,
          records: currentChangedLayout,
        },
      },
    });
  }
  return (
    <div className="relative">
      {userSettingsLoading && <Skeleton height={800} className={'absolute z-[999] mt-6 top-0'} />}
      <Table
        onLayoutChange={onChangeLayoutHandler}
        layout={tableLayouts
          ?.find((layoutItem) => layoutItem.id === project_id)
          ?.layout.map((item) => ({ ...item, maxW: 16 }))}
        columns={tableColumns}
        dataSource={tableData}
        onFilter={changeRecordsOrderBy}
      />
      <div className="my-[20px]">
        <Paginator
          page={Math.ceil(Number(filterParams.offset) / filterParams.limit) + 1}
          count={data?.total || 0}
          limit={filterParams.limit}
          onChange={changePage}
          recordsQueue={(data?.total || 0) - (data?.total_calculated || 0)}
        />
      </div>
    </div>
  );
};

module.exports = {
  choose_project: 'Выберите отчет',
  select_all_projects: 'Все отчеты',
  part_of_speech_data: {
    all: 'Все части речи',
    NOUN: 'Существительное',
    VERB: 'Глагол',
    ADJ: 'Прилагательное',
    ADVB: 'Наречие',
    NUMR: 'Числительное',
    NPRO: 'Местоимение',
    CONJ: 'Предлоги/союзы/частицы',
  },
  occurrence: 'Встречаемость',
  all_channels_option: 'Все каналы',
  first_phrases_count: 'Колличество первых фраз',
  change_cloud_settings: 'Изменить настройки облака',
  start_cloud_calculate: 'Запустить расчет облака',
  filter_error_input_message: 'Разрешено одно или два слова',
  apply_button: 'Применить',
  // placeholders
  channel_select_placeholder: 'Канал',
  //
  word: 'слово',
  meets: 'встречается',
  cloud_deep: 'Глубина облака',
};

import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { NotFoundedPage } from '@app/pages';
import useTranslation from '@app/hooks/use-translation';
import { SkeletonPageLoading } from '@ui';
import { useGetLiveReportSettingsQuery } from '@app/store/api/live-reports.api';
import { useGetProjectMetricsListQuery } from '@app/store/api/metrics.api';

export type ReportOutletContext = {
  chartModalIsOpen: boolean;
  changeIsOpenChartModal: (status: boolean) => void;
};

const ReportOutlet = () => {
  const { t } = useTranslation('pages.reports');
  const { id } = useParams();
  const [chartModalIsOpen, changeIsOpenChartModal] = useState<boolean>(false);
  //api
  const { currentData: liveReport, isLoading: liveReportLoading } = useGetLiveReportSettingsQuery(
    { id: id || '' },
    { skip: !id },
  );
  const { data: metricList, isLoading: projectMetricLoading } = useGetProjectMetricsListQuery(
    {
      project_id: liveReport?.project.project_id || '',
    },
    { skip: !liveReport?.project.project_id },
  );

  if (liveReportLoading || projectMetricLoading) return <SkeletonPageLoading />;
  if (!liveReport) return <NotFoundedPage text={t('not_found')} icon="BrokenDocIcon" />;

  return (
    <Outlet
      context={
        {
          projectMetrics: metricList,
          report: liveReport,
          chartModalIsOpen,
          changeIsOpenChartModal,
        } as unknown as ReportOutletContext
      }
    />
  );
};

export default ReportOutlet;

import { createApi } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  MetricTemplatesGroupType,
  MetricTemplatesType,
} from '@app/interfaces/slices-types/metric-templates.type';

import { MetricObjType } from '@app/interfaces/pages-types/anatylics-metric.type';

import { baseQuery } from '../baseQuery';
export const metricTemplateApi = createApi({
  reducerPath: 'metricTemplate',
  tagTypes: ['metricTemplate', 'singleMetricTemplate'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getMetricTemplateGroupsList: build.query<MetricTemplatesGroupType[], void>({
      query: () => ({
        url: serverRoutes.metricTemplatesGroups,
      }),
      transformResponse: (baseQueryReturnValue: {
        metric_templates_groups: MetricTemplatesGroupType[];
      }) => baseQueryReturnValue.metric_templates_groups,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ metric_templates_group_id }) => ({
                type: 'metricTemplate' as const,
                metric_templates_group_id,
              })),
              'metricTemplate',
            ]
          : ['metricTemplate'],
    }),
    createMetricTemplateGroup: build.mutation<MetricTemplatesGroupType, { name: string }>({
      query: (body) => ({
        url: serverRoutes.metricTemplatesGroup,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['metricTemplate'],
    }),
    editMetricTemplateGroup: build.mutation<
      MetricTemplatesGroupType,
      { params: { id: string }; body: { name: string } }
    >({
      query: ({ params, body }) => ({
        url: serverRoutes.metricTemplatesGroup,
        method: 'PUT',
        params,
        body,
      }),
      invalidatesTags: ['metricTemplate'],
    }),
    deleteMetricTemplateGroup: build.mutation<MetricTemplatesGroupType, { id: string }>({
      query: (params) => ({
        url: serverRoutes.metricTemplatesGroup,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['metricTemplate'],
    }),
    // //single metricTemplate
    getSingleMetricTemplate: build.query<MetricTemplatesType, { id: string }>({
      query: (params) => ({
        url: serverRoutes.metricTemplate,
        params,
      }),
      providesTags: ['singleMetricTemplate'],
    }),
    createMetricTemplate: build.mutation<
      MetricObjType,
      {
        params: {
          metric_templates_group_id: string;
        };
        body: MetricObjType;
      }
    >({
      query: ({ body, params }) => ({
        url: serverRoutes.metricTemplate,
        body,
        params,
        method: 'POST',
      }),
      invalidatesTags: ['metricTemplate'],
    }),
    editMetricTemplate: build.mutation<
      MetricObjType,
      {
        params: {
          id: string;
          metric_templates_group_id: string;
        };
        body: MetricObjType;
      }
    >({
      query: ({ params, body }) => ({
        url: serverRoutes.metricTemplate,
        body,
        params,
        method: 'PUT',
      }),
      invalidatesTags: ['singleMetricTemplate', 'metricTemplate'],
    }),
    deleteMetricTemplate: build.mutation({
      query: (params: { id: string }) => ({
        url: serverRoutes.metricTemplate,
        method: 'DELETE',
        params,
      }),
      invalidatesTags: ['metricTemplate'],
    }),
  }),
});

export const {
  useGetMetricTemplateGroupsListQuery,
  useCreateMetricTemplateGroupMutation,
  useDeleteMetricTemplateMutation,
  useLazyGetSingleMetricTemplateQuery,
  useDeleteMetricTemplateGroupMutation,
  useEditMetricTemplateGroupMutation,
  useCreateMetricTemplateMutation,
  useEditMetricTemplateMutation,
} = metricTemplateApi;

const presetsTemplates = require('./presets-templates');
// const projectRecords = require('./projectRecords');
const notFound = require('./notFound');
const authorization = require('./authorization');
const reports = require('./reports');
const chartPage = require('./chartPage');
const settings = require('./settings');
// const dashboard = require('./dashboard');
// const records = require('./records');
const compositeMetric = require('./composite-metric');
const metricTemplates = require('./analytics-project-metric-and-templates');
const dictionaries = require('./dictionaries');
const analytics = require('./analytics');
const wordsCloud = require('./wordsCloud');
const analyticsProjectPreset = require('./analytics-project-preset');
const analyticsProjectRecords = require('./analytics-project-records');
const analyticsProjectMainSettings= require('./analytics-project-main-settings');
const wordsNGramm = require('./wordsNGramm');
const auditLogs = require('./auditLogs');
const dashboardPage = require('./dashboard')
const allRecords = require('./all-records')
const recordsByAnalytics = require('./records-by-analytics-projects')

module.exports = {
  dashboard:dashboardPage,
  notFound,
  allRecords,
  settings,
  chartPage,
  reports,
  auditLogs,
  dictionaries,
  wordsNGramm,
  wordsCloud,
  analytics,
  authorization,
  presetAp:analyticsProjectPreset,
  recordsAp:analyticsProjectRecords,
  mainSettingsAp:analyticsProjectMainSettings,
  metric:compositeMetric,
  metricTemplates,
  recordsByAnalytics,
  presets:presetsTemplates,
  // projectRecords,
  // dashboard,
  // records,
};

import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import AudioBuild from '@app/components/audio-build';
import { NotFoundedPage } from '@app/pages';

const SingleRecordPage: FC = () => {
  const { id } = useParams();
  if (!id) return <NotFoundedPage />;
  return <AudioBuild recordId={id} />;
};

export default SingleRecordPage;

import {
  compositeRecordRequest,
  deleteRecord,
  getFileRecordRequest,
  getRecord,
  getRecordAudio,
  getRecordBulk,
} from '@app/api/record.api';
import { GetFilterParams } from '@app/interfaces/filter';
import {
  CompositeRecordParams,
  RecordItem,
  RecordLiveDataResponse,
  RecordMutationResult,
  RecordReducerState,
  RecordRequestData,
  RecordsItems,
  SingleRecord,
} from '@app/interfaces/records';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { httpRequest } from '@app/store/middleware/rest';
import fileDownload from 'js-file-download';
import serverRoutes from '@app/utils/server-routes';
import { AxiosResponseHeaders } from 'axios';

export const initialState: RecordReducerState = {
  items: {
    headers: [],
    records: [],
  },
  liveReportRecords: {
    headers: [],
    records: [],
    total: 0,
  },
  record: null,
  recordTranscription: null,
  compositeRecord: undefined,
  audio: null,
  isPending: false,
  total: 0,
  error: undefined,
  recordIsPending: false,
};

export const getRecordsThunk = {
  compositeRecordThunk: createAsyncThunk<RecordMutationResult, CompositeRecordParams>(
    `RecordsSlice/compositeRecordRequest`,
    async ({ projectId, recordId }) => {
      return await compositeRecordRequest({ projectId, recordId }).then((response) => response);
    },
  ),
  getRecordsThunk: createAsyncThunk<
    RecordsItems & Pick<RecordReducerState, 'total'>,
    GetFilterParams
  >('RecordsSlice/getRecordsThunk', async (data, { rejectWithValue }) => {
    return await getRecordBulk(data)
      .then((response) => response.data)
      .catch((err) => {
        return rejectWithValue(err.response.status + '');
      });
  }),
  getRecordThunk: createAsyncThunk<RecordItem, { id: string }>(
    'RecordsSlice/getRecordThunk',
    async (data, { rejectWithValue }) => {
      return await getRecord(data)
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
  getRecordAudioThunk: createAsyncThunk<
    { data: string; headers: AxiosResponseHeaders },
    { id: string }
  >('RecordsSlice/getRecordAudioThunk', async (data, { rejectWithValue }) => {
    return await getRecordAudio(data)
      .then((response) => ({
        data: response.data,
        headers: response.headers,
      }))
      .catch((err) => {
        return rejectWithValue(err.response.status + '');
      });
  }),
  deleteRecordThunk: createAsyncThunk<RecordItem, { items: string[] }>(
    'RecordsSlice/deleteRecordThunk',
    async (data, { rejectWithValue }) => {
      return await deleteRecord(data)
        .then((response) => response.data)
        .catch((err) => {
          return rejectWithValue(err.response.status + '');
        });
    },
  ),
  getFileRecordThunk: createAsyncThunk<Blob, { recordId: string }>(
    'RecordSlice/getAudioFile',
    async ({ recordId }) => {
      return await getFileRecordRequest({ params: { record_id: recordId } });
    },
  ),
};

const recordsSlice = createSlice({
  name: 'RecordsSlice',
  initialState,
  reducers: {
    setItems(state, { payload }: { payload: RecordsItems }) {
      state.items = payload;
    },
    liveReportRecordsResponse: (state, { payload }: { payload: RecordLiveDataResponse }) => {
      state.liveReportRecords = payload;
    },
    singleRecordResponse: (state, { payload }: { payload: SingleRecord }) => {
      state.record = payload;
    },
    audioResponse: (state, { payload }: { payload: Blob }) => {
      state.audio = payload;
    },
    changeRecordItem(state, action) {
      const index = state.items.records.findIndex(
        (record) => record.record_id === action.payload.record_id,
      );
      if (index === -1) return;
      state.items.records[index] = action.payload;
    },
    deleteRecordItem(state, action) {
      state.items.records.splice(action.payload.index, 1);
    },
    recordsResponse: (state, { payload }) => {
      state.items = payload;
    },
    recordsPending: (state, { payload }) => {
      state.isPending = payload;
    },
    errorResponse: (state, { payload }) => {
      state.error = payload;
    },
    recordTranscriptionResponse: (state, { payload }) => {
      state.recordTranscription = payload;
    },
    deleteRecordResponse: (state, { payload }) => {
      state.items = payload;
      state.audio = null;
      // state.recordTranscription = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRecordsThunk.compositeRecordThunk.pending, (state) => {
      state.isPending = true;
      state.compositeRecord = undefined;
      state.recordTranscription = null;
    });
    builder.addCase(getRecordsThunk.compositeRecordThunk.fulfilled, (state, { payload }) => {
      state.compositeRecord = payload;
      state.isPending = false;
    });
    builder.addCase(getRecordsThunk.compositeRecordThunk.rejected, (state, action) => {
      state.error = action.error.message;
      state.isPending = false;
    });
    builder.addCase(getRecordsThunk.getFileRecordThunk.fulfilled, (state, { payload }) => {
      state.audio = payload;
      state.recordIsPending = false;
    });
    builder.addCase(getRecordsThunk.getFileRecordThunk.pending, (state) => {
      state.recordIsPending = true;
    });
    builder.addCase(getRecordsThunk.getRecordsThunk.fulfilled, (state, { payload }) => {
      state.items = {
        headers: payload.headers,
        records: payload.records,
      };
      state.total = payload.total;
    });
    builder.addCase(getRecordsThunk.getRecordAudioThunk.fulfilled, (_, { payload }) => {
      fileDownload(
        payload.data,
        payload.headers['content-disposition']
          .replace(/"/gim, '')
          .replace('attachment; filename=', ''),
      );
    });
    builder.addCase(getRecordsThunk.getRecordThunk.fulfilled, (state, action) => {
      state.items.records = state.items.records.map((record) => {
        if (record.record_id === action.payload.record_id) {
          record = action.payload;
        }
        return record;
      });
    });
  },
});

export const {
  setItems: setRecordsItems,
  changeRecordItem,
  deleteRecordItem,
  recordsResponse,
  recordsPending,
  liveReportRecordsResponse,
  singleRecordResponse,
  errorResponse,
  audioResponse,
  deleteRecordResponse,
  recordTranscriptionResponse,
} = recordsSlice.actions;

export default recordsSlice.reducer;

export const requestRecords = () =>
  httpRequest({
    url: '/api/front/preset_group/bulk',
    method: 'GET',
    onSuccess: setRecordsItems.type,
  });

export const recordsLiveReportsListRequest = (data: RecordRequestData) =>
  httpRequest({
    url: serverRoutes.live_report_records,
    method: 'POST',
    params: { id: data.id },
    data,
    onSuccess: liveReportRecordsResponse.type,
    onLoading: recordsPending.type,
  });

export const singleRecordRequest = (params: { record_id: string }) =>
  httpRequest({
    url: serverRoutes.record,
    method: 'GET',
    params,
    onSuccess: singleRecordResponse.type,
    onLoading: recordsPending.type,
    onError: errorResponse.type,
  });

export const audioRecordRequest = (params: { record_id: string }) =>
  httpRequest({
    url: serverRoutes.audio,
    method: 'GET',
    params,
    headers: {
      'Content-Type': 'audio/wav',
      'Transfer-Encoding': 'chunked',
    },
    onSuccess: audioResponse.type,
    onLoading: recordsPending.type,
    onError: errorResponse.type,
  });

export const recordTranscriptionRequest = (params: { record_id: string }) =>
  httpRequest({
    url: serverRoutes.transcription,
    method: 'GET',
    params,
    onSuccess: recordTranscriptionResponse.type,
    onLoading: recordsPending.type,
    onError: errorResponse.type,
  });

export const removeRecordRequest = (params: { items: Array<string> }) =>
  httpRequest({
    url: serverRoutes.record,
    method: 'DELETE',
    params,
    onSuccess: deleteRecordResponse.type,
    onLoading: recordsPending.type,
    onError: errorResponse.type,
  });

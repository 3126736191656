import React from 'react';

import { Outlet } from 'react-router-dom';

import HeaderBlock from './blocks/header.block';
import FooterBlock from './blocks/footer.block';

const EmailLayout = () => {
  return (
    <div className="h-[100vh]">
      <HeaderBlock />
      <main className="min-h-[(100vh-200px)] px-[20px]">
        <Outlet />
      </main>
      <FooterBlock />
    </div>
  );
};

export default EmailLayout;

import cn from 'classnames';

export const cellCircledClassName = cn(
  'sticky',
  'after:absolute',
  'after:h-[100%]',
  'after:min-h-[100%]',
  'after:bg-white',
  'after:w-[10px]',
  'after:h-5',
  'before:absolute',
  'before:bg-basic_app_bg',
  'before:h-[100%]',
);

export const columnCellClassName = ({ first, last }: { first: boolean; last: boolean }) =>
  cn(
    'w-full',
    'min-w-[80px]',
    'h-[58px]',
    'bg-white ',
    'block',
    'w-full',
    !last && !first ? 'overflow-hidden' : 'bg-white',
    'h-full',
    'pl-[10px]',
    last &&
      cn(
        'right-[0px] rounded-r-[10px] before:w-[10px] pr-[10px] before:right-[0px] after:right-[0px] after:rounded-r-[10px]',
      ),
    first &&
      cn(
        'left-[0px] rounded-l-[10px] before:w-[10px] pl-[10px] before:left-[0px] after:left-[0px] after:rounded-l-[10px]',
      ),
    (last || first) && cellCircledClassName,
  );

export const columnCellWrapperClassName = (noScroll: boolean) =>
  cn(
    !noScroll && 'overflow-y-auto',
    'scrollHidden',
    'w-full',
    'max-h-full',
    'inline-flex',
    'items-center',
  );

export const columCellInnerClassName = (right: boolean) =>
  cn(
    'w-full',
    'flex-1',
    'min-h-[58px]',
    'py-[14px]',
    'text-sm',
    'inline-flex',
    'h-full',
    'items-center',
    'font-[400]',
    'text-0color',
    'overflow-hidden',
    right ? 'justify-end' : 'justify-between',
    'items-center',
  );

export const columnTitleClassName = ({ truncate, right }: { truncate: boolean; right: boolean }) =>
  cn(
    ' text-black',
    'min-w-[80px]',
    truncate && 'truncate',
    'overflow-hidden',
    'bg-basic_app_bg',
    'inline-flex',
    right && 'justify-end',
    'items-center',
    'uppercase',
    'text-[12px]',
    'font-[600]',
  );

export const titleTableWrapperClassName = cn(
  'w-fit',
  'py-[20px]',
  'bg-basic_app_bg',
  'top-0',
  'z-30',
  'sticky',
  'grid',
);

export const tableTitleFilterWrapperClassName = ({
  filter,
  right,
}: {
  filter?: boolean;
  right: boolean;
}) =>
  cn(
    filter ? 'cursor-pointer inline-flex items-center hover:bg-bg_4' : 'bg-basic_app_bg',
    right && 'justify-end',
    'px-[5px]',
    'py-[8px]',
    'w-full',
    'rounded-[10px]',
    'duration-150',
    'ease-in-out',
    'select-none',
  );

export const skeletonRowClassName = cn(
  'animate-pulse',
  'bg-white/50',
  'h-[58px]',
  'shadow-medium',
  'rounded-[10px]',
  'mb-[4px]',
);

export const skeletonRowTitleClassName = cn(
  'animate-pulse',
  'bg-b_dark',
  'h-[58px]',
  'shadow-medium',
  'rounded-[10px]',
  'mb-[4px]',
);

export const tableTitleCellClassName = cn(
  'text-clip',
  'w-full',
  'p-[4px]',
  'items-center',
  'bg-basic_app_bg',
  'flex',
  'uppercase',
  'text-[12px]',
  'font-[600]',
  'self-stretch',
  'hover:shadow-lg',
);

export const tableTitleCellItemClassName = (dragEnable?: boolean) =>
  cn(
    'cursor-pointer',
    !dragEnable && 'hover:bg-bg_4',
    'py-[8px]',
    'flex',
    'w-full',
    'justify-between',
    'rounded-[10px]',
    'duration-150',
    'ease-in-out',
    'select-none',
  );

export const tableWhiteDateCellClassName = cn(
  'h-[40px]',
  'truncate w-full',
  'flex items-center justify-center',
  'text-[14px] font-400 leading-[30px]',
);

export const sortActiveClassName = (isActive: boolean) =>
  cn(isActive ? 'text-action' : 'text-3color');

import React, { useEffect, useState } from 'react';

import { Box, Button, EasyFilterView } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { useParams } from 'react-router-dom';
import { GroupSharedStatus } from '@app/interfaces/analytics';

import {
  useEditLiveReportMutation,
  useGetLiveReportSettingsQuery,
} from '@app/store/api/live-reports.api';

import { useGetProjectMetricsListQuery } from '@app/store/api/metrics.api';

import { Filter, Range } from '@app/components/ui/easy-filter/types';

import ReportNavigatorBlock from './blocks/report-navigator.block';
import ReportHeaderWithStatusBlock from './blocks/report-header-with-status.block';
const emptyFilter: Filter & Range = {
  range: {
    type: 'n',
    parameters: [],
  },
  filter: [],
};
const ReportSettingsPage = () => {
  const { id } = useParams();
  const { t } = useTranslation('pages.reports');
  // api
  const { data: liveReport } = useGetLiveReportSettingsQuery({ id: id || '' }, { skip: !id });
  const { data: metricList, isLoading: projectMetricLoading } = useGetProjectMetricsListQuery(
    {
      project_id: liveReport?.project.project_id || '',
    },
    { skip: !liveReport?.project.project_id },
  );
  const [editLiveReport] = useEditLiveReportMutation();
  // useState
  const [newFilterValues, changeNewFilterValues] = useState<Filter & Range>();

  useEffect(() => {
    if (!liveReport) return;
    changeNewFilterValues(liveReport?.filter);
  }, [liveReport]);

  // handlers
  function onChangeFilterHandler(data: Filter & Range) {
    changeNewFilterValues(data);
  }
  function getFilteringKeys() {
    return metricList?.map((metric) => ({
      id: metric.metric_id,
      title: metric.name,
      type_value: metric.result_value_type,
    }));
  }

  function handleSaveFilter() {
    if (!liveReport || !newFilterValues) return;
    editLiveReport({
      params: { id: liveReport.live_report_id },
      body: { name: liveReport.name, filter: newFilterValues },
    });
  }
  function saveButtonDisabled() {
    if (!liveReport?.project?.owner && liveReport?.project?.shared === GroupSharedStatus.VIEW)
      return true;
    if (liveReport?.filter === newFilterValues) return true;
    // if (!newFilterValues?.filter.length) return true;
  }
  function resetButtonDisabled() {
    if (!liveReport?.project?.owner && liveReport?.project?.shared === GroupSharedStatus.VIEW)
      return true;
    // if (!newFilterValues?.filter.length) return true;
    if (liveReport?.filter === newFilterValues) return true;
  }
  function deleteButtonDisabled() {
    if (!liveReport?.project?.owner && liveReport?.project?.shared === GroupSharedStatus.VIEW)
      return true;
    if (!liveReport?.filter?.filter.length) return true;
    if (newFilterValues?.filter.length === 0) return true;
  }
  function handleResetFilter() {
    changeNewFilterValues(liveReport?.filter);
  }
  function handleDeleteFilter() {
    changeNewFilterValues(emptyFilter);
  }
  // jsx markup
  const titleMarkup = <h2 className="text-1color font-bold">{t('filter_settings')}</h2>;
  const footerMarkup = (
    <div className="w-full flex justify-between">
      <div>
        <Button
          onClick={handleSaveFilter}
          disabled={saveButtonDisabled()}
          label={t('button_settings.save')}
        />
        <Button
          fill="linked"
          variant="primary"
          label={t('button_settings.cancel')}
          onClick={handleResetFilter}
          disabled={resetButtonDisabled()}
        />
      </div>
      <div>
        <Button
          icon="TrashIcon"
          fill="linked"
          variant="danger"
          onClick={handleDeleteFilter}
          label={t('button_settings.remove')}
          disabled={deleteButtonDisabled()}
        />
      </div>
    </div>
  );

  // if (liveReportLoading) return <SkeletonPageLoading />;
  return (
    <div>
      <ReportHeaderWithStatusBlock />
      <ReportNavigatorBlock />
      <Box className="inline-flex w-full justify-between flex-col">
        <div className="py-[30px] px-[34px]">
          <EasyFilterView
            dataIdStartDate="reports-settings-date-from"
            dataIdEndDate="reports-settings-date-to"
            data={newFilterValues}
            isLoading={projectMetricLoading}
            footerMarkup={footerMarkup}
            titleMarkup={titleMarkup}
            onChangeHandler={onChangeFilterHandler}
            keysForFiltering={getFilteringKeys() || []}
            disabledChangeButton={
              !liveReport?.project?.owner && liveReport?.project?.shared === GroupSharedStatus.VIEW
            }
          />
        </div>
      </Box>
    </div>
  );
};

export default ReportSettingsPage;

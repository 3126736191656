const forms = require('./forms');
const humanize = require('./humanize');
const breadcrumbs = require('./breadcrumbs');
const sharedStatus = require('./sharedStatus');
const pageTitle = require('./pageTitle');
const uiPopupHints = require('./popup-hints-for-ui');
const constants = require('./constants');

module.exports = {
  forms,
  humanize,
  breadcrumbs,
  sharedStatus,
  pageTitle,
  uiPopupHints,
  constants
};

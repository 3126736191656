import useTranslation from '@app/hooks/use-translation';
import React, { useCallback, useEffect } from 'react';
import {
  useAddNewDashboardMutation,
  useGetDashboardQuery,
  useGetDashboardsListQuery,
} from '@app/store/api/dashboard-page.api';
import { Button } from '@ui';
import Dashboard from '@app/pages/dashboards/blocks/dashboard';
import { DashboardsListType, DashboardsSettingsType } from '@app/interfaces/dashboards.type';
import DashboardPageSkeleton from '@app/pages/dashboards/blocks/dashboard-page.skeleton';
import { useAppSelector } from '@app/store/store';
import { useUpdateUserSettingsMutation } from '@app/store/api/user-settings.api';

export const DashboardPage = () => {
  const { data, isLoading: loading } = useGetDashboardsListQuery();
  const { userSettings } = useAppSelector((state) => state.userSettings);
  const [createDashboard, { isLoading }] = useAddNewDashboardMutation();
  const { t } = useTranslation('pages.dashboard');
  const { currentData, isFetching } = useGetDashboardQuery(userSettings?.currentDashboardId || '', {
    skip: !userSettings?.currentDashboardId,
  });
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const dashboardExists = useCallback((id: string, arr: DashboardsListType[]) => {
    const dashboardsIdList: string[] = [];
    for (const dashboard of arr) {
      dashboardsIdList.push(dashboard.dashboard_id);
    }
    return dashboardsIdList.includes(id);
  }, []);

  useEffect(() => {
    const idFromStorage = userSettings?.currentDashboardId;
    if (idFromStorage && data) {
      if (!dashboardExists(idFromStorage, data?.dashboard)) {
        updateUserSettings({
          ...userSettings,
          currentDashboardId: data?.dashboard?.[0].dashboard_id,
        });
      }
    } else {
      data?.dashboard.length &&
        updateUserSettings({
          ...userSettings,
          currentDashboardId: data?.dashboard?.[0].dashboard_id,
        });
    }
  }, [dashboardExists, data, updateUserSettings, userSettings]);

  const createNewDashboard = (data: { name: string; shared: boolean }) => {
    createDashboard(data).then((data) => {
      const { data: DData } = data as unknown as { data: DashboardsSettingsType };
      updateUserSettings({
        ...userSettings,
        currentDashboardId: DData.dashboard_id,
      });
    });
  };
  const createFirstDashboard = () => {
    updateUserSettings({
      ...userSettings,
      currentDashboardId: null,
    })
      .unwrap()
      .then(() => {
        createNewDashboard({
          name: t('system.new_dashboard_system_name', { name: '1' }),
          shared: false,
        });
      });
  };

  if (loading || isFetching || isLoading) return <DashboardPageSkeleton />;
  return (
    <>
      {data?.dashboard?.length === 0 || userSettings?.currentDashboardId === null ? (
        <div className="flex items-center justify-center h-full">
          <Button
            data-id="create-first-dashboard"
            icon="CirclePlusIcon"
            onClick={() => createFirstDashboard()}
            label={t('system.add_new_dashboard')}
          />
        </div>
      ) : (
        <Dashboard currentDashboard={currentData} createNewDashboard={createNewDashboard} />
      )}
    </>
  );
};

module.exports = {
  success_create_metric: 'Метрика добавлена',
  composite_formula_name: 'формула',
  composite_name: 'Имя метрики',
  composite_success_edit_metric: 'Метрика отредактирована',
  composite_fail_edit_metric: 'Не удалось отредактировать метрику. Возможно формула составлена не верно и не может быть сохранена, просим ознакомится с инструкцией',
  composite_fail_create_metric: 'Не удалось создать метрику. Возможно формула составлена не верно и не может быть сохранена, просим ознакомится с инструкцией',
  composite_fail_metric_formula: 'Неверная формула',
  error_metric_build: 'Ошибка загрузки метрики, попробуйте еще раз',
  select: {
    composite_visualization: {
      label: 'Формат отображения',
      options: {
        composite_visualization_native: 'Стандартный',
        composite_visualization_score: 'Бар от 1 до 5',
        composite_visualization_score100: 'Бар от 0 до 100',
      },
    },
    composite_metric_type: {
      labelDisplayType: 'Выбор типа отображения',
      label: 'Выбрать тип результата',
      options: {
        str: 'Строка',
        num: 'Число',
      },
    },
  },
};

import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import cn from 'classnames';

import { CircleButton, Modal, PageHeader, PermissionAccessWrapper, Skeleton } from '@ui';
import { TrashIcon } from '@icons';
import RecordMeta from '@app/components/audio-build/record-meta';
import AudioChat from '@app/components/audio-build/audio-chat';
import { useNavigate } from 'react-router-dom';
import useTranslation from '@app/hooks/use-translation';
import connector, { PropsFromRedux } from '@app/utils/store';
import { useAudioContext } from '@app/components/audio-build/record-provider';
import WaveAudio from '@app/components/audio-build/waveaudio/wave-audio.component';
import { CalculatedRecordMetric } from '@app/interfaces/records';

import { userPermissions } from '@app/utils/user-permissions';

import RecordMetric from './record-metric';

const AudioContainer: FC<PropsFromRedux> = (props) => {
  const {
    removeRecordRequest,
    recordsState,
    getFileRecordThunk,
    compositeRecordRequest,
    recordMetaRequest,
    metaState,
  } = props;
  const { recordId, projectId } = useAudioContext();
  const { compositeRecord, audio, isPending, recordIsPending } = recordsState;
  const { isPending: isPendingMeta, recordMeta } = metaState;
  const [currentMetricByRegionId, setCurrentMetricByRegionId] = useState<number>(0);
  const [activeMetric, changeActiveMetric] = useState<CalculatedRecordMetric[string]>();
  const [isFullScreen, setFullScreen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation('components.audio');
  const [activeChatTiming, setActiveChatTiming] = useState<
    { from: number; to: number } | undefined
  >();
  const [activeMessageId, setActiveMessageId] = useState<Array<string> | null>(null);

  useEffect(() => {
    recordMetaRequest({ record_id: recordId });
  }, [recordId, recordMetaRequest]);

  useEffect(() => {
    compositeRecordRequest({ recordId, projectId });
  }, [compositeRecordRequest, projectId, recordId]);

  useEffect(() => {
    getFileRecordThunk({ recordId });
  }, [getFileRecordThunk, projectId, recordId]);
  function removeRecord() {
    if (recordId) {
      removeRecordRequest({ items: [recordId] });
      navigate(window.location.pathname.split('/').splice(0, 3).join('/'));
    }
  }

  async function handleCopyText() {
    // eslint-disable-next-line compat/compat
    await window.navigator.clipboard.writeText(window.location.href);
    toast.success(t('link_copied'));
  }
  function handleChangeActiveRecordMetric(metricId: string | undefined, type: 'once' | 'all') {
    switch (type) {
      case 'once': {
        if (metricId && compositeRecord) {
          const currentIds = compositeRecord?.calculatedRecordMetric[metricId]?.settings?.ids || [];
          if (compositeRecord.calculatedRecordMetric[metricId] !== activeMetric) {
            changeActiveMetric(compositeRecord.calculatedRecordMetric[metricId]);
            setCurrentMetricByRegionId(0);
            currentIds &&
              setActiveMessageId([
                String(compositeRecord?.calculatedRecordMetric[metricId]?.settings?.messageId?.[0]),
              ]);
          } else {
            let currentRegion = 0;
            if (currentIds.length - 1 > currentMetricByRegionId) {
              setCurrentMetricByRegionId((prevState) => prevState + 1);
              currentRegion = currentMetricByRegionId + 1;
            } else {
              setCurrentMetricByRegionId(0);
            }
            setActiveMessageId([
              compositeRecord?.calculatedRecordMetric[metricId]?.settings?.messageId?.[
                currentRegion
              ] || '',
            ]);
          }
        }
        break;
      }
      case 'all': {
        if (metricId !== undefined && compositeRecord?.calculatedRecordMetric[metricId]) {
          changeActiveMetric(compositeRecord.calculatedRecordMetric[metricId]);
        } else {
          changeActiveMetric(undefined);
        }
        break;
      }
    }
  }

  const renderMetricAndMetaBlocks = (
    <div
      className={cn(
        'mt-[12px]',
        isFullScreen && 'order-2 basis-1/2 pr-[10px] items-center flex-row w-full',
      )}
    >
      <div className="">
        {isPendingMeta ? <Skeleton width="100%" height={62} /> : <RecordMeta meta={recordMeta} />}
      </div>
      {projectId &&
        compositeRecord?.calculatedRecordMetric &&
        Object.keys(compositeRecord?.calculatedRecordMetric).length > 0 && (
          <div className="my-[12px]">
            <RecordMetric
              onChangeActiveMetric={handleChangeActiveRecordMetric}
              projectMetric={compositeRecord.calculatedRecordMetric}
            />
          </div>
        )}
    </div>
  );
  return (
    <div className="">
      <PageHeader
        label={
          <div className="text-speech_analitics">
            <div className="mb-0">{t('audio')}</div>
            <div className="text-speech_analitics text-[13px]">
              {isPendingMeta ? (
                <Skeleton height="14px" width="100px" />
              ) : (
                <span
                  className="mb-[2px] pl-[0px] cursor-pointer hover:opacity-80"
                  onClick={handleCopyText}
                >
                  {(recordMeta && recordMeta?.record_name) || '-'}
                </span>
              )}
              <CircleButton
                hintTitle={t('popup_hints.copy_link')}
                hintPlace="top"
                icon="CopyIcon"
                size={15}
                className="text-defaultColor ml-[14px]"
                onClick={handleCopyText}
              />
            </div>
          </div>
        }
      >
        <PermissionAccessWrapper
          permissions={'action'}
          tag={userPermissions.action.actionDeleteRecord}
        >
          <button
            className="text-basic_red p-[5px] inline-flex items-center"
            onClick={setIsDeleteOpen.bind(null, true)}
          >
            <TrashIcon size={15} className="text-basic_red mr-[5px]" />
            {t('delete')}
          </button>
        </PermissionAccessWrapper>
      </PageHeader>
      <div className={cn('flex  w-full', isFullScreen ? ' flex-wrap' : ' gap-[12px]')}>
        <div
          className={cn(
            isFullScreen ? 'basis-[100%]  flex-1 w-[100%]' : 'basis-[48%] order-1 w-full',
          )}
        >
          {!recordIsPending && audio && compositeRecord?.dialog_words ? (
            <div className={cn(isFullScreen ? 'basis-2/2' : 'basis-1/2')}>
              <WaveAudio
                recordName={recordMeta?.record_name}
                wordsTimeList={compositeRecord?.wordsTimeList}
                audio={audio}
                emotionTrack={compositeRecord.emotionTrack}
                activeAndChangeCurrentRegion={[currentMetricByRegionId, setCurrentMetricByRegionId]}
                channelSettings={compositeRecord?.dialogChannels}
                activeMetricWords={activeMetric}
                onChangeActiveMessageId={setActiveMessageId}
                activeMessageId={activeMessageId}
                activeChartTiming={activeChatTiming}
                changeFullScreen={setFullScreen.bind(null, (prev) => !prev)}
                dialogs={compositeRecord?.dialogs}
              />
            </div>
          ) : (
            <Skeleton height={286} width="100%" label={t('skeleton_loading_label')} />
          )}
          {!isFullScreen && renderMetricAndMetaBlocks}
        </div>
        <div
          className={cn(
            'basis-1/2 sticky top-[12px] h-full',
            isFullScreen ? 'order-3 mt-[12px]' : ' order-2',
          )}
        >
          {isPending && !compositeRecord ? (
            <Skeleton height="100%" width="100%" />
          ) : (
            <AudioChat
              activeMetric={activeMetric}
              activeMessageId={activeMessageId}
              onChangeActiveChartTiming={setActiveChatTiming}
              transcription={compositeRecord?.dialogs}
            />
          )}
        </div>
        {isFullScreen && renderMetricAndMetaBlocks}
      </div>
      <Modal
        id="remove_record"
        value={isDeleteOpen}
        size="xs"
        title={t('delete_record')}
        setValue={setIsDeleteOpen}
        onApply={removeRecord}
        variant="removal"
      >
        {t('record_will_deleted')}
      </Modal>
    </div>
  );
};

export default connector(AudioContainer);

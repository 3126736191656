import React, { FC, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import cn from 'classnames';

import AudioChatItem from '@app/components/audio-build/audio-chat/audio-chat-item';
import AudioChatEnd from '@app/components/audio-build/audio-chat/audio-chat-end';
import useTranslation from '@app/hooks/use-translation';
import { useAudioContext } from '@app/components/audio-build/record-provider';
import connector, { PropsFromRedux } from '@app/utils/store';
import { CircleButton, DropMenu, TooltipWrapper } from '@ui';
import useDownloadLink from '@app/hooks/use-download-link';
import { getFileChatRecordRequest } from '@app/api/record.api';
import { CalculatedRecordMetric, Dialog } from '@app/interfaces/records';
import AudioChatLoadingItem from '@app/components/audio-build/audio-chat/audio-chat-loading-item';

import activeMetricBuilder from '@app/components/audio-build/audio-chat/active-text-builder';

import { CHAT_VIEW } from '@app/utils/constants';

import { chartWrapperClassName, chatContainerClassName } from './audio-chat.styles';

type AudioChatProps = {
  transcription?: Array<Dialog>;
  activeMetric?: CalculatedRecordMetric[string];
  onChangeActiveChartTiming: React.Dispatch<
    React.SetStateAction<{ from: number; to: number } | undefined>
  >;
  activeMessageId: Array<string> | null;
} & PropsFromRedux;

const AudioChat: FC<AudioChatProps> = (props) => {
  const { onChangeActiveChartTiming, transcription, activeMessageId, activeMetric } = props;
  const scrollRef = useRef<HTMLDivElement>(null);
  const { recordId, toggleEmotions } = useAudioContext();
  const { t } = useTranslation('components.audio');
  const downloadTranscriptionFile = useDownloadLink();
  const [isDialogType, toggleDialogType] = useState<boolean>(
    Boolean(Number(localStorage.getItem(CHAT_VIEW) || 0)),
  );
  useEffect(() => {
    if (activeMessageId?.length) {
      const activeDiv: HTMLDivElement | null = document?.querySelector(
        `#${String(activeMessageId[0])}`,
      );
      if (activeDiv && scrollRef.current) {
        scrollRef.current.scrollTo({ top: activeDiv.offsetTop, behavior: 'smooth' });
      }
    }
  }, [activeMessageId]);

  const iconButtonClassName = cn('text-defaultColor');

  function handleChatClickTimeEvent({ from, to }: { from: number; to: number }) {
    onChangeActiveChartTiming({ from, to });
  }

  const renderMessage = () => {
    let lastActiveDistance = 0;
    if (transcription) {
      return (
        <>
          {transcription.map((dialogItem, index) => {
            const { phrase, lastDistance } = activeMetricBuilder({
              dialog: dialogItem,
              activeMetricSetup: activeMetric || null,
              lastDistance: { distance: lastActiveDistance, owner: dialogItem.channel_id },
            });
            lastActiveDistance = lastDistance.coloredCountIterator;
            return (
              <AudioChatItem
                key={index}
                id={dialogItem.id}
                isDialogType={isDialogType}
                active={activeMessageId?.includes(dialogItem.id) || false}
                phrase={phrase}
                emotion={dialogItem.emotion}
                toggleEmotions={toggleEmotions}
                duration={{ from: dialogItem.from, to: dialogItem.to }}
                channelId={dialogItem.channel_id}
                channelConfig={dialogItem.channel_config}
                onClick={() =>
                  handleChatClickTimeEvent({ from: dialogItem.from, to: dialogItem.to || 5000 })
                }
              />
            );
          })}
        </>
      );
    } else
      return (
        <div>
          <AudioChatLoadingItem count={4} />
        </div>
      );
  };

  function handleDownloadTranscription(format: string) {
    getFileChatRecordRequest({
      record_id: recordId,
      format: format as 'TXT' | 'CSV',
    })
      .then((data) => {
        if (format === 'TXT') {
          downloadTranscriptionFile({
            name: `${t('audio')}#${recordId}`,
            format: format.toLowerCase(),
            file: data,
          });
        } else {
          // download as urf-8-bom
          const a = document.createElement('a');
          document.body.appendChild(a);
          const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // add here
          const blob = new Blob([bom, data], { type: 'octet/stream' }), // add bom
            url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = `${t('audio')}#${recordId}.csv`;
          a.click();
          window.URL.revokeObjectURL(url);
        }
      })
      .catch(() => {
        toast.error(t('translation_download_false'));
      });
  }

  function toggleDialogTypeHandler() {
    toggleDialogType((prevState) => !prevState);
    localStorage.setItem(CHAT_VIEW, String(Number(!isDialogType)));
  }

  return (
    <div className={chartWrapperClassName}>
      <div className={chatContainerClassName}>
        <div className="text-0color text-lg font-bold ">{t('dialog')}</div>
        <div className="inline-flex items-center">
          {/*<CircleButton*/}
          {/*  icon="ZoomIcon"*/}
          {/*  size={16}*/}
          {/*  className={classNames(iconButtonClassName, 'mr-[19px]')}*/}
          {/*/>*/}
          <TooltipWrapper content={t('chat_type')} id="change_chat_view_button">
            <CircleButton
              icon="SortIcon"
              size={17}
              className={iconButtonClassName}
              onClick={toggleDialogTypeHandler}
            />
          </TooltipWrapper>
          <DropMenu
            dropSize={70}
            onClick={handleDownloadTranscription}
            menu={[
              { title: 'TXT', key: 'TXT' },
              { title: 'CSV', key: 'CSV' },
            ]}
          >
            <CircleButton
              hintTitle={t('popup_hints.download_transcript')}
              icon="DownloadCloudIcon"
              size={19}
              className={iconButtonClassName}
            />
          </DropMenu>
        </div>
      </div>
      <div className="h-[calc(100%-5rem)] overflow-hidden">
        <div
          ref={scrollRef}
          className={cn(
            !isDialogType && 'pl-[60px] ml-[20px]',
            'px-[27px] h-full overflow-y-auto overflow-hidden',
          )}
        >
          <ol
            className={cn(
              !isDialogType && 'ml-[180px] border-l px-[27px] border-gray-200',
              'relative block  p-4',
            )}
          >
            {renderMessage()}
            {transcription && <AudioChatEnd isEmpty={!transcription?.length} />}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default connector(AudioChat);

import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

import serverRoutes from '@app/utils/server-routes';

import {
  CreateNewReportGraphType,
  NewLiveReportGraph,
  ReportChart,
  ReportListChart,
} from '@app/interfaces/report.type';

import { EditGraphLayoutType } from '@app/interfaces/dashboards.type';

// import { chartsGraphDataApi } from '@app/store/api/graph-data.api';

import { baseQuery } from '../baseQuery';

export const liveReportsChartsApi = createApi({
  reducerPath: 'liveReportsChartsApi',
  tagTypes: ['singleLiveReportChart'],
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getLiveReportsGraphList: build.query<(ReportListChart & ReportChart)[], string | undefined>({
      async queryFn(arg, queryApi, extraOptions, fetchWithBQ) {
        const graphsArray: (ReportListChart & ReportChart)[] = [];
        const graphsInLiveReport = (await fetchWithBQ({
          url: serverRoutes.charts,
          params: { live_report_id: arg },
        })) as unknown as { data: { live_report_graph_list: ReportListChart[] } };
        const data = graphsInLiveReport.data.live_report_graph_list || [];
        for (const graph of data) {
          const graphData = (await fetchWithBQ({
            url: serverRoutes.chartData,
            method: 'POST',
            body: { id: graph.graph_id },
          })) as unknown as {
            data: { data: ReportChart };
            error?: {
              status: number;
              data: {
                data: ReportChart;
              };
            };
          };
          if (graphData.error?.status) {
            switch (graphData.error?.status) {
              case 500:
                graphsArray.push({
                  ...graphData.error.data.data,
                  layout: { ...graph.layout, i: graph.graph_id },
                  errorStatus: graphData.error?.status,
                });
                break;
              default:
                break;
            }
          } else {
            graphData.data &&
              graphsArray.push({
                ...graphData.data.data,
                layout: { ...graph.layout, i: graph.graph_id },
              });
          }
        }
        return graphsArray
          ? { data: graphsArray as (ReportListChart & ReportChart)[] }
          : { error: { error: '123', data: [] } as FetchBaseQueryError };
      },
    }),

    getLiveReportGraph: build.query<NewLiveReportGraph, { params: { id: string } }>({
      query: ({ params }) => ({
        url: serverRoutes.chart,
        params,
      }),
      providesTags: ['singleLiveReportChart'],
    }),
    addNewLiveReportGraph: build.mutation<NewLiveReportGraph, CreateNewReportGraphType>({
      async queryFn(arg, { dispatch }, extraOptions, fetchWithBQ) {
        const createGraphData = (await fetchWithBQ({
          url: serverRoutes.chart,
          method: 'POST',
          body: arg,
        })) as unknown as { data: NewLiveReportGraph };
        const graphData = (await fetchWithBQ({
          url: serverRoutes.chartData,
          method: 'POST',
          body: { id: createGraphData.data.graph_id },
        })) as unknown as { data: { data: ReportChart } };
        const formattedChartObject = {
          ...graphData.data.data,
          layout: { ...createGraphData.data.layout, i: createGraphData.data.graph_id },
        };
        dispatch(
          liveReportsChartsApi.util?.updateQueryData(
            'getLiveReportsGraphList',
            arg.live_report_id || '',
            (draft) => {
              draft.push(formattedChartObject);
            },
          ),
        );

        return createGraphData
          ? { data: createGraphData.data }
          : { error: { error: '123', data: [] } as FetchBaseQueryError };
      },
    }),
    editNewLiveReportGraph: build.mutation<
      NewLiveReportGraph,
      { params: { id: string }; body: CreateNewReportGraphType; liveReportId?: string }
    >({
      async queryFn({ body, params, liveReportId }, { dispatch }, extraOptions, fetchWithBQ) {
        const editGraphData = (await fetchWithBQ({
          url: serverRoutes.chart,
          method: 'PUT',
          body,
          params,
        })) as unknown as { data: NewLiveReportGraph };
        const graphData = (await fetchWithBQ({
          url: serverRoutes.chartData,
          method: 'POST',
          body: { id: editGraphData.data.graph_id },
        })) as unknown as { data: { data: ReportChart } };

        const formattedChartObject = {
          ...graphData.data.data,
          layout: { ...editGraphData.data.layout, i: editGraphData.data.graph_id },
        };
        dispatch(
          liveReportsChartsApi.util?.updateQueryData(
            'getLiveReportsGraphList',
            liveReportId || '',
            (draft) => {
              return draft.map((draftChart) => {
                if (draftChart.graph_id === editGraphData.data.graph_id) {
                  return formattedChartObject;
                }
                return draftChart;
              });
            },
          ),
        );

        return editGraphData
          ? { data: editGraphData.data }
          : { error: { error: '123', data: [] } as FetchBaseQueryError };
      },
      invalidatesTags: ['singleLiveReportChart'],
    }),
    deleteNewLiveReportGraph: build.mutation({
      query: (params: { id: string; live_report_id?: string }) => ({
        url: serverRoutes.chart,
        method: 'DELETE',
        params,
      }),
      onCacheEntryAdded: async (arg, { dispatch, cacheDataLoaded }) => {
        await cacheDataLoaded;
        dispatch(
          liveReportsChartsApi.util?.updateQueryData(
            'getLiveReportsGraphList',
            arg.live_report_id,
            (draft) => {
              return draft.filter((draftChart) => draftChart.graph_id !== arg.id);
            },
          ),
        );
      },
    }),
    // layout
    editLiveReportGraphLayout: build.mutation<
      NewLiveReportGraph,
      { body: EditGraphLayoutType[]; liveReportId: string }
    >({
      query: ({ body, liveReportId }) => ({
        url: serverRoutes.chartLayout,
        method: 'PUT',
        body: {
          live_report_id: liveReportId,
          layout: {
            live_report_graph_order: body,
          },
        },
      }),
      onCacheEntryAdded: async ({ liveReportId, body }, { dispatch, cacheDataLoaded }) => {
        await cacheDataLoaded.then(() => {
          dispatch(
            liveReportsChartsApi.util?.updateQueryData(
              'getLiveReportsGraphList',
              liveReportId,
              (draft) => {
                return draft.map((draftChart) => ({
                  ...draftChart,
                  layout: {
                    ...draftChart.layout,
                    ...(body.find((layoutItem) => layoutItem.graph_id === draftChart.graph_id)
                      ?.layout || draftChart.layout),
                  },
                }));
              },
            ),
          );
        });
      },
    }),
  }),
});

export const {
  useGetLiveReportsGraphListQuery,
  useGetLiveReportGraphQuery,
  useLazyGetLiveReportsGraphListQuery,
  useAddNewLiveReportGraphMutation,
  useDeleteNewLiveReportGraphMutation,
  useEditLiveReportGraphLayoutMutation,
  useEditNewLiveReportGraphMutation,
} = liveReportsChartsApi;

import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { PageHeader, Select, Skeleton } from '@ui';
import useTranslation from '@app/hooks/use-translation';
import { OptionItem } from '@app/components/ui/select/select.type';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { useGetAnalyticProjectsForLastProjectsQuery } from '@app/store/api/analytics-projects.api';

const ReportHeaderBlock = () => {
  const { t } = useTranslation('pages.reports');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { data: projects, isLoading } = useGetAnalyticProjectsForLastProjectsQuery();

  function handleSelectOnChange(projectId: string | number) {
    navigate(`/${navigationRoutes.reports}?project=${projectId}`);
    localStorage.setItem(
      'SELECTED_REPORT_PROJECT_ID',
      `/${navigationRoutes.reports}?project=${projectId}`,
    );
  }

  const renderSelectProjectOptions: Array<OptionItem> = projects
    ? projects?.map(({ name, project_id }) => ({
        title: name,
        value: project_id,
      }))
    : [];

  const renderSelectProject = isLoading ? (
    <Skeleton width={300} height={32} />
  ) : (
    <Select
      size={300}
      onChange={handleSelectOnChange}
      placeholder={t('select.all_projects')}
      options={renderSelectProjectOptions}
      defaultValue={searchParams.get('project') || undefined}
      isEmpty
      dropWidth={296}
    />
  );

  return (
    <PageHeader label={t('title')} icon="MetricIcon">
      <div className="inline-flex font-[500] text-[15px] text-1color items-center">
        {t('choose_project')}:<div className="ml-[11px]">{renderSelectProject}</div>
      </div>
    </PageHeader>
  );
};

export default ReportHeaderBlock;

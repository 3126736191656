import { createContext, Dispatch, SetStateAction, useContext } from 'react';

export type AudioContext = {
  withMetric: boolean;
  recordId: string;
  projectId: string | undefined;
  toggleEmotions: boolean;
  changeToggleEmotions: Dispatch<SetStateAction<boolean>>;
};
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const audioContextProvider = createContext<AudioContext>(null!);
export const useAudioContext = () => useContext(audioContextProvider);

export default audioContextProvider;

import React, { FC } from 'react';

import { CircleButton, TooltipWrapper } from '@ui';
import { KnownMetricsCases } from '@app/interfaces/records';
import cn from 'classnames';

export type MetricItemProps = {
  isActive?: boolean;
  data: KnownMetricsCases[string]['known_metric'];
  onChangeMetricItem?: ({ caseName, value }: { caseName: string; value: string | number }) => void;
};

const MetricItem: FC<MetricItemProps> = (props) => {
  const { data, isActive = false, onChangeMetricItem } = props;
  const { name, value, metric_id, color, type: metricType } = data;
  const renderMetricTypeContent = metricType === 'find-words' && (
    <div className="flex items-center justify-end gap-x-[2px] w-full h-full">
      <div className="flex pt-[2px] w-[20px] h-[20px] items-center justify-center">
        <CircleButton
          icon="SearchInTextIcon"
          size={19}
          className="text-3color"
          onClick={onChangeMetricItem?.bind(null, {
            caseName: `${metricType}_once`,
            value: metric_id,
          })}
        />
      </div>
      <div
        className={cn(
          isActive && 'bg-speech_analitics_light',
          'rounded-[6px] flex pt-[2px] w-[20px] h-[20px] items-center justify-center',
        )}
      >
        <CircleButton
          onClick={onChangeMetricItem?.bind(null, { caseName: metricType, value: metric_id })}
          icon="SplitedIcon"
          size={15}
          className="text-speech_analitics"
        />
      </div>
    </div>
  );

  const valueTypeRender =
    typeof value === 'string' ? (
      <div className="text-[13px] col-span-2 text-2 color h-full text-left">
        <TooltipWrapper content={String(value)} id={String(value)} className="text-right">
          <div className="w-full ">{value}</div>
        </TooltipWrapper>
      </div>
    ) : (
      <div className={cn(metricType !== 'find-words' && 'col-span-2', 'w-full items-center')}>
        <TooltipWrapper content={String(value)} id={String(value)} className="text-right">
          <div className="content-end text-right flex w-full justify-end h-full items-center">
            <div className="relative w-[20px]">
              <div
                className="w-[20px] h-[20px] opacity-10 rounded-[3px]"
                style={{ backgroundColor: `#${color}` || '#000' }}
              />
              <div
                className="absolute top-0 left-0 h-[20px] text-[12px] flex justify-center items-center w-[20px] opacity-100"
                style={{ color: `#${color}` || '#000' }}
              >
                {value}
              </div>
            </div>
          </div>
        </TooltipWrapper>
      </div>
    );

  return (
    <div className="grid grid-cols-[1fr_250px_50px] gap-2 px-[26px] items-center selected-none  hover:bg-basic_app_bg rounded-[3px] py-[5px] transition w-full content-center ">
      <div className="text-1color text-[14px] truncate w-[100%]">
        <TooltipWrapper content={String(name)} id={String(name)}>
          {name}
        </TooltipWrapper>
      </div>
      {valueTypeRender}
      {renderMetricTypeContent}
    </div>
  );
};
export default MetricItem;

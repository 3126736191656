import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import metaSlice from '@app/store/reducers/meta.slice';
import { recognitionSettingsApi } from '@app/store/api/recognition-rules-settings-page.api';
import { asrModelApi } from '@app/store/api/asr_model.api';
import { dashboardApi } from '@app/store/api/dashboard-page.api';
import { dashboardChartsApi } from '@app/store/api/dashboard-charts.api';
import { chartsGraphDataApi } from '@app/store/api/graph-data.api';
import { metricRecordsApi } from '@app/store/api/metric-records.api';
import { analyticsProjectApi } from '@app/store/api/analytics-projects.api';

import { presetTemplateApi } from '@app/store/api/preset-template.api';

import { customAliasesApi } from '@app/store/api/custom-aliases.api';

import { metricTemplateApi } from '@app/store/api/metric-template.api';

import { metricsApi } from '@app/store/api/metrics.api';

import { dictionariesApi } from '@app/store/api/dictionaries.api';

import { integrationSettingsApi } from '@app/store/api/intergation-settings.api';

import { liveReportsApi } from '@app/store/api/live-reports.api';

import { liveReportsChartsApi } from '@app/store/api/live-reports-charts.api';

import { analyticsProjectPresetApi } from '@app/store/api/analytics-project-preset.api';

import { wordsNGramApi } from '@app/store/api/get-words-n-gramm.api';

import userSettingsSlice from '@app/store/reducers/user-settings.slice';

import { userSettingsApi } from '@app/store/api/user-settings.api';

import recordsApi from './api/records.api';
import restMiddleware from './middleware/rest';
import recordsSlice from './reducers/recordsSlice';
import { RootState } from './../interfaces/common';
import metricSlice from './reducers/metric-slice';
import audioChannelsSlice from './reducers/audio-channels.slice';
import { userInfoApi } from './api/get-user-info.api';
import { channelsInfoApi } from './api/get-channels-info.api';
import { wordsCloudApi } from './api/get-words-cloud.api';
import { auditLogsApi } from './api/audit-logs.api';
import { commonSettingsApi } from './api/common-settings-page.api';
import { aliasSettingsApi } from './api/alias-settings-page.api';

const store = configureStore({
  reducer: {
    records: recordsSlice,
    metric: metricSlice,
    meta: metaSlice,
    audioChannels: audioChannelsSlice,
    userSettings: userSettingsSlice,
    [userInfoApi.reducerPath]: userInfoApi.reducer,
    [channelsInfoApi.reducerPath]: channelsInfoApi.reducer,
    [wordsCloudApi.reducerPath]: wordsCloudApi.reducer,
    [auditLogsApi.reducerPath]: auditLogsApi.reducer,
    [commonSettingsApi.reducerPath]: commonSettingsApi.reducer,
    [aliasSettingsApi.reducerPath]: aliasSettingsApi.reducer,
    [asrModelApi.reducerPath]: asrModelApi.reducer,
    [recognitionSettingsApi.reducerPath]: recognitionSettingsApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [dashboardChartsApi.reducerPath]: dashboardChartsApi.reducer,
    [chartsGraphDataApi.reducerPath]: chartsGraphDataApi.reducer,
    [metricRecordsApi.reducerPath]: metricRecordsApi.reducer,
    [analyticsProjectApi.reducerPath]: analyticsProjectApi.reducer,
    [recordsApi.reducerPath]: recordsApi.reducer,
    [presetTemplateApi.reducerPath]: presetTemplateApi.reducer,
    [customAliasesApi.reducerPath]: customAliasesApi.reducer,
    [metricTemplateApi.reducerPath]: metricTemplateApi.reducer,
    [metricsApi.reducerPath]: metricsApi.reducer,
    [dictionariesApi.reducerPath]: dictionariesApi.reducer,
    [integrationSettingsApi.reducerPath]: integrationSettingsApi.reducer,
    [liveReportsApi.reducerPath]: liveReportsApi.reducer,
    [liveReportsChartsApi.reducerPath]: liveReportsChartsApi.reducer,
    [analyticsProjectPresetApi.reducerPath]: analyticsProjectPresetApi.reducer,
    [wordsNGramApi.reducerPath]: wordsNGramApi.reducer,
    [userSettingsApi.reducerPath]: userSettingsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      userInfoApi.middleware,
      recognitionSettingsApi.middleware,
      commonSettingsApi.middleware,
      dashboardApi.middleware,
      aliasSettingsApi.middleware,
      wordsCloudApi.middleware,
      channelsInfoApi.middleware,
      auditLogsApi.middleware,
      metricRecordsApi.middleware,
      dashboardChartsApi.middleware,
      chartsGraphDataApi.middleware,
      asrModelApi.middleware,
      analyticsProjectApi.middleware,
      recordsApi.middleware,
      presetTemplateApi.middleware,
      customAliasesApi.middleware,
      metricTemplateApi.middleware,
      metricsApi.middleware,
      dictionariesApi.middleware,
      integrationSettingsApi.middleware,
      liveReportsApi.middleware,
      liveReportsChartsApi.middleware,
      analyticsProjectPresetApi.middleware,
      wordsNGramApi.middleware,
      userSettingsApi.middleware,
      restMiddleware,
    ),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;

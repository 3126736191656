import React, { FC } from 'react';
import classNames from 'classnames';

import useTranslation from '@app/hooks/use-translation';
import {
  activeClassName,
  chartMessageIconClassName,
} from '@app/components/audio-build/audio-chat/audio-chat.types';
import Icon from '@app/components/ui/icons';

const AudioChatEnd: FC<{ isEmpty: boolean }> = ({ isEmpty }) => {
  const { t } = useTranslation('components.audio');
  const emptyIconClassName = classNames(
    'flex',
    'absolute',
    '-left-[5px]',
    'justify-center',
    'items-center',
    'w-[10px]',
    'h-[10px]',
    'bg-speech_analitics_light',
    'rounded-full',
  );

  const renderEmpty = isEmpty ? (
    <li
      id="not_dialog_0"
      className="mb-2 ml-6 hover:bg-speech_analitics_light cursor-pointer hover:rounded-[6px] hover:opacity-90"
    >
      <span className={chartMessageIconClassName('bg-3color text-white', false)}>
        <Icon name="BrokenRobotIcon" className="text-white" size={26} />
        <div className="absolute text-[11px] font-normal text-3color left-[-50px]">
          0 {t('sec')}
        </div>
      </span>
      <div className={activeClassName({ active: false })}>
        <div className="mt-[-5px]">
          <h3 className=" flex items-center text-[15px] font-bold text-gray-900">System</h3>
          <p className={classNames('mb-4', 'text-base', 'text-sm', 'font-[500]', 'text-0color')}>
            {t('empty_dialog')}
          </p>
        </div>
      </div>
    </li>
  ) : (
    <></>
  );
  return (
    <>
      {renderEmpty}
      <li className="mb-10 ml-6 h-0 text-[15px] text-3color">
        <div className={emptyIconClassName} />
        <div className={'px-[9px] pt-[7px] pb-[1px] rounded'}>
          <div className="mt-[-15px]">
            <h3 className=" flex font-bold  items-center">{t('end_dialog')}</h3>
          </div>
        </div>
      </li>
    </>
  );
};

export default AudioChatEnd;

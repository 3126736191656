import React from 'react';

import { NavigationTabs } from '@ui';
import { NavigationTabsItem } from '@app/components/ui/navigation-tabs/navigation-tabs';
import { navigationRoutes } from '@app/utils/navigation-routes';
import useTranslation from '@app/hooks/use-translation';

const SettingsNavigationBlock = () => {
  const { t } = useTranslation('pages.settings.navigation');
  const navigationItemsList: NavigationTabsItem[] = [
    {
      link: `/${navigationRoutes.settings}`,
      icon: 'FolderIcon',
      label: t('common_settings'),
      index: 'commonSettings',
      dataId: 'settings-section-common-settings',
    },
    {
      link: `${navigationRoutes.settingsAlias}`,
      icon: 'LetterA',
      label: t('alias'),
      index: 'alias',
      iconSize: 15,
      dataId: 'settings-section-alias-settings',
    },
    {
      link: `${navigationRoutes.settingsRecognitionRules}`,
      icon: 'RecordChannelIcon',
      label: t('recognition_rules'),
      index: 'recognitionRules',
      dataId: 'settings-section-recognition-rules',
    },
    {
      link: `${navigationRoutes.integration}`,
      icon: 'PuzzleIcon',
      label: t('integration_settings'),
      index: 'integrationSettings',
      dataId: 'settings-section-integration-settings',
    },
  ];
  return <NavigationTabs items={navigationItemsList} />;
};

export default SettingsNavigationBlock;

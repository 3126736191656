import React from 'react';

import AuthDataJson from '@app/pages/authorization/mock/auth.json';
import { useAuth } from '@app/utils/reactTokenAuth';
import { navigationRoutes } from '@app/utils/navigation-routes';
import { NavLink } from 'react-router-dom';

// import { RtkLogoIcon } from '@app/components/ui/icons/default';

import logo from '../../../imgs/imagesToBuild';

import AuthMenuBlock from './auth-menu.block';
const HeaderBlock = () => {
  const [logged] = useAuth();
  const renderAuthMenu = logged && <AuthMenuBlock auth={AuthDataJson} />;
  const renderCurrentIndexLink = logged ? navigationRoutes.main : navigationRoutes.authorization;
  return (
    <div className="bg-white flex justify-between px-[26px] text-sm font-[500] shadow-medium items-center border-b-[1px] border-b-b_light h-[67px] w-full">
      <NavLink to={renderCurrentIndexLink} className="inline-flex items-center">
        <div className="text-primary_copy mr-[16px]">
          <img src={logo} alt="LOGO" />
        </div>
        {/*<LogoIcon className="text-primary mr-[16px]" size={32} />*/}
        {/*<div className="inline-flex items-center">*/}
        {/*  <UktechIcon className="text-black mr-[9px]" size={73} />*/}
        {/*  <AnalyticsIcon className="text-primary mt-[3px]" size={139} />*/}
        {/*</div>*/}
      </NavLink>
      <div>{renderAuthMenu}</div>
    </div>
  );
};

export default HeaderBlock;

import React from 'react';
import { useParams } from 'react-router';
import { NotFoundedPage } from '@app/pages';
import AudioBuild from '@app/components/audio-build';

const SingleLastRecordPage = () => {
  const { recordId, recordProjectId } = useParams();

  if (!recordId) return <NotFoundedPage />;
  return <AudioBuild recordId={recordId} projectId={recordProjectId} withMetric />;
};

export default SingleLastRecordPage;

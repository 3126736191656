import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import useTranslation from '@app/hooks/use-translation';
import { Button, EasyFilterView, Input, Select } from '@ui';
import { AsrModerType } from '@app/interfaces/asr-moder.type';
import {
  RecognitionRulesSettingsForCreate,
  RecognitionRulesSettingsObj,
} from '@app/interfaces/settings.type';
import { Filter, Range } from '@app/components/ui/easy-filter/types';
import { useGetAliasesListQuery } from '@app/store/api/alias-settings-page.api';
type RecognitionRulesFormPropsType = {
  asr_model: AsrModerType[] | undefined;
  onFormSubmit(data: RecognitionRulesSettingsForCreate): void;
  editMode: boolean;
  recognitionRuleData: RecognitionRulesSettingsObj | undefined;
};
export const RecognitionRulesForm: FC<RecognitionRulesFormPropsType> = (props) => {
  const { asr_model, onFormSubmit, editMode, recognitionRuleData } = props;
  const { t } = useTranslation('pages.settings');
  const formSetup = editMode
    ? {
        name: recognitionRuleData?.name,
        asr_model_id: recognitionRuleData?.asr_model_id,
        rules: recognitionRuleData?.rules,
      }
    : undefined;
  const { handleSubmit, watch, register, setValue } = useForm<RecognitionRulesSettingsForCreate>({
    defaultValues: formSetup,
  });
  const { data, isLoading } = useGetAliasesListQuery();

  function onSubmit(data) {
    onFormSubmit(data);
  }
  function onChangeFilterHandler(newFilter: Filter & Range) {
    setValue('rules', newFilter);
  }
  function getAsrModelOptionsList() {
    return asr_model?.map((model) => ({ title: model.name, value: model.model_id }));
  }

  function getFilteringKeys() {
    return data?.aliases
      .filter((item) => !item.forbidden_rules)
      .map((alias) => ({
        id: alias.alias_id,
        title: alias.name,
        type_meta: alias.type_meta,
        type_value: alias.type_value,
      }));
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="">
        <div className="flex items-center gap-[101.5px] mb-[20px]">
          <span className="text-1color text-[15px] font-[500] leading-[110%] tracking-tight	">
            {t('recog_rules.form.name')}
          </span>
          <div className={`w-full`}>
            <Input
              {...register('name')}
              placeholder={t('recog_rules.form.placeholder_name')}
              name={'name'}
            />
          </div>
        </div>
        <div className=" flex items-center gap-[51px] mb-[20px]">
          <span className="mt-[-25px] whitespace-nowrap text-1color text-[15px] font-[500] leading-[110%] tracking-tight">
            {t('recog_rules.form.filter_rules')}
          </span>
          <div className={'max-w-[385px] min-w-[385px]'}>
            <EasyFilterView
              modalMode
              isLoading={isLoading}
              onChangeHandler={onChangeFilterHandler}
              keysForFiltering={getFilteringKeys() || []}
              fillFromTemplate
              data={watch('rules')}
            />
          </div>
        </div>
        <div className="flex items-center gap-[13px] mb-[20px]">
          <span className="whitespace-nowrap text-1color text-[15px] font-[500] leading-[110%] tracking-tight">
            {t('recog_rules.form.recog_model')}
          </span>
          <div className={`w-full`}>
            <Select
              placeholder={t('recog_rules.form.choose_placeholder')}
              options={getAsrModelOptionsList() || []}
              dropWidth={374}
              onChange={(value) => setValue('asr_model_id', value as string)}
              defaultValue={watch().asr_model_id}
            />
          </div>
        </div>
        <div className="w-full flex justify-center flex-col gap-[18px] pl-[132px] pr-[140px]">
          <Button
            type="submit"
            isFull
            className="!px-[60px] !py-[20px] mt-[10px]"
            label={t('alias.form.button_label')}
          />
          {editMode && (
            <div className="flex w-full justify-center items-center ml-[-4px]">
              <Button
                variant="danger"
                fill="linked"
                className="w-full"
                label={t('recog_rules.form.button_label_delete')}
              />
            </div>
          )}
        </div>
      </form>
    </>
  );
};

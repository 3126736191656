import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import AudioBuild from '@app/components/audio-build';
import { NotFoundedPage } from '@app/pages';
import connector, { PropsFromRedux } from '@app/utils/store';

const ProjectSingleRecordPage: FC<PropsFromRedux> = () => {
  const { record_id, analytics_id } = useParams();

  if (!analytics_id || !record_id) return <NotFoundedPage />;
  return <AudioBuild recordId={record_id} projectId={analytics_id} withMetric />;
};

export default connector(ProjectSingleRecordPage);
